<template>
<div>
    <!--面包屑组件 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/songs' }">歌曲管理</el-breadcrumb-item>
        <el-breadcrumb-item>上传歌曲</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
        <el-form :model="uploadForm" :rules="uploadFormRules" ref="uploadFormRef" label-width="100px">
            <el-tabs v-model="activeName">
                <el-tab-pane label="基本信息" name="first">
                    <el-row :gutter="30">
                        <el-col :span='14'>
                            <el-form-item label="歌曲标题" prop="title">
                                <el-input v-model="uploadForm.title"></el-input>
                            </el-form-item>
                            <el-form-item label="维语标题" prop="title_uy">
                                <el-input v-model="uploadForm.title_uy"></el-input>
                            </el-form-item>
                            <el-form-item label="资源类型" prop="song_url_type">
                                <el-radio-group v-model="uploadForm.song_url_type" @change="typeChange">
                                    <el-radio label='1'>公众号音频</el-radio>
                                    <el-radio label='0'>本地文件</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="资源ID">
                                <el-input v-model="uploadForm.song_media_id" :disabled="type_visible" placeholder="Mzk0MzI0MTQ1N18x..."></el-input>
                            </el-form-item>
                            <el-form-item label="音频文件" prop="audio_url">
                                <el-upload :disabled="uploadDisable" class="upload-demo" :action="uploadPath + '/audio'" :headers="headerObj" :limit="1" :before-upload="beforeMusicUpload" :on-success="musicUploadSuccess">
                                    <el-button :disabled="uploadDisable" size="small" type="primary" icon="el-icon-upload">点击上传</el-button>
                                    <div slot="tip" class="el-upload__tip">
                                        <el-alert title="只能上传mp3文件，且不超过20MB(如果是wav格式的文件请转换MP3然后在上传)" type="warning" :closable="false"></el-alert>
                                    </div>
                                </el-upload>
                            </el-form-item>
                            <el-row :gutter="30">
                                <el-col :span="16">
                                    <el-form-item label="分类">
                                        <el-select v-model="uploadForm.song_cate_id" placeholder="请选择分类">
                                            <el-option v-for="item in songCateList" :label="item.song_cate_name" :value="item.song_cate_id" :key="item.song_cate_id"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="歌曲封面">
                                <el-upload class="avatar-uploader" :action="uploadPath + '/image'" :headers="headerObj" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">

                                    <img v-if="uploadForm.imageUrl" :src="uploadForm.imageUrl" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                            </el-form-item>
                            <!-- <el-row :gutter="30">
                                <el-col :span="16">
                                    <el-form-item label="专辑">
                                        <el-select v-model="uploadForm.album" placeholder="请选择专辑" clearable>
                                            <el-option v-for="item in albumList" :key="item.id" :label="item.album_name" :value="item.id" class="album_list">
                                                <img :src="item.album_image" style="width:36px;height:36px;line-height:36px;" />
                                                <span style="color: #8492a6; font-size: 13px;margin-left:10px;">{{ item.album_name }}</span>
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row> -->
                            <el-row :gutter="30">
                                <el-col :span="16">
                                    <el-form-item label="创作者">
                                        <el-select v-model="uploadForm.user_id" filterable placeholder="请选择创作者" clearable>
                                            <el-option v-for="item in singerList" :key="item.id" :value="item.id" :label="item.realname" class="artis_list">
                                                <img :src="item.avatar" style="width:36px;height:36px;line-height:36px;border-radius:5px;" />
                                                <span style="color: #8492a6; font-size: 13px;margin-left:10px;">{{ item.realname }}</span>
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="歌曲说明">
                                <el-input type="textarea" :rows="4" :autosize="{ minRows: 4, maxRows: 4}" placeholder="请输入内容" v-model="uploadForm.shuoming"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <!-- 添加专辑对话框 -->
                    <el-dialog title="添加专辑" :visible.sync="addAlbumDialogVisible" width="30%">
                        <el-form :model="addAlbumForm" :rules="addAlbumFormRules" ref="addAlbumFormRef" label-width="100px">
                            <el-form-item label="专辑名称" prop="album_name">
                                <el-input v-model="addAlbumForm.album_name" placeholder="请填写专辑名称"></el-input>
                            </el-form-item>
                            <el-form-item label="专辑封面">
                                <el-upload class="avatar-uploader" :action="uploadPath + '/image'" :headers="headerObj" :show-file-list="false" :on-success="handleAlbumImageSuccess" :before-upload="beforeAlbumImageUpload">

                                    <img v-if="addAlbumForm.album_image" :src="addAlbumForm.album_image" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <span style="color:#909399;font-size:12px;">请上传1:1正方形封面图片</span>
                            </el-form-item>
                            <el-form-item label="专辑介绍">
                                <el-input type="textarea" rows="3" placeholder="请填写专辑介绍" v-model="addAlbumForm.album_desc"></el-input>
                            </el-form-item>
                            <el-form-item label="发布状态">
                                <el-radio-group v-model="addAlbumForm.album_state">
                                    <el-radio :label="1">立即发布</el-radio>
                                    <el-radio :label="0">暂时不发布</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-form>
                        <span slot="footer" class="dialog-footer">
                            <el-button @click="addAlbumDialogClose">取 消</el-button>
                            <el-button type="primary" @click="addAlbum">确 定</el-button>
                        </span>
                    </el-dialog>

                </el-tab-pane>
                <el-tab-pane label="其他信息(创作者)" name="second">
                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="歌手 ">
                                <el-tag :key="i" v-for="(singer,i) in singerTag" closable @close="handleSingerTagClose(i)">{{singer}}</el-tag>
                                <el-input class="input-new-tag" v-if="inputSingerVisible" v-model="inputSingerValue" ref="saveSingerInput" size="small" @keyup.enter.native="handleSingerInputConfirm" @blur="handleSingerInputConfirm">
                                </el-input>
                                <el-button v-else class="button-new-tag" size="small" @click="showSingerInput">+ 新建</el-button>
                            </el-form-item>

                            <el-form-item label="作曲 ">
                                <el-tag :key="i" v-for="(zuoqu,i) in zuoquTag" closable @close="handleZuoquTagClose(i)">{{zuoqu}}</el-tag>
                                <el-input class="input-new-tag" v-if="inputZuoquVisible" v-model="inputZuoquValue" ref="saveZuoquInput" size="small" @keyup.enter.native="handleZuoquInputConfirm" @blur="handleZuoquInputConfirm">
                                </el-input>
                                <el-button v-else class="button-new-tag" size="small" @click="showZuoquInput">+ 新建</el-button>
                            </el-form-item>

                            <el-form-item label="作词 ">
                                <el-tag :key="i" v-for="(zuoci,i) in zuociTag" closable @close="handleZuociTagClose(i)">{{zuoci}}</el-tag>
                                <el-input class="input-new-tag" v-if="inputZuociVisible" v-model="inputZuociValue" ref="saveZuociInput" size="small" @keyup.enter.native="handleZuociInputConfirm" @blur="handleZuociInputConfirm">
                                </el-input>
                                <el-button v-else class="button-new-tag" size="small" @click="showZuociInput">+ 新建</el-button>
                            </el-form-item>

                            <el-form-item label="歌词 ">
                                <el-input type="textarea" :rows="10" :autosize="{ minRows: 10, maxRows: 10}" placeholder="填写 lryic 格式的歌词 ：[00:00.10] 歌词... ([时间] 歌词)" v-model="uploadForm.lyricText"></el-input>
                            </el-form-item>

                        </el-col>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane label="付费视听" name="other">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="付费收听">
                                <el-radio-group v-model="uploadForm.is_free" style="margin-left:15px;" @change="radioChange">
                                    <el-radio :label="0">免费</el-radio>
                                    <el-radio :label="1">扣费</el-radio>
                                </el-radio-group>
                                <span style="margin-left:30px;color:#909399;">选择扣费、按付费价格付费的用户才能收听</span>
                            </el-form-item>

                            <el-form-item label="扣费价格">
                                <el-row>
                                    <el-col :span="8" style="margin-left:15px;">
                                        <el-input placeholder="0.00" v-model.number.trim="uploadForm.price" :disabled="price_disable">
                                            <template slot="append">元</template>
                                        </el-input>
                                    </el-col>
                                </el-row>
                            </el-form-item>

                            <el-form-item label="VIP会员">
                                <el-checkbox v-model="uploadForm.is_vip" style="margin-left:15px;" true-label='1' false-label="0"> </el-checkbox>
                                <span style="margin-left:30px;color:#909399;">勾选后，非vip会员不能收听此内容</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-tab-pane>
            </el-tabs>
            <el-row>
                <el-col :span="12" :offset="6" style="margin-top:30px;">
                    <el-button type="primary" style="width:120px;" @click="saveUploadForm">提交</el-button>
                    <el-button style="width:120px;" @click="resetForm">重置信息</el-button>
                </el-col>
            </el-row>
        </el-form>
    </el-card>
</div>
</template>

<script>
export default {
    data() {
        return {
            uploadPath: this.global.uploadPath,
            type_visible: false,
            uploadDisable: true,
            uploadForm: {
                title: '',
                title_uy: '',
                song_url_type: '1',
                song_media_id: '',
                audio_url: '',
                imageUrl: '',
                song_small_image: '',
                album: 1,
                song_cate_id: '',
                shuoming: '',
                singer: '',
                zuoci: '',
                zuoqu: '',
                lyricText: '',
                is_free: 0,
                price: '',
                user_id: '',
                is_vip: 0
            },
            addAlbumForm: {
                album_name: '',
                album_image: '',
                album_desc: '',
                album_state: 1
            },
            addAlbumDialogVisible: false,
            addAlbumFormRules: {
                album_name: [{
                    required: true,
                    message: '请填写专辑名称',
                    trigger: 'blur'
                }]
            },
            albumList: [],
            songCateList: [],
            singerList: [],
            price_disable: true,
            singerTag: [],
            zuoquTag: [],
            zuociTag: [],
            // 上传接口请求头参数
            headerObj: {
                Authorization: window.sessionStorage.getItem('token')
            },
            inputSingerValue: '',
            inputZuoquValue: '',
            inputZuociValue: '',
            inputSingerVisible: false,
            inputZuoquVisible: false,
            inputZuociVisible: false,
            uploadFormRules: {
                title: [{
                    required: true,
                    message: '请输入歌曲标题',
                    trigger: 'blur'
                }],
                title_uy: [{
                    required: true,
                    message: '请输入维语标题',
                    trigger: 'blur'
                }]
            },
            activeName: 'first',
            value: ''
        }
    },
    created() {
        this.getCategory()
        this.getAlbumList()
        this.getSingerList()
    },
    methods: {
        async getAlbumList() {
            const {
                data: res
            } = await this.$http.get('album/opt')
            if (res.code !== 200) {
                return this.$message.error(res.msg)
            }
            console.log(res.data)
            this.albumList = res.data
        },

        createAlbum() {
            this.addAlbumDialogVisible = true
        },
        addAlbumDialogClose() {
            this.addAlbumDialogVisible = false
        },
        addAlbum() {
            this.$refs.addAlbumFormRef.validate(async valid => {
                if (!valid) {
                    return false
                }

                const {
                    data: res
                } = await this.$http.post('album', this.addAlbumForm)
                if (res.code !== 200) {
                    return this.$message.error(res.msg)
                }

                this.$message.success('专辑添加成功')
                this.getAlbumList()
                this.addAlbumDialogVisible = false
                this.$refs.addAlbumFormRef.resetFields()
                this.AddAlbumForm = {
                    album_state: 1
                }
            })
        },
        handleAlbumImageSuccess(res, file) {
            console.log('upload')
            if (res.code !== 200) {
                this.$message.error('图片上传失败')
            }
            console.log(res)
            this.addAlbumForm.album_image = res.url
            this.$message.success(res.msg)
        },
        beforeAlbumImageUpload(file) {
            const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
            const isLt5M = file.size / 1024 / 1024 < 5

            if (!isImage) {
                this.$message.error('上传歌曲海报只能是 JPG|PNG 格式!')
            }
            if (!isLt5M) {
                this.$message.error('上传歌曲海报大小不能超过 5MB!')
            }
            return isImage && isLt5M
        },
        async getCategory() {
            const {
                data: res
            } = await this.$http.get('songCategory')
            if (res.code !== 200) {
                this.$message.error('分类数据获取失败')
            }
            this.songCateList = res.data
        },
        handleAvatarSuccess(res, file) {
            if (res.code !== 200) {
                this.$message.error('图片上传失败')
            }
            this.uploadForm.imageUrl = res.url
            this.uploadForm.song_small_image = res.thumb_small_image
            this.$message.success(res.msg)
        },
        typeChange() {
            this.type_visible = this.uploadForm.song_url_type === '0'
            this.uploadDisable = this.uploadForm.song_url_type === '1'
        },
        beforeAvatarUpload(file) {
            const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
            const isLt5M = file.size / 1024 / 1024 < 5

            if (!isImage) {
                this.$message.error('上传歌曲封面只能是 JPG|PNG 格式!')
            }
            if (!isLt5M) {
                this.$message.error('上传歌曲封面大小不能超过 5MB!')
            }
            return isImage && isLt5M
        },
        //  提交上传音乐表单数据
        saveUploadForm() {
            this.$refs.uploadFormRef.validate(async valid => {
                if (!this.uploadDisable && this.uploadForm.audio_url === '') {
                    return this.$message.error('请上传音频文件')
                }
                if (this.uploadForm.song_cate_id === '') {
                    return this.$message.error('请选择歌曲分类')
                }
                if (this.uploadForm.imageUrl === '') {
                    return this.$message.error('请上传歌曲封面')
                }

                const {
                    data: res
                } = await this.$http.post('songs/add', this.uploadForm)

                if (res.code !== 200) {
                    return this.$message.error('歌曲添加失败')
                }
                this.$message.success('歌曲添加成功')
                this.$router.push('/songs')
            })
        },
        beforeMusicUpload(file) {
            console.log(file.type)
            const isAudio = file.type === 'audio/mpeg'
            const isLt20M = file.size / 1024 / 1024 < 20

            if (!isAudio) {
                this.$message.error('请上传MP3格式的音频文件')
            }
            if (!isLt20M) {
                this.$message.error('音频文件不能超过 20MB!')
            }
            return isAudio && isLt20M
        },
        musicUploadSuccess(res, file) {
            if (res.code !== 200) {
                return this.$message.error('上传音乐失败')
            }
            this.uploadForm.audio_url = res.url
            this.$message.success('上传音乐成功')
        },

        showSingerInput() {
            this.inputSingerVisible = true
            this.$nextTick(_ => {
                this.$refs.saveSingerInput.$refs.input.focus()
            })
        },

        showZuoquInput() {
            this.inputZuoquVisible = true
            this.$nextTick(_ => {
                this.$refs.saveZuoquInput.$refs.input.focus()
            })
        },

        showZuociInput() {
            this.inputZuociVisible = true
            this.$nextTick(_ => {
                this.$refs.saveZuociInput.$refs.input.focus()
            })
        },

        handleSingerInputConfirm() {
            const inputValue = this.inputSingerValue
            if (inputValue) {
                this.singerTag.push(inputValue)
            }
            this.uploadForm.singer = this.singerTag.join(',')
            this.inputSingerVisible = false
            this.inputSingerValue = ''
        },

        handleZuoquInputConfirm() {
            const inputValue = this.inputZuoquValue
            if (inputValue) {
                this.zuoquTag.push(inputValue)
            }
            this.uploadForm.zuoqu = this.zuoquTag.join(',')
            this.inputZuoquVisible = false
            this.inputZuoquValue = ''
        },

        handleZuociInputConfirm() {
            const inputValue = this.inputZuociValue
            if (inputValue) {
                this.zuociTag.push(inputValue)
            }
            this.uploadForm.zuoci = this.zuociTag.join(',')
            this.inputZuociVisible = false
            this.inputZuociValue = ''
        },

        // 删除歌手标签
        handleSingerTagClose(i) {
            this.singerTag.splice(i, 1)
        },
        // 删除作曲标签
        handleZuoquTagClose(i) {
            this.zuoquTag.splice(i, 1)
        },
        // 删除作词标签
        handleZuociTagClose(i) {
            this.zuociTag.splice(i, 1)
        },

        radioChange(res) {
            console.log(res)
            if (res === 0) {
                this.price_disable = true
            } else {
                this.price_disable = false
            }
        },
        resetForm() {
            this.$refs.uploadFormRef.resetFields()
            this.uploadForm.audio_url = ''
            this.uploadForm.song_media_id = ''
            this.uploadForm.imageUrl = ''
            this.uploadForm.album = ''
            this.uploadForm.song_cate_id = ''
            this.uploadForm.shuoming = ''
            this.uploadForm.singer = ''
            this.uploadForm.zuoci = ''
            this.uploadForm.zuoqu = ''
            this.uploadForm.lyricText = ''
            this.uploadForm.is_free = 0
            this.uploadForm.price = ''
            this.uploadForm.is_vip = 0
            this.singerTag = []
            this.zuoquTag = []
            this.zuociTag = []
        },
        async getSingerList() {
            const {
                data: res
            } = await this.$http.get('artisOpt')
            if (res.code !== 200) {
                return this.$message.error(res.msg)
            }
            this.singerList = res.data
        }
    }
}
</script>

<style lang="less">
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0 0 30px;
}

.el-select {
    width: 100%;
}

.input-new-tag {
    width: 150px !important;
}

.el-tag {
    margin-right: 15px;
}

.el-alert {
    padding: 0 !important;
}

.avatar-uploader .avatar-uploader-icon {
    width: 120px;
    height: 120px;
    line-height: 120px;
}

.avatar {
    width: 120px;
    height: 120px;
}

.album_list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 10px 0;
}

.artis_list {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}

.artis_list .artis_name {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    margin-left: 10px;
}
</style>
