<template>
  <div>
    <el-card>
       <div slot="header" class="clearfix">
          <span>概览</span>
      </div>
      <el-row :gutter="20">
            <el-col :span="6">
              <div class="grid-content box-shadow bg-other">
                <div class="count">{{homeData.vip_users}}</div>
                <div class="title">超级会员</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content box-shadow bg-danger">
                <div class="count">{{homeData.users}}</div>
                <div class="title">用户数量</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content box-shadow bg-warning">
                <div class="count">{{homeData.order}}</div>
                <div class="title">支付订单</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content box-shadow bg-primary">
                <div class="count">{{homeData.amount}}</div>
                <div class="title">累计收入(元)</div>
              </div>
            </el-col>
          </el-row>
    </el-card>
    <el-row :gutter="15">
      <el-col :span="16">
          <el-card style="margin-top:15px;">
            <div slot="header" class="clearfix">
                <span>数据统计</span>
            </div>
            <div id="container" style="width:100%;height:300px;margin-bottom:15px;"></div>
          </el-card>
      </el-col>
      <el-col :span="8">
        <el-card style="margin-top:15px;">
      <div slot="header" class="clearfix">
          <span>资源比例</span>
      </div>
      <div id="category" style="width:100%;height:300px;margin-bottom:15px;"></div>
    </el-card>
      </el-col>
    </el-row>

    <!-- <el-row :gutter="15">
      <el-col :span="6">
          <el-card style="margin-top:15px;">
            <div slot="header" class="clearfix">
                <span>电台播放量</span>
            </div>
            <div id="fm" style="width:100%;height:300px;margin-bottom:15px;"></div>
          </el-card>
      </el-col>
      <el-col :span="6">
          <el-card style="margin-top:15px;">
            <div slot="header" class="clearfix">
                <span>视频播放量</span>
            </div>
            <div id="video" style="width:100%;height:300px;margin-bottom:15px;"></div>
          </el-card>
      </el-col>
      <el-col :span="6">
          <el-card style="margin-top:15px;">
            <div slot="header" class="clearfix">
                <span>课程播放量</span>
            </div>
            <div id="cource" style="width:100%;height:300px;margin-bottom:15px;"></div>
          </el-card>
      </el-col>
      <el-col :span="6">
        <el-card style="margin-top:15px;">
      <div slot="header" class="clearfix">
          <span>音乐播放量</span>
      </div>
      <div id="song" style="width:100%;height:300px;margin-bottom:15px;"></div>
    </el-card>
      </el-col>
    </el-row> -->
  </div>
</template>
<script>
import { Line, Pie } from '@antv/g2plot'
export default {
  data() {
    return {
      homeData: {}
    }
  },
  created() {
    this.getHomeData()
    // this.$nextTick(() => {
    //   this.renderCate()
    // })
  },
  methods: {
    async getHomeData() {
      const { data: res } = await this.$http.get('home')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.homeData = res.data
      this.renderData()
      this.renderCate()
      // this.renderFMData()
      // this.renderVideoData()
      // this.renderCourceData()
      // this.renderSongData()
    },
    renderCate() {
      const data = this.homeData.cate
      const piePlot = new Pie('category', {
        data,
        angleField: 'value',
        colorField: 'type'
      })
      piePlot.render()
    },
    renderData() {
      const data = this.homeData.list
      const line = new Line('container', {
        data,
        xField: 'day',
        yField: 'amount',
        xAxis: {
          tickCount: 10
        },
        // 是否曲线形式显示
        smooth: true
      })
      line.render()
    },
    renderFMData() {
      const data = this.homeData.media_views
      const line = new Line('fm', {
        data,
        xField: 'day',
        yField: 'fm_views',
        xAxis: {
          tickCount: 3
        },
        // 是否曲线形式显示
        smooth: true
      })
      line.render()
    },
    renderVideoData() {
      const data = this.homeData.media_views
      const line = new Line('video', {
        data,
        xField: 'day',
        yField: 'video_views',
        xAxis: {
          tickCount: 3
        },
        // 是否曲线形式显示
        smooth: true
      })
      line.render()
    },
    renderCourceData() {
      const data = this.homeData.media_views
      const line = new Line('cource', {
        data,
        xField: 'day',
        yField: 'cource_views',
        xAxis: {
          tickCount: 3
        },
        // 是否曲线形式显示
        smooth: true
      })
      line.render()
    },
    renderSongData() {
      const data = this.homeData.media_views
      const line = new Line('song', {
        data,
        xField: 'day',
        yField: 'song_views',
        xAxis: {
          tickCount: 3
        },
        // 是否曲线形式显示
        smooth: true
      })
      line.render()
    }
  }
}
</script>
<style lang="less" scoped>
.el-col {
  border-radius: 4px;
  color: #eeeeee;
}
.border-top-primary{
  border-top: 3px solid #409EFF;
}
.border-top-success{
  border-top: 3px solid #67C23A;
}
.border-top-warning{
  border-top: 3px solid #E6A23C;
}
.border-top-danger{
  border-top: 3px solid #F56C6C;
}
.bg-primary {
  background: #5473E8;
}

.bg-success {
  background: #67C23A;
}

.bg-warning {
  background: #FF9f24;
}

.bg-other {
  background: #23bcca;
}

.bg-danger {
  background: #F56C6C;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #409EFF;
}

.bg-purple-light {
  background: #e5e9f2;
}
.box-shadow{
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
}

.grid-content {
  border-radius: 4px;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image:url('../../assets/item-bg.png');
  background-repeat: repeat;
  background-size: cover;
}

.grid-content .title {
  font-size: 18px;
}

.grid-content .count {
  font-size: 44px;
}

</style>
