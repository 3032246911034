<template>
<div>
    <breadcrumb param1="歌单管理" param2="歌单详情" />
    <el-card>
        <el-page-header @back="goBack"></el-page-header>
        <div class="vocie">
            <el-row>
                <el-col :span="12">
                    <div class="album_list">
                        <div class="fm_image">
                            <img style="width: 152px; height: 152px; border-radius: 5px" :src="gedanInfo.gedan_image_url" />
                        </div>
                        <div class="album_name">
                            <span class="uy-text">{{gedanInfo.gedan_name}}</span>
                            <span class="uy-text ltr" style="color: #606266; font-size: 14px"> {{gedanInfo.gedan_name_uy}}</span>
                            <span style="color: #606266; font-size: 14px">添加时间：{{gedanInfo.gedan_create_time}}</span>
                            <div class="movie-desc">
                                电台简介 ：<span class="uy-text"> {{gedanInfo.gedan_desc}} </span>
                            </div>
                            <div class="movie-desc">
                                维文简介 ：<span class="uy-text"> {{gedanInfo.gedan_desc_uy}} </span>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-divider content-position="left"></el-divider>
            <el-row>
                <el-col :span="12">
                    <el-button type="warning" icon="el-icon-plus" @click="addTrack">添加歌曲</el-button>
                </el-col>
            </el-row>
            <el-table :data="gedanListItem" v-loading="loadingItem">
                <el-table-column label="#" type="index" prop=""></el-table-column>
                <el-table-column label="歌曲标题">
                    <template slot-scope="scope">
                        <div class="gedan-item">
                            <el-image :src="scope.row.song_image_url" style="width:36px;height:36px;border-radius:3px;"></el-image>
                            <span style="margin-left:15px;">{{scope.row.song_title}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="歌手">
                    <template slot-scope="scope">
                        <span>{{scope.row.artis_name}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="添加时间" prop="create_time" width="200"></el-table-column>
                <el-table-column label="操作" width="200" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="deleteGedanItem(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChangeItem" @current-change="handleCurrentChangeItem" :current-page="queryInfoItem.page" :page-sizes="[10, 15, 20]" :page-size="queryInfoItem.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totalItem">
            </el-pagination>
            <el-dialog title="添加歌曲" :visible.sync="addTrackVisible" width="40%">
                <div>
                    <el-row>
                        <el-col>
                            <el-input placeholder="请输入内容" v-model="queryInfo.query" class="input-with-select" clearable @clear="getSongList">
                                <el-button slot="append" icon="el-icon-search" @click="getSongList"></el-button>
                            </el-input>
                        </el-col>
                    </el-row>
                </div>
                <el-table :data="songsList" v-loading="loading">
                    <!-- <el-table-column label="歌曲编号" sortable width="120" prop="id" align="center"></el-table-column> -->
                    <el-table-column label="歌曲名称">
                        <template slot-scope="scope">
                            <div class="album_list">
                                <div class="song_image">
                                    <el-image style="width: 64px; height: 64px;border-radius:6px;" :src="scope.row.song_image_url"></el-image>
                                    <div class="play-button-wrapper">
                                        <div class="music-play-button"><i class="iconfont icon-play"></i></div>
                                    </div>
                                </div>
                                <div class="album_name">
                                    <span>{{scope.row.song_title}}</span>
                                    <span style="color:#909399;font-size:13px">歌手： {{scope.row.user_name}}</span>
                                </div>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="更新时间" sortable prop="song_update_time" width="220"></el-table-column>

                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-row>
                                <el-button icon="el-icon-plus" size="mini" plain @click="addToGedan(scope.row.id)">添加</el-button>
                            </el-row>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addTrackVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addTrackVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </el-card>

</div>
</template>

<script>
export default {
    data() {
        return {
            gedanInfo: [],
            addTrackVisible: false,
            songsList: [],
            queryInfo: {
                query: '',
                page: 1,
                pagesize: 5
            },
            total: 0,
            loading: true,
            loadingItem: true,
            queryInfoItem: {
                query: '',
                page: 1,
                pagesize: 10
            },
            totalItem: 0,
            addToForm: {
                gedan_id: this.$route.query.id,
                song_id: ''
            },
            gedanListItem: []
        }
    },
    created() {
        this.getGedanInfo()
        this.getGedanListItem()
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        async getGedanInfo() {
            const {
                data: res
            } = await this.$http.get(
                'gedan/' + this.$route.query.id
            )
            console.log(res)
            if (res.code !== 200) {
                this.$message.error(res.msg)
            }
            this.gedanInfo = res.data
        },
        async getSongList() {
            const {
                data: res
            } = await this.$http.get('/songs', {
                params: this.queryInfo
            })

            if (res.code !== 200) {
                this.$message.error('找不到相关数据')
            }
            this.songsList = res.data.data
            this.total = res.data.total
            this.loading = false
            this.$message.success('数据获取成功')
        },
        addTrack() {
            this.getSongList()
            this.addTrackVisible = true
        },
        async addToGedan(id) {
            this.addToForm.song_id = id
            const {
                data: res
            } = await this.$http.post('/gedan_list', this.addToForm)
            if (res.code !== 200) {
                return this.$message.error(res.msg)
            }
            this.getGedanListItem()
            this.$message.success(res.msg)
        },
        async getGedanListItem() {
            const {
                data: res
            } = await this.$http.get('/gedan_list/' + this.$route.query.id, {
                params: this.queryInfoItem
            })
            if (res.code !== 200) {
                return this.$message.error(res.msg)
            }
            this.totalItem = res.data.total
            this.gedanListItem = res.data.data
            this.loadingItem = false
        },
        async deleteGedanItem(id) {
            const confirmRes = await this.$confirm('确定删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            if (confirmRes !== 'confirm') {
                return this.$message.info('取消删除')
            }
            this.loading = true
            const {
                data: res
            } = await this.$http.delete('gedan_list/' + id)

            if (res.code !== 200) {
                return this.$message.error(res.msg)
            }
            this.$message.success(res.msg)
            this.getGedanListItem()
        },
        handleSizeChange(newPage) {
            console.log(newPage)
            this.queryInfo.pagesize = newPage
            this.getSongList()
        },
        handleCurrentChange(newPage) {
            this.loading = false
            this.queryInfo.page = newPage
            this.getSongList()
        },
        handleSizeChangeItem(newPage) {
            this.queryInfoItem.pagesize = newPage
            this.getGedanListItem()
        },
        handleCurrentChangeItem(newPage) {
            this.loading = false
            this.queryInfoItem.page = newPage
            this.getGedanListItem()
        }
    }
}
</script>

<style scoped>
.vocie {
    margin-top: 30px;
}

.album_list {
    display: flex;
    flex-direction: row;
    margin: 0px;
}

.album_list .album_name {
    display: flex;
    flex-direction: column;
    text-align: left;
    /* justify-content: space-between; */
    margin-left: 30px;
}

.album_list span {
    margin-bottom: 15px;
}

.fm_image i {
    position: relative;
    color: #fff;
    font-size: 20px;
    z-index: 100;
}

.fm-info {
    display: flex;
    flex-direction: column;
    /* height: 116px; */
    justify-content: flex-start;
    color: #606266;
    font-size: 14px;
}

.fm-info span {
    margin-bottom: 15px;
}

.movie-desc {
    /* margin-top: 15px; */
    color: #606266;
    /* margin-left:162px; */
    font-size: 14px;
    line-height: 32px;
}

.gedan-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
}
</style>
