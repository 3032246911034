<template>
<div>
    <!--面包屑组件 -->
    <breadcrumb param1="运营" param2="轮播图管理" />
    <el-card>
        <el-row :gutter="15">
            <el-col :span="8">
                <el-input placeholder="请输入内容" v-model="queryInfo.query" class="input-with-select" clearable @clear="getBannerList">
                    <el-button slot="append" icon="el-icon-search" @click="getBannerList"></el-button>
                </el-input>
            </el-col>
            <el-col :span="4">
                <el-button type="warning" icon="el-icon-plus" @click="showBannerDialog()">添加轮播图</el-button>
            </el-col>
        </el-row>

        <el-table :data="bannerList" v-loading="loading">
            <el-table-column label="#" type="index"></el-table-column>
            <el-table-column label="轮播图图片" width="140">
                <template slot-scope="scope">
                    <img class="menu-image" :src="scope.row.banner_image_url" />
                </template>
            </el-table-column>
            <el-table-column label="标题">
                <template slot-scope="scope">
                    <div>{{scope.row.banner_title}}</div>
                </template>
            </el-table-column>
            <el-table-column label="跳转ID" prop="banner_resource_id" width="100"></el-table-column>
            <el-table-column label="跳转类型" width="150">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.banner_leixing === 1" type="success">歌单</el-tag>
                    <el-tag v-if="scope.row.banner_leixing === 2" type="primary">单曲</el-tag>
                    <el-tag v-if="scope.row.banner_leixing === 3" type="warning">视频</el-tag>
                    <el-tag v-if="scope.row.banner_leixing === 4" type="info">页面</el-tag>
                    <el-tag v-if="scope.row.banner_leixing === 5" type="success">短视频</el-tag>
                    <el-tag v-if="scope.row.banner_leixing === 6" type="warning">电台</el-tag>
                    <el-tag v-if="scope.row.banner_leixing === 7" type="primary">课程</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="排序" width="120">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.banner_order_num" @blur="update_banner_order_num(scope.row)"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="300">
                <template slot-scope="scope">
                    <el-button type="primary" icon="el-icon-edit" size="mini" @click="editBanner(scope.row.id)">编辑</el-button>
                    <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteBanner(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>

    <el-dialog title="添加轮播图" :visible.sync="bannerDialogVisible" width="40%">
        <el-form :model="addBannerForm" :rules="addBannerFormRules" ref="addBannerFormRef" label-width="100px">
            <el-form-item label="轮播图名称" prop="banner_title">
                <el-input v-model="addBannerForm.banner_title"></el-input>
            </el-form-item>
            <el-form-item label="轮播图图片">
                <el-upload class="avatar-uploader" :action="uploadUrl + '/image'" :headers="headerObj" :show-file-list="false" :on-success="handleImageIconSuccess" :before-upload="beforeImageIconUpload">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="排序" prop="banner_order_num">
                <el-input v-model="addBannerForm.banner_order_num"></el-input>
            </el-form-item>
            <el-form-item label="跳转到">
                <el-radio-group v-model="addBannerForm.banner_leixing">
                    <el-radio :label="1">歌单</el-radio>
                    <el-radio :label="2">单曲</el-radio>
                    <el-radio :label="3">视频</el-radio>
                    <el-radio :label="4">页面</el-radio>
                    <el-radio :label="5">短视频</el-radio>
                    <el-radio :label="6">电台</el-radio>
                    <el-radio :label="7">课程</el-radio>
                </el-radio-group>
                <el-alert title="资源ID请填写所选类型对应的ID、否则会出现找不到资源的问题" type="warning" show-icon>
                </el-alert>
            </el-form-item>
            <el-form-item label="资源ID" prop="banner_resource_id">
                <el-input v-model="addBannerForm.banner_resource_id"></el-input>
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="bannerDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="saveBannerForm">确 定</el-button>
        </span>
    </el-dialog>

    <!-- 编辑轮播图 -->
    <el-dialog title="编辑轮播图" :visible.sync="editBannerDialogVisible" width="40%">
        <el-form :model="editBannerForm" :rules="addBannerFormRules" ref="addBannerFormRef" label-width="100px">
            <el-form-item label="轮播图名称" prop="banner_title">
                <el-input v-model="editBannerForm.banner_title"></el-input>
            </el-form-item>
            <el-form-item label="轮播图图片">
                <el-upload class="avatar-uploader" :action="uploadUrl + '/image'" :headers="headerObj" :show-file-list="false" :on-success="handleImageEditIconSuccess" :before-upload="beforeImageIconUpload">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="排序" prop="banner_order_num">
                <el-input v-model="editBannerForm.banner_order_num"></el-input>
            </el-form-item>
            <el-form-item label="跳转到">
                <el-radio-group v-model="editBannerForm.banner_leixing">
                    <el-radio :label="1">歌单</el-radio>
                    <el-radio :label="2">单曲</el-radio>
                    <el-radio :label="3">视频</el-radio>
                    <el-radio :label="4">页面</el-radio>
                    <el-radio :label="5">短视频</el-radio>
                    <el-radio :label="6">电台</el-radio>
                    <el-radio :label="7">课程</el-radio>
                </el-radio-group>
                <el-alert title="资源ID请填写所选类型对应的ID、否则会出现找不到资源的问题" type="warning" show-icon>
                </el-alert>
            </el-form-item>
            <el-form-item label="资源ID" prop="banner_resource_id">
                <el-input v-model="editBannerForm.banner_resource_id"></el-input>
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="editBannerDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="updateBanner">确 定</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            queryInfo: {
                query: '',
                page: 1,
                pagesize: 7
            },
            total: 0,
            loading: true,
            bannerDialogVisible: false,
            addBannerForm: {
                banner_title: '',
                banner_image_url: '',
                banner_order_num: '0',
                banner_leixing: 1,
                banner_resource_id: ''
            },
            editBannerForm: {},
            editBannerDialogVisible: false,
            bannerList: [],
            imageUrl: '',
            // 上传接口请求头参数
            headerObj: {
                Authorization: window.sessionStorage.getItem('token')
            },
            addBannerFormRules: {
                banner_title: [{
                        required: true,
                        message: '请填写轮播图名称',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 20,
                        message: '长度在 2 到 20 个字符',
                        trigger: 'blur'
                    }
                ],
                banner_resource_id: [{
                        required: true,
                        message: '请填写资源ID',
                        trigger: 'blur'
                    }
                    // { min: 1, max: 5, message: '1～5位数之间' }
                ],
                banner_order_num: [{
                    required: true,
                    message: '请填写排序号码、数字越大越靠前显示',
                    trigger: 'blur'
                }]
            }
        }
    },
    created() {
        this.getBannerList()
    },
    methods: {
        async getBannerList() {
            const {
                data: res
            } = await this.$http.get('banner', {
                params: this.queryInfo
            })
            if (res.code !== 200) {
                this.$message.error('数据获取失败')
            }
            this.total = res.data.total
            this.bannerList = res.data.data
            this.$message.success('获取成功')
            this.loading = false
        },
        showBannerDialog() {
            this.bannerDialogVisible = true
        },
        async editBanner(id) {
            const {
                data: res
            } = await this.$http.get('banner/' + id)
            if (res.code !== 200) {
                this.$message.error('数据获取失败')
            }
            console.log(res)
            this.editBannerForm = res.data
            this.imageUrl = res.data.banner_image_url
            this.$message.success('数据获取成功')
            this.editBannerDialogVisible = true
        },
        updateBanner() {
            this.$refs.addBannerFormRef.validate(async valid => {
                if (!valid) {
                    return false
                }
                if (this.editBannerForm.banner_image_url === '') {
                    return this.$message.error('请上传轮播图图片')
                }
                const {
                    data: res
                } = await this.$http.put('banner', this.editBannerForm)
                if (res.code !== 200) {
                    return this.$message.error(res.msg)
                }
                this.$message.success(res.msg)
                this.getBannerList()
                this.editBannerDialogVisible = false
                this.editBannerForm = {}
            })
        },
        handleImageIconSuccess(res, file) {
            if (res.code !== 200) {
                this.$message.error('图片上传失败')
            }
            this.addBannerForm.banner_image_url = res.url
            this.imageUrl = res.url
            this.$message.success(res.msg)
        },
        handleImageEditIconSuccess(res, file) {
            if (res.code !== 200) {
                this.$message.error('图片上传失败')
            }
            this.imageUrl = res.url
            this.editBannerForm.banner_image_url = res.url
            this.$message.success(res.msg)
        },
        beforeImageIconUpload(file) {
            const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
            const isLt1M = file.size / 1024 / 1024 < 1

            if (!isImage) {
                this.$message.error('上传歌曲海报只能是 JPG|PNG 格式!')
            }
            if (!isLt1M) {
                this.$message.error('上传歌曲海报大小不能超过 1MB!')
            }
            return isImage && isLt1M
        },
        saveBannerForm() {
            this.$refs.addBannerFormRef.validate(async valid => {
                if (!valid) {
                    return false
                }
                if (this.addBannerForm.banner_image_url === '') {
                    return this.$message.error('请上传轮播图图片')
                }
                const {
                    data: res
                } = await this.$http.post('banner', this.addBannerForm)
                if (res.code !== 200) {
                    return this.$message.error(res.msg)
                }
                this.$message.success(res.msg)
                this.getBannerList()
                this.bannerDialogVisible = false
                this.addBannerForm.banner_image_url = ''
                this.addBannerForm.banner_image_url = ''
                this.addBannerForm.banner_order_num = '0'
                this.addBannerForm.banner_leixing = 1
                this.addBannerForm.banner_resource_id = ''
            })
        },
        handleSizeChange(newPage) {
            this.queryInfo.pagesize = newPage
            this.getBannerList()
        },
        handleCurrentChange(newPage) {
            this.queryInfo.page = newPage
            this.getBannerList()
        },
        async update_banner_order_num(row) {
            const {
                data: res
            } = await this.$http.put('banner/update_banner_order/' + row.id, {
                banner_order_num: row.banner_order_num
            })
            if (res.code !== 200) {
                return this.$message.error('更新失败')
            }

            this.getBannerList()
            this.$message.success('更新成功')
        },
        async deleteBanner(id) {
            const confirmRes = await this.$confirm('此操作将永久删除该轮播图, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            if (confirmRes !== 'confirm') {
                return this.$message.info('取消删除')
            }

            const {
                data: res
            } = await this.$http.delete('banner/' + id)
            if (res.code !== 200) {
                return this.$message.error('删除失败')
            }
            this.$message.success('删除成功')
            this.getBannerList()
        }
    }
}
</script>

<style scoped>
.el-alert {
    width: 100%;
    padding: 0px 5px;
    margin: 0;
    box-sizing: border-box;
    border-radius: 4px;
}

.menu-image {
    width: 120px;
    height: 60px;
}
</style>
