<template>
  <div>
    <!--面包屑组件 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>财务管理</el-breadcrumb-item>
      <el-breadcrumb-item>提现明细</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-table :data="withdrawList" v-loading="loading">
        <el-table-column label="编号" sortable prop="id"></el-table-column>
        <el-table-column label="类型" sortable>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.type === 1" type="info"> 用户提现 </el-tag>
            <el-tag v-if="scope.row.type === 2" type="info"> 其他提现 </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创作者">
          <template slot-scope="scope">
            <div class="user_list">
              <el-image style="width: 38px; height: 38px" :src="scope.row.avatar">
                <div slot="error" class="image-slot">
                  <i class="el-icon-user"></i>
                </div>
              </el-image>
              <div class="user_name">
                <span>{{scope.row.nickname}}</span>
                <span>{{scope.row.user_id }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="金额(元)" sortable prop="amount"></el-table-column>
        <el-table-column label="审核状态" sortable>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 1" type="info"> 待审核 </el-tag>
            <el-tag v-if="scope.row.status === 2" type="success"> 通过 </el-tag>
            <el-tag v-if="scope.row.status === 3" type="danger"> 拒绝 </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="打款状态" sortable>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.is_paid === 1" type="info"> 未打款 </el-tag>
            <el-tag v-if="scope.row.is_paid === 2" type="success"> 现金红包 </el-tag>
            <el-tag v-if="scope.row.is_paid === 3" type="success"> 企业付款 </el-tag>
            <el-tag v-if="scope.row.is_paid === 4" type="success"> 手动打款 </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="微信单号" sortable prop="trade_no" width="260px"></el-table-column>
        <el-table-column label="创建时间" sortable prop="create_time"></el-table-column>
        <el-table-column label="更新时间" sortable prop="update_time"></el-table-column>
        <el-table-column label="审核操作" width="220px">
          <template slot-scope="scope">
            <el-button type="success" icon="el-icon-right" size="mini" @click="gotoPass(scope.row.id)">通过</el-button>
            <el-button type="danger" icon="el-icon-close" size="mini" @click="gotoRefuse(scope.row.id)">拒绝</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page"
        :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        queryInfo: {
          query: '',
          page: 1,
          pagesize: 10
        },
        total: 0,
        loading: true,
        withdrawList: [],
        // 上传接口请求头参数
        headerObj: {
          Authorization: window.sessionStorage.getItem('token')
        }
      }
    },
    created() {
      this.getWithdrawList()
    },
    methods: {
      handleSizeChange(newPage) {
        this.queryInfo.pagesize = newPage
        this.getWithdrawList()
      },
      handleCurrentChange(newPage) {
        this.queryInfo.page = newPage
        this.getWithdrawList()
      },
      async getWithdrawList() {
        const {
          data: res
        } = await this.$http.get('withdrawOrder', {
          params: this.queryInfo
        })
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }

        this.withdrawList = res.data.data
        this.total = res.data.total
        this.$message.success(res.msg)
        this.loading = false
      },
      async gotoPass(id) {
        const confirmRes = await this.$confirm('是否确定审核通过此笔提现申请?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)
        if (confirmRes !== 'confirm') {
          return this.$message.info('已取消')
        }
        this.loading = true
        const { data: res } = await this.$http.post('withdraw/pass/' + id)
        if (res.code === 200) {
          this.$message.success('操作成功')
        } else {
          this.$message.error('操作失败：' + res.msg)
        }
        this.getWithdrawList()
      },
      async gotoRefuse(id) {
        const confirmRes = await this.$confirm('是否确定拒绝此笔提现申请?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)
        if (confirmRes !== 'confirm') {
          return this.$message.info('已取消')
        }
        this.loading = true
        const { data: res } = await this.$http.post('withdraw/refuse/' + id)
        if (res.code === 200) {
          this.$message.success('操作成功')
        } else {
          this.$message.error('操作失败：' + res.msg)
        }
        this.getWithdrawList()
      }
    }
  }
</script>

<style lang="less" scoped>
  .avatar-uploader .avatar-uploader-icon {
    width: 110px;
    height: 110px;
    line-height: 110px;
  }

  .avatar {
    width: 110px;
    height: 110px;
    line-height: 110px;
  }

  .el-form-item__content {
    line-height: 24px !important;
  }

  .user_list {
    display: flex;
    flex-direction: row;
  }

  .user_list .user_name {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    margin-left: 10px;
  }

  .el-row {
    margin-bottom: 10px;
  }
</style>
