<template>
<div>
  <!--面包屑组件 -->
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>电台管理</el-breadcrumb-item>
    <el-breadcrumb-item>电台列表</el-breadcrumb-item>
  </el-breadcrumb>

  <el-card>
    <el-row :gutter="15">
      <el-col :span="8">
        <el-input placeholder="请输入内容" v-model="queryInfo.query" class="input-with-select" clearable @clear="getfmAlbumList">
          <el-button slot="append" icon="el-icon-search" @click="getfmAlbumList"></el-button>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-button type="warning" icon="el-icon-plus" @click="addFmAlbumDialogVisible = true">添加节目</el-button>
      </el-col>
    </el-row>

    <el-table :data="fmAlbumList" v-loading="loading">
      <el-table-column label="节目名称">
        <template slot-scope="scope">
          <div class="fm_album_list">
            <el-image style="width: 75px; height: 75px" :src="scope.row.fm_album_image"></el-image>
            <div class="fm_album_name">
              <span>{{scope.row.fm_album_name}}</span>
              <span style="margin-top:5px;color:#909399;">创建于 {{ scope.row.fm_album_create_time }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="节目介绍">
        <template slot-scope="scope">
          {{scope.row.fm_album_desc | ellipsis(24)}}
        </template>
      </el-table-column>
      <el-table-column label="状态" width="120">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.fm_album_state === 1" type="success">已发布</el-tag>
          <el-tag v-else type="info">未发布</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="更新时间" prop="fm_album_update_time" width="220"></el-table-column>
      <el-table-column label="操作" width="220">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
          <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteFmAlbum(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </el-card>

  <!-- 添加节目对话框 -->
  <el-dialog title="添加节目" :visible.sync="addFmAlbumDialogVisible" width="30%">
    <el-form :model="addFmAlbumForm" :rules="addFmAlbumFormRules" ref="addFmAlbumFormRef" label-width="100px">
      <el-form-item label="节目名称" prop="fm_album_name">
        <el-input v-model="addFmAlbumForm.fm_album_name" placeholder="请填写节目名称"></el-input>
      </el-form-item>
      <el-form-item label="节目封面">
        <el-upload class="avatar-uploader" :action="uploadPath + '/image'" :headers="headerObj" :show-file-list="false" :on-success="handleAlbumImageSuccess" :before-upload="beforeAlbumImageUpload">
          <img v-if="addFmAlbumForm.fm_album_image" :src="addFmAlbumForm.fm_album_image" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <span style="color:#909399;font-size:12px;">请上传1:1正方形封面图片</span>
      </el-form-item>
      <el-form-item label="节目介绍">
        <el-input type="textarea" rows="3" placeholder="请填写节目介绍" v-model="addFmAlbumForm.fm_album_desc"></el-input>
      </el-form-item>
      <el-form-item label="发布状态">
        <el-radio-group v-model="addFmAlbumForm.fm_album_state">
          <el-radio :label="1">立即发布</el-radio>
          <el-radio :label="0">暂时不发布</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="addFmAlbumDialogClose">取 消</el-button>
      <el-button type="primary" @click="addFmAlbum">确 定</el-button>
    </span>
  </el-dialog>

  <el-dialog title="编辑节目" :visible.sync="editAlbumDialogVisible" width="30%">
    <el-form :model="editAlbumForm" :rules="addFmAlbumFormRules" ref="editAlbumFormRef" label-width="100px">
      <el-form-item label="节目名称" prop="fm_album_name">
        <el-input v-model="editAlbumForm.fm_album_name" placeholder="请填写节目名称"></el-input>
      </el-form-item>
      <el-form-item label="节目封面">
        <el-upload class="avatar-uploader" :action="uploadPath + '/image'" :headers="headerObj" :show-file-list="false" :on-success="handleEditAlbumImageSuccess" :before-upload="beforeAlbumImageUpload">

          <img v-if="editAlbumForm.fm_album_image" :src="editAlbumForm.fm_album_image" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <span style="color:#909399;font-size:12px;">请上传1:1正方形封面图片</span>
      </el-form-item>
      <el-form-item label="节目介绍">
        <el-input type="textarea" rows="3" placeholder="请填写节目介绍" v-model="editAlbumForm.fm_album_desc"></el-input>
      </el-form-item>
      <el-form-item label="发布状态">
        <el-radio-group v-model="editAlbumForm.fm_album_state">
          <el-radio :label="1">立即发布</el-radio>
          <el-radio :label="0">暂时不发布</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="editAlbumDialogClose">取 消</el-button>
      <el-button type="primary" @click="editAlbum">确 定</el-button>
    </span>
  </el-dialog>
</div>
</template>

<script>
export default {
  data() {
    return {
      uploadPath: this.global.uploadPath,
      addFmAlbumForm: {
        fm_album_name: '',
        fm_album_image: '',
        fm_album_desc: '',
        fm_album_state: 1
      },
      editAlbumForm: {},
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 10
      },
      total: 0,
      loading: true,
      fmAlbumList: [],
      // 上传接口请求头参数
      headerObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      addFmAlbumFormRules: {
        fm_album_name: [
          { required: true, message: '请填写节目名称', trigger: 'blur' }
        ]
      },
      addFmAlbumDialogVisible: false,
      editAlbumDialogVisible: false
    }
  },
  created() {
    this.getfmAlbumList()
  },
  methods: {
    handleAlbumImageSuccess(res, file) {
      // this.uploadForm.imageUrl = URL.createObjectURL(file.raw)
      this.addFmAlbumForm.fm_album_image = res.url
      if (res.code !== 200) {
        this.$message.error('图片上传失败')
      }
      this.$message.success(res.msg)
    },
    handleEditAlbumImageSuccess(res, file) {
      // this.uploadForm.imageUrl = URL.createObjectURL(file.raw)
      this.editAlbumForm.fm_album_image = res.url
      if (res.code !== 200) {
        this.$message.error('图片上传失败')
      }
      this.$message.success(res.msg)
    },
    beforeAlbumImageUpload(file) {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5

      if (!isImage) {
        this.$message.error('上传歌曲海报只能是 JPG|PNG 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传歌曲海报大小不能超过 5MB!')
      }
      return isImage && isLt5M
    },
    handleSizeChange(newPage) {
      this.queryInfo.pagesize = newPage
      this.getfmAlbumList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getfmAlbumList()
    },
    addFmAlbum() {
      this.$refs.addFmAlbumFormRef.validate(async valid => {
        if (!valid) {
          return false
        }

        const { data: res } = await this.$http.post('fm_album', this.addFmAlbumForm)
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }

        this.$message.success('节目添加成功')
        this.getfmAlbumList()
        this.addFmAlbumDialogVisible = false
        this.$refs.addFmAlbumFormRef.resetFields()
        this.addFmAlbumForm = {
          fm_album_state: 1
        }
      })
    },
    async getfmAlbumList() {
      const { data: res } = await this.$http.get('fm_album', { params: this.queryInfo })
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }

      this.fmAlbumList = res.data.data
      this.total = res.data.total
      this.$message.success(res.msg)
      this.loading = false
    },
    addFmAlbumDialogClose() {
      this.addFmAlbumDialogVisible = false
      this.$refs.addFmAlbumFormRef.resetFields()
      this.addFmAlbumForm = {}
    },

    async showEditDialog(albumId) {
      console.log(albumId)
      const { data: res } = await this.$http.get('fm_album/' + albumId)
      console.log(res)
      if (res.code !== 200) {
        this.$message.error('获取数据失败')
      }

      this.editAlbumForm = res.data
      this.editAlbumDialogVisible = true
    },
    editAlbumDialogClose() {
      this.editAlbumDialogVisible = false
      this.$refs.editAlbumFormRef.resetFields()
      this.editAlbumForm = {}
    },
    editAlbum() {
      this.$refs.editAlbumFormRef.validate(async valid => {
        if (!valid) {
          return false
        }
        const { data: res } = await this.$http.put('fm_album', this.editAlbumForm)
        if (res.code !== 201) {
          this.$message.error('修改失败')
        }
        this.$message.success('修改成功')

        this.getfmAlbumList()
        this.editAlbumDialogVisible = false
      })
    },
    async deleteFmAlbum(albumId) {
      const confirmRes = await this.$confirm('此操作将永久删除该节目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)

      if (confirmRes !== 'confirm') {
        return this.$message.info('取消删除')
      }

      const { data: res } = await this.$http.delete('fm_album/' + albumId)

      if (res.code !== 201) {
        return this.$message.error('删除失败')
      }

      this.$message.success('删除成功')
      this.getfmAlbumList()
    }
  }
}
</script>

<style lang="less" scoped>
.avatar-uploader .avatar-uploader-icon {
  width: 110px;
  height: 110px;
  line-height: 110px;
}

.avatar {
  width: 110px;
  height: 110px;
  line-height: 110px;
}

.el-form-item__content {
  line-height: 24px !important;
}

.fm_album_list {
  display: flex;
  flex-direction: row;
}

.fm_album_list .fm_album_name {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  margin-left: 30px;
}
</style>
