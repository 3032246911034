<template>
  <div>
    <breadcrumb param1="课程管理" param2="课程详情" />
    <el-card>
      <el-page-header @back="goBack"></el-page-header>
      <el-row style="margin-top:30px;">
          <el-col :span="12">
            <div class="album_list">
              <div class="fm_image">
                <img style="width: 210px; height: 120px; border-radius: 5px" :src="courceInfo.cource_image_url"/>
              </div>
              <div class="album_name">
                <span class="uy-text">{{ courceInfo.cource_title_zh }}</span>
                <span class="uy-text">{{ courceInfo.cource_title_uy }}</span>
                <span style="color: #606266; font-size: 14px">添加时间：{{ courceInfo.cource_create_time }}</span>
                <div class="movie-desc">电台简介 ：<span class="uy-text">{{courceInfo.cource_introduce_zh}}</span></div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="fm-info">
              <span>价格：{{ courceInfo.cource_price }}</span>
              <span>最后更新：{{ courceInfo.cource_update_time }}</span>
            </div>
          </el-col>
        </el-row>
        <el-divider content-position="left"></el-divider>
        <el-row>
          <el-col :span="12">
            <el-button type="warning" icon="el-icon-plus" @click="addDialogVisible = true" >新增课程</el-button>
          </el-col>
        </el-row>
        <el-table :data="cource_list">
          <el-table-column label="标题">
            <template slot-scope="scope">
              <span class="uy-text" style="direction: rtl; ">
                <div>{{ scope.row.cource_list_title_zh }}</div>
                <div>{{ scope.row.cource_list_title_uy }}</div>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="资源" width="150">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.cource_list_file_type === 1" type="primary">公众号</el-tag>
              <el-tag v-if="scope.row.cource_list_file_type === 2" type="warning">本地</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="cource_list_views" label="播放" width="120"></el-table-column>
          <el-table-column prop="cource_list_update_time" label="最后更新" width="220"></el-table-column>

          <el-table-column label="试看" width="150">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.cource_list_is_free === 0" type="info">免费</el-tag>
              <el-tag v-if="scope.row.cource_list_is_free === 1" type="success">收费</el-tag>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="250">
            <template slot-scope="scope">
              <el-row>
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  size="mini"
                  @click="showEditDialog(scope.row.id)"
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  @click="deleteCourceList(scope.row.id)"
                  >删除</el-button
                >
              </el-row>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 15, 20]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

      <!-- 添加课程对话框  -->
      <el-dialog title="添加课程" :visible.sync="addDialogVisible" width="40%">
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" prop="addForm" label-width="100px" class="demo-addForm">
          <el-form-item label="课程标题" prop="cource_list_title_uy">
            <el-input v-model="addForm.cource_list_title_uy" class="uy-text" ></el-input>
          </el-form-item>
          <el-form-item label="中文标题" prop="cource_list_title_zh">
            <el-input v-model="addForm.cource_list_title_zh"></el-input>
          </el-form-item>
          <el-form-item label="资源类型" prop="movie_list_url_type">
            <el-radio-group v-model="addForm.cource_list_file_type" @change="typeChange">
              <el-radio label="1">公众号视频</el-radio>
              <el-radio label="2">本地文件</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="资源ID">
            <el-input v-model="addForm.cource_list_file_wxv_id" :disabled="type_visible" placeholder="wxv_1234567878abc"></el-input>
          </el-form-item>
          <el-form-item label="视频文件" prop="cource_list_file">
            <el-upload class="upload-demo" :action="uploadUrl + '/video'" ref="upload" :headers="headerObj" :limit="1" :before-upload="beforeVideoUpload" :on-success="videoUploadSuccess">
              <el-button size="small" type="primary" icon="el-icon-upload">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                <el-alert title="请上传mp4格式文件 、规格：360P,720P,1080P" type="warning" :closable="false"></el-alert>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="试看" prop="movie_list_is_vip">
            <el-radio-group v-model="addForm.cource_list_is_free">
              <el-radio label="0">关闭</el-radio>
              <el-radio label="1">开启</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="课程备注" class="uy-text">
            <el-input type="textarea" v-model="addForm.cource_list_remark" rows="4" maxlength="300" show-word-limit ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addCourceList">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 编辑课程对话框  -->
      <el-dialog title="编辑课程" :visible.sync="editDialogVisible" width="40%">
        <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" prop="addForm" label-width="100px" class="demo-addForm">
          <el-form-item label="课程标题" prop="cource_list_title_uy">
            <el-input v-model="editForm.cource_list_title_uy" class="uy-text" ></el-input>
          </el-form-item>
          <el-form-item label="中文标题" prop="cource_list_title_zh">
            <el-input v-model="editForm.cource_list_title_zh"></el-input>
          </el-form-item>
          <el-form-item label="资源类型" prop="movie_list_url_type">
            <el-radio-group v-model="editForm.cource_list_file_type" @change="typeChange">
              <el-radio :label="1">公众号视频</el-radio>
              <el-radio :label="2">本地文件</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="资源ID">
            <el-input v-model="editForm.cource_list_file_wxv_id" :disabled="type_visible" placeholder="wxv_1234567878abc"></el-input>
          </el-form-item>
          <el-form-item label="视频文件" prop="cource_list_file">
            <el-upload class="upload-demo" :action="uploadUrl + '/video'" ref="upload" :headers="headerObj" :limit="1" :before-upload="beforeVideoUpload" :on-success="videoUploadSuccess">
              <el-button size="small" type="primary" icon="el-icon-upload">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                <el-alert title="请上传mp4格式文件 、规格：360P,720P,1080P" type="warning" :closable="false"></el-alert>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="收费" prop="movie_list_is_vip">
            <el-radio-group v-model="editForm.cource_list_is_free">
              <el-radio :label="0">关闭</el-radio>
              <el-radio :label="1">开启</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="课程备注" class="uy-text">
            <el-input type="textarea" v-model="editForm.cource_list_remark" rows="4" maxlength="300" show-word-limit ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editCourceList">确 定</el-button>
        </span>
      </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      courceInfo: [],
      cource_list: [],
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 10
      },
      total: 0,
      addDialogVisible: false,
      editDialogVisible: false,
      addForm: {
        cource_id: this.$route.query.id,
        cource_list_title_uy: '',
        cource_list_title_zh: '',
        cource_list_file_type: '1',
        cource_list_file: '',
        cource_list_file_wxv_id: '',
        cource_list_is_free: '0',
        cource_list_remark: ''
      },
      editForm: {},
      addFormRules: {
        cource_list_title_uy: [
          { required: 'true', message: '请输入课程标题', trigger: 'blur' }
        ],
        cource_list_title_zh: [
          { required: 'true', message: '请输入中文标题', trigger: 'blur' }
        ]
      },
      editFormRules: {
        cource_list_title_uy: [
          { required: 'true', message: '请输入课程标题', trigger: 'blur' }
        ],
        cource_list_title_zh: [
          { required: 'true', message: '请输入中文标题', trigger: 'blur' }
        ]
      },
      headerObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      type_visible: false
    }
  },
  created() {
    this.getCourceInfo()
    this.getCourceList()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    async getCourceInfo() {
      const { data: res } = await this.$http.get('cource/' + this.$route.query.id)
      if (res.code !== 200) {
        this.$message.error(res.msg)
      }
      this.courceInfo = res.data
    },
    async addCourceList() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) {
          return this.$message.error('请填写相关信息')
        }
        const { data: res } = await this.$http.post('courcelist', this.addForm)
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.getCourceList()
        this.addDialogVisible = false
        this.$refs.addFormRef.resetFields()
        this.addForm.cource_list_remark = ''
        this.$refs.upload.clearFiles()
      })
    },
    async editCourceList() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) {
          return this.$message.error('请填写相关信息')
        }
        const { data: res } = await this.$http.put('courcelist', this.editForm)
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.getCourceList()
        this.editDialogVisible = false
        this.$refs.editFormRef.resetFields()
        this.editForm.cource_list_remark = ''
        this.$refs.upload.clearFiles()
      })
    },
    async getCourceList() {
      const { data: res } = await this.$http.get('courcelist/list/' + this.$route.query.id, { params: this.queryInfo })
      if (res.code !== 200) {
        return this.$message.error('获取失败')
      }
      this.total = res.data.total
      this.cource_list = res.data.data
    },
    typeChange() {
      this.type_visible = this.addForm.cource_list_file_type === '2'
    },
    async showEditDialog(id) {
      const { data: res } = await this.$http.get(`courcelist/${id}`)
      if (res.code !== 200) {
        return this.$message.error('获取失败')
      }
      this.editForm = res.data
      this.editDialogVisible = true
    },
    async deleteCourceList(id) {
      const confirmRes = await this.$confirm(
        '此操作将永久删除该分类, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch((err) => err)

      if (confirmRes !== 'confirm') {
        return this.$message.info('取消删除')
      }

      const { data: res } = await this.$http.delete('courcelist/' + id)
      if (res.code !== 200) {
        return this.$message.error('删除失败')
      }
      this.$message.success('删除成功')
      this.getCourceList()
    },
    beforeVideoUpload(file) {
      const isVideo = file.type === 'video/mp4'
      if (!isVideo) {
        this.$message.error('请上传MP4格式的音频文件')
      }
      return isVideo
    },
    videoUploadSuccess(res, file) {
      if (res.code !== 200) {
        return this.$message.error('上传失败')
      }
      this.addForm.cource_list_file = res.local_path
      this.$message.success('上传成功')
    },
    handleSizeChange(newPage) {
      this.queryInfo.pagesize = newPage
      this.getCourceList()
    },
    handleCurrentChange(newPage) {
        this.queryInfo.page = newPage
        this.getCourceList()
    }
  }
}
</script>
<style scoped>
.vocie {
  margin-top: 30px;
}
.album_list {
  display: flex;
  flex-direction: row;
  margin: 0px;
}

.album_list .album_name {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 30px;
}

.album_list span {
  margin-bottom: 15px;
}

.fm_image i {
  position: relative;
  color: #fff;
  font-size: 20px;
  z-index: 100;
}
.fm-info {
  display: flex;
  flex-direction: column;
  /* height: 116px; */
  justify-content: flex-start;
  color: #606266;
  font-size: 14px;
}

.fm-info span {
  margin-bottom: 15px;
}
.movie-desc {
  /* margin-top: 15px; */
  color: #606266;
  /* margin-left:162px; */
  font-size: 14px;
  line-height: 32px;
}
</style>
