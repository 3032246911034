<template>
  <div>
    <!--面包屑组件 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>支付明细</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-table :data="orderList" v-loading="loading">
        <el-table-column label="订单号" sortable prop="trade_no" width="180"></el-table-column>
        <el-table-column label="状态" sortable width="100">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0" type="info"> 未付款 </el-tag>
            <el-tag v-if="scope.row.status === 1" type="primary"> 已付款 </el-tag>
            <el-tag v-if="scope.row.status === 2" type="warning"> 已退款 </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="用户" width="320">
          <template slot-scope="scope">
            <div class="user_list">
              <el-image style="width: 38px; height: 38px" :src="scope.row.avatar">
                <div slot="error" class="image-slot">
                  <i class="el-icon-user"></i>
                </div>
              </el-image>
              <div class="user_name">
                <span>{{scope.row.nickname}}</span>
                <span>{{scope.row.openid }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="金额(元)" sortable prop="amount" width="100"></el-table-column>
        <el-table-column label="类型" sortable width="100">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.type === 1" type="info"> 单曲购买 </el-tag>
            <el-tag v-if="scope.row.type === 9" type="info"> vip会员 </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="购买项目" width="280">
          <template slot-scope="scope">
            <div class="user_list">
              <div class="user_name">
                <span>{{scope.row.obj_title}}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="微信交易流水号" sortable prop="transaction_id" width="240"></el-table-column>
        <el-table-column label="创建时间/更新时间/通知时间" width="200">
          <template slot-scope="scope">
            <div class="user_list">
              <div class="user_name">
                <span>{{scope.row.create_time}}</span>
                <span>{{scope.row.update_time }}</span>
                <span>{{scope.row.notify_time }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-row>
              <el-button type="warning" icon="el-icon-right" size="mini" @click="gotoRefund(scope.row.id)">申请退款</el-button>
            </el-row>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page"
        :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        queryInfo: {
          query: '',
          page: 1,
          pagesize: 10
        },
        total: 0,
        loading: true,
        orderList: [],
        // 上传接口请求头参数
        headerObj: {
          Authorization: window.sessionStorage.getItem('token')
        }
      }
    },
    created() {
      this.getOrderList()
    },
    methods: {
      handleSizeChange(newPage) {
        this.queryInfo.pagesize = newPage
        this.getOrderList()
      },
      handleCurrentChange(newPage) {
        this.queryInfo.page = newPage
        this.getOrderList()
      },
      async getOrderList() {
        const {
          data: res
        } = await this.$http.get('payOrder', {
          params: this.queryInfo
        })
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }

        this.orderList = res.data.data
        this.total = res.data.total
        this.$message.success(res.msg)
        this.loading = false
      },
      async gotoRefund(id) {
        const confirmRes = await this.$confirm('是否确定拒绝此内容?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)
        if (confirmRes !== 'confirm') {
          return this.$message.info('已取消')
        }
        this.loading = true
        this.getOrderList()
      }
    }
  }
</script>

<style lang="less" scoped>
  .avatar-uploader .avatar-uploader-icon {
    width: 110px;
    height: 110px;
    line-height: 110px;
  }

  .avatar {
    width: 110px;
    height: 110px;
    line-height: 110px;
  }

  .el-form-item__content {
    line-height: 24px !important;
  }

  .user_list {
    display: flex;
    flex-direction: row;
  }

  .user_list .user_name {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    margin-left: 10px;
  }

  .el-row {
    margin-bottom: 10px;
  }
</style>
