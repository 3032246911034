<template>
  <div>
    <!--面包屑组件 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>系统设置</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-form :model="configForm" :rules="configFormRules" ref="configFormRef" label-width="150px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="分成比例(%)" prop="rebate_rate">
              <el-input v-model="configForm.rebate_rate"></el-input>
            </el-form-item>
            <el-form-item label="提现密码" prop="password">
              <el-input v-model="configForm.password" show-password></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"></el-col>
        </el-row>
        <el-row>
          <el-col :span="12" :offset="6">
            <el-button type="primary" @click="configModify">保存</el-button>
          </el-col>
        </el-row>

      </el-form>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        configForm: {},
        configFormRules: {
          rebate_rate: [{
            required: true,
            message: '分成比例不能为空',
            trigger: 'blur'
          }],
          password: [{
            required: true,
            message: '提现密码不能为空',
            trigger: 'blur'
          }]
        }
      }
    },
    created() {
      this.getSystemConfig()
    },
    methods: {
      async getSystemConfig() {
        const {
          data: res
        } = await this.$http.get('systemConfig', {
          params: this.queryInfo
        })
        // alert(JSON.stringify(res))
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        this.configForm = res.data
        this.$message.success(res.msg)
      },
      configModify() {
        this.$refs.configFormRef.validate(async valid => {
          if (!valid) {
            return false
          }
          const {
            data: res
          } = await this.$http.put('configModify', this.configForm)
          if (res.code === 200) {
            this.$message.success('修改成功')
          } else {
            this.$message.error('修改失败：' + res.msg)
          }
          this.getSystemConfig()
        })
      }
    }
  }
</script>

<style scoped>
</style>
