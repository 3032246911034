<template>
<div>
    <!--面包屑组件 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/videos' }">视频管理</el-breadcrumb-item>
        <el-breadcrumb-item>添加视频</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
        <el-form :model="uploadForm" :rules="uploadFormRules" ref="uploadFormRef" label-width="100px">
            <el-tabs v-model="activeName">
                <el-tab-pane label="基本信息" name="first">
                    <el-row :gutter="30">
                        <el-col :span="14">
                            <el-form-item label="视频标题" prop="video_title">
                                <el-input v-model="uploadForm.video_title"></el-input>
                            </el-form-item>
                            <el-form-item label="维语标题" prop="video_title_uy">
                                <el-input v-model="uploadForm.video_title_uy"></el-input>
                            </el-form-item>
                            <el-form-item label="资源类型" prop="song_url_type">
                                <el-radio-group v-model="uploadForm.video_url_type" @change="typeChange">
                                    <el-radio label='1'>公众号视频</el-radio>
                                    <el-radio label='0'>本地文件</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="资源ID">
                                <el-input v-model="uploadForm.video_media_id" :disabled="type_visible" placeholder="wxv_1234567878abc..."></el-input>
                            </el-form-item>
                            <el-form-item label="视频文件" prop="video_url">
                                <el-upload :disabled="uploadDisable" class="upload-demo" :action="uploadPath + '/video'" :headers="headerObj" :limit="1" :before-upload="beforeVideoUpload" :on-success="videoUploadSuccess">
                                    <el-button :disabled="uploadDisable" size="small" type="primary" icon="el-icon-upload">点击上传</el-button>
                                    <div slot="tip" class="el-upload__tip">
                                        <el-alert title="只能上传MP4文件，且不超过500MB" type="warning" :closable="false"></el-alert>
                                    </div>
                                </el-upload>
                            </el-form-item>
                            <el-row :gutter="30">
                                <el-col :span="16">
                                    <el-form-item label="视频分类">
                                        <el-select v-model="uploadForm.video_cate_id" placeholder="请选择分类">
                                            <el-option v-for="item in videoCateList" :label="item.category_title_zh" :value="item.id" :key="item.id"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="30">
                                <el-col :span="16">
                                    <el-form-item label="创作者">
                                        <el-select v-model="uploadForm.user_id" filterable placeholder="请选择创作者" clearable>
                                            <el-option v-for="item in singerList" :key="item.id" :value="item.id" :label="item.realname" class="artis_list">
                                                <img :src="item.avatar" style="width:36px;height:36px;line-height:36px;border-radius:5px;" />
                                                <span style="color: #8492a6; font-size: 13px;margin-left:10px;">{{ item.realname }}</span>
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="视频方向" prop="video_direction">
                                <el-radio-group v-model="uploadForm.video_direction">
                                    <el-radio label='1'>宽屏(普通视频)</el-radio>
                                    <el-radio label='2'>竖屏(短视频)</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="视频长度" prop="video_duration">
                                <el-input v-model="uploadForm.video_duration" placeholder="请填写视频时长 （如：03:45）"></el-input>
                            </el-form-item>
                            <el-form-item label="视频封面">
                                <el-upload class="avatar-uploader" :action="uploadPath + '/image'" :headers="headerObj" :show-file-list="false" :on-success="handleThumbSuccess" :before-upload="beforeThumbUpload">
                                    <img v-if="uploadForm.video_thumb" :src="uploadForm.video_thumb" class="avatar" />
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                            </el-form-item>
                            <el-form-item label="视频说明">
                                <el-input type="textarea" :rows="4" :autosize="{ minRows: 4, maxRows: 4 }" placeholder="请输入内容" v-model="uploadForm.shuoming"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane label="付费视听" name="other">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="付费观看">
                                <el-radio-group v-model="uploadForm.is_free" style="margin-left:15px;" @change="radioChange">
                                    <el-radio :label="0">免费</el-radio>
                                    <el-radio :label="1">扣费</el-radio>
                                </el-radio-group>
                                <span style="margin-left:30px;color:#909399;">选择扣费、按付费价格付费的用户才能观看</span>
                            </el-form-item>

                            <el-form-item label="扣费价格">
                                <el-row>
                                    <el-col :span="8" style="margin-left:15px;">
                                        <el-input placeholder="0.00" v-model.number.trim="uploadForm.price" :disabled="price_disable">
                                            <template slot="append">元</template>
                                        </el-input>
                                    </el-col>
                                </el-row>
                            </el-form-item>

                            <el-form-item label="VIP会员">
                                <el-checkbox v-model="uploadForm.is_vip" style="margin-left:15px;" true-label="1" false-label="0">
                                </el-checkbox>
                                <span style="margin-left:30px;color:#909399;">勾选后，非vip会员不能收听此内容</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-tab-pane>
            </el-tabs>
            <el-row>
                <el-col :span="12" :offset="6" style="margin-top:30px;">
                    <el-button type="primary" style="width:120px;" @click="saveUploadForm">提交</el-button>
                    <el-button style="width:120px;" @click="resetForm">重置信息</el-button>
                </el-col>
            </el-row>
        </el-form>
    </el-card>
</div>
</template>

<script>
export default {
    data() {
        return {
            uploadPath: this.global.uploadPath,
            type_visible: false,
            uploadDisable: true,
            uploadForm: {
                video_title: '',
                video_title_uy: '',
                video_url_type: '1',
                video_media_id: '',
                video_url: '',
                video_thumb: '',
                video_small_thumb: '',
                video_cate_id: '',
                video_duration: '',
                shuoming: '',
                is_free: 0,
                price: '',
                is_vip: 0,
                video_direction: '1'
            },
            videoCateList: [],
            singerList: [],
            price_disable: true,
            // 上传接口请求头参数
            // headerObj: {
            //     Authorization: window.sessionStorage.getItem('token'),
            //     'Content-Type': 'multipart/form-data'
            // },
            headerObj: {
                Authorization: window.sessionStorage.getItem('token')
            },
            uploadFormRules: {
                video_title: [{
                    required: true,
                    message: '请输入视频标题',
                    trigger: 'blur'
                }],
                video_title_uy: [{
                    required: true,
                    message: '请输入维语标题',
                    trigger: 'blur'
                }],
                video_duration: [{
                    required: true,
                    message: '请填写视频时长',
                    trigger: 'blur'
                }]
            },
            activeName: 'first',
            value: ''
        }
    },
    created() {
        this.getCategory()
        this.getSingerList()
    },
    methods: {
        handleThumbSuccess(res, file) {
            if (res.code !== 200) {
                this.$message.error('图片上传失败')
            }
            this.uploadForm.video_thumb = res.url
            this.uploadForm.video_small_thumb = res.thumb_small_image
            this.$message.success(res.msg)
        },
        beforeThumbUpload(file) {
            const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
            const isLt5M = file.size / 1024 / 1024 < 5

            if (!isImage) {
                this.$message.error('上传视频封面只能是 JPG|PNG 格式!')
            }
            if (!isLt5M) {
                this.$message.error('上传视频封面大小不能超过 5MB!')
            }
            return isImage && isLt5M
        },
        typeChange() {
            this.type_visible = this.uploadForm.video_url_type === '0'
            this.uploadDisable = this.uploadForm.video_url_type === '1'
        },

        videoUploadSuccess(res, file) {
            if (res.code !== 200) {
                return this.$message.error('上传视频失败')
            }
            this.uploadForm.video_url = res.url
            this.$message.success('上传视频成功')
        },
        beforeVideoUpload(file) {
            const isMP4 = file.type === 'video/mp4'
            const isLt500M = file.size / 1024 / 1024 < 500
            if (!isMP4) {
                this.$message.error('请上传MP4格式的音频文件')
            }
            if (!isLt500M) {
                this.$message.error('音频文件不能超过 500MB!')
            }
            return isMP4 && isLt500M
        },
        async getCategory() {
            const {
                data: res
            } = await this.$http.get('category/type/3')
            if (res.code !== 200) {
                this.$message.error('分类数据获取失败')
            }
            this.videoCateList = res.data
        },
        //  提交上传音乐表单数据
        saveUploadForm() {
            this.$refs.uploadFormRef.validate(async valid => {
                if (!this.uploadDisable && this.uploadForm.video_url === '') {
                    return this.$message.error('请上传视频文件')
                }
                if (this.uploadForm.video_cate_id === '') {
                    return this.$message.error('请选择视频分类')
                }
                if (this.uploadForm.video_thumb === '') {
                    return this.$message.error('请上传视频封面')
                }
                if (!valid) {
                    return false
                }

                const {
                    data: res
                } = await this.$http.post(
                    '/video/add',
                    this.uploadForm
                )

                if (res.code !== 200) {
                    return this.$message.error('视频添加失败')
                }
                this.$message.success('视频添加成功')
                this.$router.push('/videos')
            })
        },
        radioChange(res) {
            console.log(res)
            if (res === 0) {
                this.price_disable = true
            } else {
                this.price_disable = false
            }
        },
        resetForm() {
            this.$refs.uploadFormRef.resetFields()
            this.uploadForm.video_url = ''
            this.uploadForm.video_thumb = ''
            this.uploadForm.video_small_thumb = ''
            this.uploadForm.album = ''
            this.uploadForm.fm_cate_id = ''
            this.uploadForm.shuoming = ''
            this.uploadForm.is_free = 0
            this.uploadForm.price = ''
            this.uploadForm.is_vip = 0
        },
        async getSingerList() {
            const {
                data: res
            } = await this.$http.get('artisOpt')
            if (res.code !== 200) {
                return this.$message.error(res.msg)
            }
            this.singerList = res.data
        }
    }
}
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 200px !important;
    height: 120px !important;
    line-height: 120px;
    text-align: center;
}

.avatar {
    width: 200px !important;
    height: 120px !important;
    display: block;
}

.el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0 0 30px;
}

.el-select {
    width: 100%;
}

.input-new-tag {
    width: 150px !important;
}

.el-tag {
    margin-right: 15px;
}

.el-alert {
    padding: 0 !important;
}

.avatar-uploader .avatar-uploader-icon {
    width: 120px;
    height: 120px;
    line-height: 120px;
}

.avatar {
    width: 120px;
    height: 120px;
}

.album_list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 10px 0;
}
</style>
