<template>
<div>
    <!--面包屑组件 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>广播节目</el-breadcrumb-item>
        <el-breadcrumb-item>广播列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
            <el-row :gutter="10">
                <el-col :span="14">
                    <el-form-item label="节目标题">
                        <el-input v-model="addForm.radio_title"></el-input>
                    </el-form-item>
                    <el-form-item label="维文标题">
                        <el-input v-model="addForm.radio_title_uy"></el-input>
                    </el-form-item>
                    <el-form-item label="资源类型" prop="song_url_type">
                        <el-radio-group v-model="addForm.radio_url_type" @change="typeChange">
                            <el-radio label='1'>公众号音频</el-radio>
                            <el-radio label='0'>本地文件</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="资源ID">
                        <el-input v-model="addForm.radio_media_id" :disabled="type_visible" placeholder="Mzk0MzI0MTQ1N18x..."></el-input>
                    </el-form-item>
                    <el-form-item label="音频文件" prop="video_url">
                        <el-upload :disabled="uploadDisable" class="upload-demo" :action="uploadPath + '/audio'" :headers="headerObj" :limit="1" :before-upload="beforeAudioUpload" :on-success="audioUploadSuccess">
                            <el-button :disabled="uploadDisable" size="small" type="primary" icon="el-icon-upload">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip">
                                <el-alert title="只能上传MP3文件，且不超过300MB" type="warning" :closable="false"></el-alert>
                            </div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="节目封面">
                        <el-upload class="avatar-uploader" :action="uploadPath + '/image'" :headers="headerObj" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                            <img v-if="addForm.image_url" :src="addForm.image_url" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="节目长度" prop="radio_duration">
                        <el-input v-model="addForm.radio_duration" placeholder="请填写节目时长 （如：03:45）"></el-input>
                    </el-form-item>
                    <el-col :span="14">
                        <el-form-item label="选择歌手">
                            <el-select v-model="addForm.singer" filterable placeholder="请选择" @change="selectChange">
                                <el-option v-for="(item, index) in singer" :key="item.id" :value="index" :label="item.singer_name + item.singer_en_name" class="album_list">
                                    <img :src="tmp_url + item.avatar" style="width:36px;height:36px;line-height:36px;border-radius:5px;" />

                                    <span style="color: #8492a6; font-size: 13px;margin-left:10px;">{{ item.singer_name }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="歌手列表">
                            <el-tag v-for="(tag, i) in tags" :key="tag.singer_name" closable @close="handleSingerTagClose(i)">
                                {{ tag }}
                            </el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="节目介绍">
                            <el-input type="textarea" :rows="4" :autosize="{ minRows: 4, maxRows: 4 }" placeholder="请输入内容" v-model="addForm.radio_desc"></el-input>
                        </el-form-item>
                    </el-col>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12" :offset="6" style="margin-top:30px;">
                    <el-button type="primary" style="width:120px;" @click="saveAddForm">提交</el-button>
                    <el-button style="width:120px;" @click="resetForm">重置信息</el-button>
                </el-col>
            </el-row>
        </el-form>
    </el-card>
</div>
</template>

<script>
export default {
    data() {
        return {
            uploadPath: this.global.uploadPath,
            tmp_url: 'https://music.mldqanal.cn/uploads/images/',
            type_visible: false,
            uploadDisable: true,
            tags: [],
            addForm: {
                radio_title: '',
                radio_title_uy: '',
                radio_url_type: '1',
                radio_media_id: '',
                radio_small_image: '',
                radio_desc: '',
                audio_url: '',
                image_url: '',
                radio_singer: '',
                radio_duration: '',
                radio_singer_tag: ''
            },
            singer_tag: [],
            singer: [],
            addFormRules: {
                radio_title: [{
                    required: true,
                    message: '请输入节目标题',
                    trigger: 'blur'
                }],
                radio_title_uy: [{
                    required: true,
                    message: '请输入维文标题',
                    trigger: 'blur'
                }]
            },
            // 上传接口请求头参数
            headerObj: {
                Authorization: window.sessionStorage.getItem('token')
            }
        }
    },
    created() {
        this.getSingerList()
    },
    methods: {
        beforeAudioUpload(file) {
            const isAudio = file.type === 'audio/mpeg'
            const isLt300M = file.size / 1024 / 1024 < 300

            if (!isAudio) {
                this.$message.error('请上传MP3格式的音频文件')
            }
            if (!isLt300M) {
                this.$message.error('音频文件不能超过 300MB!')
            }
            return isAudio && isLt300M
        },
        typeChange() {
            this.type_visible = this.addForm.radio_url_type === '0'
            this.uploadDisable = this.addForm.radio_url_type === '1'
        },
        audioUploadSuccess(res, file) {
            if (res.code !== 200) {
                return this.$message.error('上传音乐失败')
            }
            this.addForm.audio_url = res.url
            this.$message.success('上传音乐成功')
        },

        handleAvatarSuccess(res, file) {
            if (res.code !== 200) {
                this.$message.error('图片上传失败')
            }
            this.addForm.image_url = res.url
            this.addForm.radio_small_image = res.thumb_small_image
            this.$message.success(res.msg)
        },
        beforeAvatarUpload(file) {
            const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
            const isLt5M = file.size / 1024 / 1024 < 5

            if (!isImage) {
                this.$message.error('上传节目封面只能是 JPG|PNG 格式!')
            }
            if (!isLt5M) {
                this.$message.error('上传节目封面大小不能超过 5MB!')
            }
            return isImage && isLt5M
        },
        async saveAddForm() {
            if (this.addForm.radio_title === '') {
                return this.$message.error('请填写节目标题')
            }
            if (this.addForm.radio_title_uy === '') {
                return this.$message.error('请填写节目标题')
            }
            if (!this.uploadDisable && this.addForm.audio_url === '') {
                return this.$message.error('请上传音频文件')
            }
            if (this.addForm.radio_duration === '') {
                return this.$message.error('请填写节目时长')
            }
            if (this.addForm.radio_singer === '') {
                return this.$message.error('请选择歌手')
            }
            const {
                data: res
            } = await this.$http.post('radio/add', this.addForm)
            if (res.code !== 200) {
                return this.$message.error('节目添加失败')
            }

            this.$message.success('节目添加成功')
            this.$router.push('/radio')
        },

        resetForm() {},
        async getSingerList() {
            fetch('https://music.mldqanal.cn/api/index/getSingerList')
                .then(res => res.json())
                .then(data => {
                    this.singer = data.singer_list
                    console.log(data.singer_list)
                })
        },
        selectChange(evnt) {
            // console.log(evnt)
            console.log(this.tags)
            this.singer_tag.push(this.singer[evnt].id)
            this.addForm.radio_singer = this.singer_tag.join(',')
            this.tags.push(this.singer[evnt].singer_name)
            this.addForm.radio_singer_tag = this.tags.join(',')
            console.log(this.addForm.radio_singer)
        },
        handleSingerTagClose(i) {
            this.tags.splice(i, 1)
            this.singer_tag.splice(i, 1)
        }
    }
}
</script>
