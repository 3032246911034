<template>
    <div>
        <breadcrumb param1="内容管理" param2="创建课程" />
        <el-card>
            <el-form ref="editFormRef" :model="editForm" :rules="editFormRules" label-width="100px">
                <el-tabs v-model="activeName">
                    <el-tab-pane label="基本信息" name="first">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="课程标题" prop="cource_title_uy">
                                <el-input v-model="editForm.cource_title_uy" placeholder="دەرىسلىك تىمىسىنى كىرگۈزۈڭ"></el-input>
                            </el-form-item>
                            <el-form-item label="中文标题" prop="cource_title_zh">
                                <el-input v-model="editForm.cource_title_zh" placeholder="请输入课程标题"></el-input>
                            </el-form-item>
                            <el-form-item label="分类标签" prop="song_url_type">
                                <el-radio-group v-model="editForm.cource_category">
                                    <el-radio v-for="item in category_list" :key="item.id" :label='item.id' border size="medium">{{item.category_title_zh}}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="课程海报" prop="cource_image">
                            <el-upload
                                class="avatar-uploader"
                                :action="uploadUrl + '/image'"
                                :headers="headerObj"
                                :show-file-list="false"
                                :on-success="handleImageSuccess"
                                :before-upload="beforeImageUpload"
                            >
                                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            </el-form-item>
                            <el-form-item label="教师名称" prop="cource_auther_uy">
                                <el-input v-model="editForm.cource_auther_uy" placeholder="دەرىس سۆزلىگۈچىنىڭ ئىسمىنى كىرگۈزۈڭ"></el-input>
                            </el-form-item>
                            <el-form-item label="中文名称" prop="cource_auther_zh">
                                <el-input v-model="editForm.cource_auther_zh" placeholder="请输入讲师名称"></el-input>
                            </el-form-item>
                            <el-form-item label="付费课程">
                                    <el-radio-group v-model="editForm.cource_is_free" style="margin-left:15px;" @change="radioChange">
                                        <el-radio :label="0">免费</el-radio>
                                        <el-radio :label="1">付费</el-radio>
                                    </el-radio-group>
                                    <span style="margin-left:30px;color:#909399;">选择付费、按付费价格付费的用户才能观看</span>
                                </el-form-item>

                                <el-form-item label="扣费价格">
                                    <el-row>
                                        <el-col :span="8" style="margin-left:15px;">
                                            <el-input placeholder="0.00" v-model.number.trim="editForm.cource_price" :disabled="price_disable">
                                                <template slot="append">元</template>
                                            </el-input>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                                <el-form-item label="VIP会员">
                                    <el-checkbox v-model="editForm.cource_is_vip" style="margin-left:15px;" :true-label="1" :false-label="0">
                                    </el-checkbox>
                                    <span style="margin-left:30px;color:#909399;">勾选后，非vip会员不能收听此内容</span>
                                </el-form-item>
                        </el-col>
                    </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="课程介绍" name="last">
                        <el-row :gutter="30">
                            <el-col :span="12">
                                <el-form-item label="课程介绍">
                                <quill-editor ref="myTextEditor" v-model="editForm.cource_introduce_uy" :options="editorOption_uy" class="ql-editor-uy"></quill-editor>
                            </el-form-item>
                            <el-form-item label="中文介绍">
                                <quill-editor ref="myTextEditor" v-model="editForm.cource_introduce_zh" :options="editorOption_zh" class="ql-editor-uy"></quill-editor>
                            </el-form-item>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                </el-tabs>
                <el-form-item>
                    <el-button type="primary" @click="editCource">下一步</el-button>
                </el-form-item>
      </el-form>
        </el-card>
    </div>
</template>
<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {
    quillEditor
} from 'vue-quill-editor'
export default {
    components: {
        quillEditor
    },
    data() {
        return {
            editForm: {
                cource_title_uy: '',
                cource_title_zh: '',
                cource_category: '',
                cource_image: '',
                cource_small_image: '',
                cource_auther_uy: '',
                cource_auther_zh: '',
                cource_is_free: 0,
                cource_price: '',
                cource_is_vip: '',
                cource_introduce_uy: '',
                cource_introduce_zh: ''
            },
            category_list: [],
            price_disable: true,
            editFormRules: {
                cource_title_uy: [
                    { required: true, message: '请输入课程标题', trigger: 'blur' }
                ],
                cource_title_zh: [
                    { required: true, message: '请输入中文标题', trigger: 'blur' }
                ],
                cource_auther_uy: [
                    { required: true, message: '请输入教师名称', trigger: 'blur' }
                ],
                cource_auther_zh: [
                    { required: true, message: '请输入中文名称', trigger: 'blur' }
                ]
            },
            headerObj: {
                Authorization: window.sessionStorage.getItem('token')
            },
            imageUrl: '',
            editorOption_zh: {
                placeholder: ' 请输入课程介绍 ...'
            },
            editorOption_uy: {
                placeholder: ' دەرىسلىك ھەققىدە چۈشەنچە يېزىڭ... '
            },
            activeName: 'first'
        }
    },
    created() {
        this.getCourceCategortList()
        this.getCourceInfo()
    },
    methods: {
        async getCourceCategortList() {
            const { data: res } = await this.$http.get('category/type/1')
            if (res.code !== 200) {
                this.$message.error(res.msg)
            }
            this.category_list = res.data
            console.log(res)
        },
        async getCourceInfo() {
            const { data: res } = await this.$http.get('cource/' + this.$route.query.id)
            if (res.code !== 200) {
                this.$message.error(res.msg)
            }
            this.imageUrl = res.data.cource_image_url
            this.editForm = res.data
        },
        handleImageSuccess(res, file) {
            if (res.code !== 200) {
                return this.$message.error('图片上传失败')
            }
            this.editForm.cource_image = res.local_path
            this.imageUrl = res.url
            this.editForm.cource_small_image = res.thumb_small_image
            this.$message.success('上传成功')
        },
        beforeImageUpload(file) {
            const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
            const isLt5M = file.size / 1024 / 1024 < 5

            if (!isImage) {
                this.$message.error('上传节目海报只能是 JPG|PNG 格式!')
            }
            if (!isLt5M) {
                this.$message.error('上传节目海报大小不能超过 5MB!')
            }
            return isImage && isLt5M
        },
        editCource() {
            this.$refs.editFormRef.validate(async valid => {
                if (!valid) {
                return this.$message.error('请填写相关信息')
                }
                const { data: res } = await this.$http.put('cource', this.editForm)
                if (res.code !== 200) {
                    return this.$message.error(res.msg)
                }
                this.$message.success(res.msg)
                this.$router.push('/cource')
            })
        },
        radioChange(res) {
            if (res === 0) {
                this.price_disable = true
            } else {
                this.price_disable = false
            }
        }
    }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 200px !important;
    height: 120px !important;
    line-height: 120px;
    text-align: center;
}
.avatar {
    width: 200px !important;
    height: 120px !important;
    display: block;
}
.el-radio--medium.is-bordered {
    margin:10px 10px;
}
</style>
