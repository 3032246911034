import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/fonts/iconfont.css'
import '../src/assets/css/global.css'
import VCharts from 'v-charts'
import axios from 'axios'
import global from './global.vue'
// 引入全局变量
import uploadUrl from './components/Global'
// 引入组件
import Breadcrumb from './components/Breadcrumb'
Vue.prototype.uploadUrl = uploadUrl.uploadUrl

// 全局注册组件
Vue.component('Breadcrumb', Breadcrumb)
Vue.use(VCharts)
Vue.prototype.global = global
axios.defaults.baseURL = 'https://api.mldqanal.cn/mgr/'
// axios.defaults.baseURL = 'http://localhost:8038/mgr/'

axios.interceptors.request.use(config => {
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config
})

axios.interceptors.response.use(config => {
  if (
    config.data.status === 1002 ||
    config.data.status === 1003 ||
    config.data.status === 1004
  ) {
    window.sessionStorage.clear()
    router.replace({
      path: '/login'
    })
  }
  return config
})
Vue.prototype.$http = axios

Vue.filter('dataFormat', function(dateStr, patren) {
  const dt = new Date(dateStr)

  const y = dt.getFullYear()
  // padStart 函数一般补全用的、padEnd 函数补后面
  const m = (dt.getMonth() + 1).toString().padStart(2, '0')
  const d = dt
    .getDate()
    .toString()
    .padStart(2, '0')
  // return y +'-' + m + '-' + d
  if (patren && patren.toLowerCase() === 'yyyy-mm-dd') {
    return `${y} - ${m} - ${d}`
  }
  if (patren && patren.toLowerCase() === '年-月-日') {
    return `${y} 年 ${m} 月 ${d} 日`
  } else {
    const hh = dt
      .getHours()
      .toString()
      .padStart(2, '0')
    const mm = dt
      .getMinutes()
      .toString()
      .padStart(2, '0')
    const ss = dt
      .getSeconds()
      .toString()
      .padStart(2, '0')

    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
  }
})

Vue.filter('ellipsis', function(value, length) {
  if (!value) return ''
  if (value.length > length) {
    return value.slice(0, length) + '...'
  }
  return value
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
