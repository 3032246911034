<template>
  <div>
    <breadcrumb param1="收益" param2="收益明细" />
    <el-card>
      <el-alert title="友情提示" type="warning" :closable="false" show-icon="true" description="每天北京时间2点产生前一天的收益明细数据"></el-alert>
      <el-table :data="income_list">
        <el-table-column label="音视频">
          <template slot-scope="scope">
            <div class="album_list">
                <el-image style="width: 44px; height: 44px" :src="scope.row.song_image_url"></el-image>
                <div class="album_name">
                    <span>{{scope.row.song_title}}</span>
                    <span>{{ scope.row.song_title_uy }}</span>
                </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="创作者">
          <template slot-scope="scope">
            <div>{{scope.row.artis_name}}</div>
            <div>{{scope.row.artis_name_uy}}</div>
          </template>
        </el-table-column>
         <el-table-column label="资源类型">
          <template slot-scope="scope">
            <span v-if="scope.row.media_type === 4">歌曲</span>
          </template>
        </el-table-column>
        <el-table-column label="点击量" prop="play_count" width="100"></el-table-column>
        <el-table-column label="收益(元)" width="180">
          <template slot-scope="scope">
            <span type="success" style="font-weight:bold;color:green;font-size:18px;">￥{{scope.row.money}}</span>
          </template>
        </el-table-column>
        <el-table-column label="收益时间" prop="log_day" width="120"></el-table-column>
        <el-table-column label="创建时间" prop="create_time" width="160"></el-table-column>
      </el-table>
       <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        queryInfo: {
          query: '',
          page: 1,
          pagesize: 10
        },
        total: 0,
        income_list: []
      }
    },
    created() {
      this.getIncomeList()
    },
    methods: {
      async getIncomeList() {
        const { data: res } = await this.$http.get('income', { params: this.queryInfo })
        if (res.code !== 200) {
          this.$message.error(res.msg)
        }
        this.total = res.data.total
        this.income_list = res.data.data
        console.log(res)
      },
      handleSizeChange(newPage) {
            this.queryInfo.pagesize = newPage
            this.getIncomeList()
        },
        handleCurrentChange(newPage) {
            this.queryInfo.page = newPage
            this.getIncomeList()
      }
    }
  }
</script>
<style scoped>
.album_list {
    display: flex;
    flex-direction: row;
}

.album_list .album_name {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    margin-left: 30px;
}
</style>
