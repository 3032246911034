<template>
<div>
    <!--面包屑组件 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>歌曲管理</el-breadcrumb-item>
        <el-breadcrumb-item>歌曲列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
        <el-row :gutter="15">
            <el-col :span="8">
                <el-input placeholder="请输入内容" v-model="queryInfo.query" class="input-with-select" clearable @clear="getSongList">
                    <el-button slot="append" icon="el-icon-search" @click="getSongList"></el-button>
                </el-input>
            </el-col>
            <el-col :span="4">
                <el-button type="primary" icon="el-icon-upload" @click="goUploadSongPage">上传歌曲</el-button>
            </el-col>
        </el-row>
        <el-table :data="songsList" v-loading="loading">
            <!-- <el-table-column label="歌曲编号" sortable width="120" prop="id" align="center"></el-table-column> -->
            <el-table-column label="歌曲名称">
                <template slot-scope="scope">
                    <div class="album_list">
                        <div class="song_image">
                            <el-image style="width: 64px; height: 64px;border-radius:6px;" :src="scope.row.song_image_url"></el-image>
                            <div class="play-button-wrapper">
                                <div class="music-play-button"><i class="iconfont icon-play"></i></div>
                            </div>
                        </div>
                        <div class="album_name">
                            <span>（{{scope.row.id}}）{{scope.row.song_title}}</span>
                            <span style="color:#909399;font-size:13px" v-if="scope.row.album_name"># {{scope.row.album_name}}</span>
                            <span style="color:#909399;font-size:13px;">上传于 {{ scope.row.song_create_time }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="歌曲分类" sortable width="120">
                <template slot-scope="scope">
                    {{scope.row.cate_name}}
                </template>
            </el-table-column>
            <el-table-column label="播放量" sortable prop="song_views" width="120" align="center"></el-table-column>
            <el-table-column label="推荐" width="120">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.song_is_top" :active-value="1" :inactive-value="0" @change="changeIsTop(scope.row.id)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="创作者" width="250">
                <template slot-scope="scope">
                    <div class="artis_list">
                        <el-image style="width: 44px; height: 44px;border-radius:3px;" :src="scope.row.avatar"></el-image>
                        <div class="artis_name">
                            <span>{{scope.row.user_name}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="状态" sortable width="120">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.song_status === 1" type="warning"> 待审核 </el-tag>
                    <el-tag v-if="scope.row.song_status === 2" type="success"> 通过 </el-tag>
                    <el-tag v-if="scope.row.song_status === 3" type="danger"> 未通过 </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="更新时间" sortable prop="song_update_time" width="220"></el-table-column>
            <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            操作 <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="['goEditPage',scope.row.id]" icon="el-icon-edit">编辑</el-dropdown-item>
                            <el-dropdown-item :command="['gotoPass',scope.row.id]" icon="el-icon-circle-check">通过</el-dropdown-item>
                            <el-dropdown-item :command="['gotoRefuse',scope.row.id]" icon="el-icon-circle-close">拒绝</el-dropdown-item>
                            <el-dropdown-item divided :command="['deleteSong',scope.row.id]" icon="el-icon-delete">删除</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page" :page-sizes="[7, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </el-card>
</div>
</template>

<script>
export default {
    data() {
        return {
            songsList: [],
            queryInfo: {
                query: '',
                page: 1,
                pagesize: 10
            },
            total: 0,
            loading: true
        }
    },
    created() {
        this.getSongList()
    },
    methods: {
        goUploadSongPage() {
            this.$router.push('/songs/upload')
        },

        async getSongList() {
            const {
                data: res
            } = await this.$http.get('/songs', {
                params: this.queryInfo
            })

            if (res.code !== 200) {
                this.$message.error('找不到相关数据')
            }
            this.songsList = res.data.data
            this.total = res.data.total
            this.loading = false
            this.$message.success('数据获取成功')
        },
        handleSizeChange(newPage) {
            console.log(newPage)
            this.queryInfo.pagesize = newPage
            this.getSongList()
        },
        handleCurrentChange(newPage) {
            console.log(newPage)
            this.queryInfo.page = newPage
            this.getSongList()
        },
        async changeIsTop(id) {
            const {
                data: res
            } = await this.$http.get('songs/istop/' + id)
            if (res.code !== 200) {
                return this.$message.error(res.msg)
            }
            this.$message.success(res.msg)
        },
        async deleteSong(id) {
            const confirmRes = await this.$confirm('此操作将永久删除该歌曲, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            if (confirmRes !== 'confirm') {
                return this.$message.info('取消删除')
            }

            const {
                data: res
            } = await this.$http.delete('songs/' + id)

            if (res.code !== 201) {
                return this.$message.error('删除失败')
            }

            this.$message.success('删除成功')
            this.getSongList()
        },
        async gotoPass(id) {
            const confirmRes = await this.$confirm('是否确定审核通过此内容?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            if (confirmRes !== 'confirm') {
                return this.$message.info('已取消')
            }

            const {
                data: res
            } = await this.$http.post('songs/pass/' + id)

            if (res.code !== 201) {
                return this.$message.error('操作失败')
            }

            this.$message.success('操作成功')
            this.getSongList()
        },
        async gotoRefuse(id) {
            const confirmRes = await this.$confirm('是否确定拒绝此内容?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            if (confirmRes !== 'confirm') {
                return this.$message.info('已取消')
            }

            const {
                data: res
            } = await this.$http.post('songs/refuse/' + id)

            if (res.code !== 201) {
                return this.$message.error('操作失败')
            }

            this.$message.success('操作成功')
            this.getSongList()
        },
        goEditPage(id) {
            this.$router.push({
                path: '/songs/edit',
                query: {
                    song_id: id
                }
            })
        },
        handleCommand(command) {
            switch (command[0]) {
                case 'goEditPage':
                    this.goEditPage(command[1])
                    break
                case 'gotoPass':
                    this.gotoPass(command[1])
                    break
                case 'gotoRefuse':
                    this.gotoRefuse(command[1])
                    break
                case 'deleteSong':
                    this.deleteSong(command[1])
                    break
            }
        }
    }
}
</script>

<style lang="less" scoped>
.album_list {
    display: flex;
    flex-direction: row;
    margin: 0px;
}

.album_list .album_name {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-between;
    margin-left: 30px;
}

.song_image i {
    position: relative;
    color: #fff;
    font-size: 20px;
    z-index: 100;
}

.play-button-wrapper {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    top: 12px;
    left: 10px;
    width: 64px;
    height: 64px;
    border-radius: 6px;
    -webkit-transition: all .6s;
    transition: all .6s;
    background-color: rgba(0, 0, 0, .2);
    cursor: pointer;
}

.play-button-wrapper:hover {
    background-color: rgba(0, 0, 0, .4);
}

.play-button-wrapper .music-play-button {
    -webkit-transition: all .4s;
    transition: all .4s;
    color: #fff;
}

.artis_list {
    display: flex;
    flex-direction: row;
}

.artis_list .artis_name {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    margin-left: 10px;
}

.el-row {
    margin-bottom: 10px;
}

.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
}

.el-icon-arrow-down {
    font-size: 12px;
}
</style>
