<template>
<div>
    <breadcrumb param1="歌单管理" param2="歌单列表" />
    <el-card>
        <el-row :gutter="15">
            <el-col :span="8">
                <el-input placeholder="请输入内容" v-model="queryInfo.query" class="input-with-select" clearable @clear="getGedanList">
                    <el-button slot="append" icon="el-icon-search" @click="getGedanList"></el-button>
                </el-input>
            </el-col>
            <el-col :span="4">
                <el-button type="warning" icon="el-icon-plus" @click="AddGedanDialogVisible = true">添加歌单</el-button>
            </el-col>
        </el-row>
        <el-table :data="gedanList" v-loading="loading">
            <el-table-column label="歌单标题">
                <template slot-scope="scope">
                    <div class="album_list">
                        <div class="song_image">
                            <el-image style="width: 84px; height: 84px;border-radius:10px;" :src="scope.row.gedan_image_url"></el-image>
                            <div class="play-button-wrapper">
                                <div class="music-play-button">
                                    <i class="iconfont icon-play"></i>
                                </div>
                            </div>
                        </div>
                        <div class="album_name">
                            <span>({{scope.row.id}}) {{ scope.row.gedan_name }}</span>
                            <span>{{ scope.row.gedan_name_uy }}</span>
                            <span style="color:#909399;font-size:13px;">发布于 {{ scope.row.gedan_create_time }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="歌曲数量" sortable width="150" prop="song_count" align="center"></el-table-column>
            <el-table-column label="上架/下架" width="150">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.gedan_is_online" :active-value="0" :inactive-value="1" @change="changeIsOnline(scope.row.id)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="推荐" width="150">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.gedan_is_top" :active-value="1" :inactive-value="0" @change="changeIsTop(scope.row.id)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="更新时间" sortable width="200" prop="gedan_update_time"></el-table-column>
            <el-table-column label="合集" width="150">
                <template slot-scope="scope">
                    <el-button type="warning" icon="el-icon-folder-add" size="medium" @click="goGedanListPage(scope.row.id)">歌单</el-button>
                </template>

            </el-table-column>
            <el-table-column label="操作" width="220">
                <template slot-scope="scope">
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            编辑 | 删除<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="['edit',scope.row.id]" icon="el-icon-edit">编辑</el-dropdown-item>
                            <el-dropdown-item :command="['delete',scope.row.id]" icon="el-icon-delete">删除</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>

    </el-card>

    <!-- 添加歌单对话框 -->
    <el-dialog title="添加歌单" :visible.sync="AddGedanDialogVisible" width="40%">
        <el-form :model="AddGedanForm" :rules="AddGedanFormRules" ref="AddGedanFormRef" label-width="100px">
            <el-form-item label="歌单名称" prop="gedan_name">
                <el-input v-model="AddGedanForm.gedan_name" placeholder="请填写歌单名称"></el-input>
            </el-form-item>
            <el-form-item label="维文名称" prop="gedan_name_uy">
                <el-input v-model="AddGedanForm.gedan_name_uy" placeholder="ئالبوم ئىسمىنى كىرگۈزۈڭ"></el-input>
            </el-form-item>
            <el-form-item label="歌单封面" prop="gedan_image_url">
                <el-upload class="avatar-uploader" :action="uploadPath + '/image'" :headers="headerObj" :show-file-list="false" :on-success="handleGedanImageSuccess" :before-upload="beforeGedanImageUpload">
                    <img v-if="AddGedanForm.gedan_image_url" :src="AddGedanForm.gedan_image_url" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <span style="color:#909399;font-size:12px;">请上传1:1正方形封面图片</span>
            </el-form-item>
            <el-form-item label="歌单介绍">
                <el-input type="textarea" rows="3" placeholder="请填写歌单介绍" v-model="AddGedanForm.gedan_desc"></el-input>
            </el-form-item>
            <el-form-item label="维文介绍">
                <el-input type="textarea" rows="3" placeholder="ئالبوم چۈشەندۈرلىشىنى يېزىڭ" v-model="AddGedanForm.gedan_desc_uy"></el-input>
            </el-form-item>
            <el-form-item label="发布状态">
                <el-radio-group v-model="AddGedanForm.gedan_is_online">
                    <el-radio :label="0">上架</el-radio>
                    <el-radio :label="1">下架</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="addGedanDialogClose">取 消</el-button>
            <el-button type="primary" @click="addGedan">确 定</el-button>
        </span>
    </el-dialog>

    <!-- 编辑歌单对话框 -->
    <el-dialog title="编辑歌单" :visible.sync="editGedanDialogVisible" width="40%">
        <el-form :model="editGedanForm" :rules="editGedanFormRules" ref="editGedanFormRef" label-width="100px">
            <el-form-item label="歌单名称" prop="gedan_name">
                <el-input v-model="editGedanForm.gedan_name" placeholder="请填写歌单名称"></el-input>
            </el-form-item>
            <el-form-item label="维文名称" prop="gedan_name_uy">
                <el-input v-model="editGedanForm.gedan_name_uy" placeholder="ئالبوم ئىسمىنى كىرگۈزۈڭ"></el-input>
            </el-form-item>
            <el-form-item label="歌单封面" prop="gedan_image_url">
                <el-upload class="avatar-uploader" :action="uploadPath + '/image'" :headers="headerObj" :show-file-list="false" :on-success="handleEditGedanImageSuccess" :before-upload="beforeGedanImageUpload">
                    <img v-if="editGedanForm.gedan_image_url" :src="editGedanForm.gedan_image_url" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <span style="color:#909399;font-size:12px;">请上传1:1正方形封面图片</span>
            </el-form-item>
            <el-form-item label="歌单介绍">
                <el-input type="textarea" rows="3" placeholder="请填写歌单介绍" v-model="editGedanForm.gedan_desc"></el-input>
            </el-form-item>
            <el-form-item label="维文介绍">
                <el-input type="textarea" rows="3" placeholder="ئالبوم چۈشەندۈرلىشىنى يېزىڭ" v-model="editGedanForm.gedan_desc_uy"></el-input>
            </el-form-item>
            <el-form-item label="发布状态">
                <el-radio-group v-model="editGedanForm.gedan_is_online">
                    <el-radio :label="0">上架</el-radio>
                    <el-radio :label="1">下架</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="editGedanDialogClose">取 消</el-button>
            <el-button type="primary" @click="updateGedan">确 定</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            queryInfo: {
                query: '',
                page: 1,
                pagesize: 5
            },
            total: 0,
            gedanList: [],
            AddGedanForm: {
                gedan_name: '',
                gedan_name_uy: '',
                gedan_image_url: '',
                gedan_desc: '',
                gedan_desc_uy: '',
                gedan_is_online: 0
            },
            editGedanForm: {},
            headerObj: {
                Authorization: window.sessionStorage.getItem('token')
            },
            uploadPath: this.global.uploadPath,
            AddGedanDialogVisible: false,
            editGedanDialogVisible: false,
            AddGedanFormRules: {
                gedan_name: [{
                    required: true,
                    message: '请填写专辑名称',
                    trigger: 'blur'
                }],
                gedan_name_uy: [{
                    required: true,
                    message: 'ئالبوم ئىسمىنى يېزىڭ',
                    trigger: 'blur'
                }],
                gedan_image_url: [{
                    required: true,
                    message: '请上传歌单图片',
                    trigger: 'blur'
                }]
            },
            editGedanFormRules: {
                gedan_name: [{
                    required: true,
                    message: '请填写专辑名称',
                    trigger: 'blur'
                }],
                gedan_name_uy: [{
                    required: true,
                    message: 'ئالبوم ئىسمىنى يېزىڭ',
                    trigger: 'blur'
                }],
                gedan_image_url: [{
                    required: true,
                    message: '请上传歌单图片',
                    trigger: 'blur'
                }]
            }
        }
    },
    created() {
        this.getGedanList()
    },
    methods: {
        async getGedanList() {
            this.loading = true
            const {
                data: res
            } = await this.$http.get('gedan', {
                params: this.queryInfo
            })
            if (res.code !== 200) {
                return this.$message.error('获取失败')
            }
            this.total = res.data.total
            this.gedanList = res.data.data
            this.loading = false
            this.$message.success('获取成功')
            console.log(res)
        },
        addGedan() {
            this.$refs.AddGedanFormRef.validate(async valid => {
                if (!valid) {
                    return false
                }
                if (this.AddGedanForm.gedan_image_url === '') {
                    return this.$message.error('请上传歌单图片')
                }
                const {
                    data: res
                } = await this.$http.post('gedan', this.AddGedanForm)
                if (res.code !== 200) {
                    return this.$message.error(res.msg)
                }
                this.$message.success('专辑添加成功')
                this.getGedanList()
                this.AddGedanDialogVisible = false
                this.$refs.AddGedanFormRef.resetFields()
                this.AddGedanForm = {
                    gedan_is_online: 1
                }
            })
        },
        goGedanListPage(id) {
            this.$router.push({
                path: 'gedan/list/',
                query: {
                    id: id
                }
            })
        },
        handleGedanImageSuccess(res, file) {
            this.AddGedanForm.gedan_image_url = res.url
            if (res.code !== 200) {
                this.$message.error('图片上传失败')
            }
            this.$message.success(res.msg)
        },

        handleEditGedanImageSuccess(res, file) {
            if (res.code !== 200) {
                this.$message.error('图片上传失败')
            }
            this.editGedanForm.gedan_image_url = res.url
            this.$message.success(res.msg)
        },
        beforeGedanImageUpload(file) {
            const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
            const isLt5M = file.size / 1024 / 1024 < 5

            if (!isImage) {
                this.$message.error('上传歌曲海报只能是 JPG|PNG 格式!')
            }
            if (!isLt5M) {
                this.$message.error('上传歌曲海报大小不能超过 5MB!')
            }
            return isImage && isLt5M
        },
        addGedanDialogClose() {
            this.AddGedanDialogVisible = false
            this.$refs.AddGedanFormRef.resetFields()
            this.AddGedanForm = {}
        },
        async changeIsOnline(id) {
            const {
                data: res
            } = await this.$http.get('is_online/' + id)
            if (res.code !== 200) {
                return this.$message.error(res.msg)
            }
            this.$message.success(res.msg)
        },
        async changeIsTop(id) {
            const {
                data: res
            } = await this.$http.get('is_top/' + id)
            if (res.code !== 200) {
                return this.$message.error(res.msg)
            }
            this.$message.success(res.msg)
        },
        async editGedan(id) {
            const {
                data: res
            } = await this.$http.get('gedan/' + id)
            if (res.code !== 200) {
                this.$message.error('获取数据失败')
            }
            console.log(res)
            this.editGedanForm = res.data
            this.editGedanDialogVisible = true
        },
        updateGedan() {
            this.$refs.editGedanFormRef.validate(async valid => {
                if (!valid) {
                    return false
                }
                const {
                    data: res
                } = await this.$http.put('gedan', this.editGedanForm)
                if (res.code !== 200) {
                    this.$message.error('修改失败')
                }
                this.$message.success('修改成功')

                this.getGedanList()
                this.editGedanDialogVisible = false
            })
        },
        async deleteGedan(id) {
            const confirmRes = await this.$confirm('此操作将永久删除该歌单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            if (confirmRes !== 'confirm') {
                return this.$message.info('取消删除')
            }
            this.loading = true
            const {
                data: res
            } = await this.$http.delete('gedan/' + id)

            if (res.code !== 200) {
                return this.$message.error(res.msg)
            }
            this.$message.success(res.msg)
            this.getGedanList()
        },
        editGedanDialogClose() {
            this.editGedanDialogVisible = false
            this.$refs.editGedanFormRef.resetFields()
            this.editGedanForm = {}
        },
        handleCommand(command) {
            switch (command[0]) {
                case 'edit':
                    this.editGedan(command[1])
                    break
                case 'delete':
                    this.deleteGedan(command[1])
                    break
            }
        },
        handleSizeChange(newPage) {
            this.queryInfo.pagesize = newPage
            this.getSongList()
        },
        handleCurrentChange(newPage) {
            this.loading = false
            this.queryInfo.page = newPage
            this.getGedanList()
        }
    }
}
</script>

<style lang="less" scoped>
.avatar-uploader .avatar-uploader-icon {
    width: 110px;
    height: 110px;
    line-height: 110px;
}

.avatar {
    width: 110px;
    height: 110px;
    line-height: 110px;
}

.el-form-item__content {
    line-height: 24px !important;
}

.album_list {
    display: flex;
    flex-direction: row;
}

.album_list .album_name {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    margin-left: 30px;
}

.artis_list {
    display: flex;
    flex-direction: row;
}

.artis_list .artis_name {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    margin-left: 10px;
}

.el-row {
    margin-bottom: 10px;
}

.singer_list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 10px 0;
}

.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
}

.el-icon-arrow-down {
    font-size: 12px;
}
</style>
