<template>
<div>
  <!--面包屑组件 -->
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>节目管理</el-breadcrumb-item>
    <el-breadcrumb-item>节目列表</el-breadcrumb-item>
  </el-breadcrumb>

  <el-card>
    <el-row :gutter="15">
      <el-col :span="8">
        <el-input placeholder="请输入内容" v-model="queryInfo.query" class="input-with-select" clearable @clear="getFmList">
          <el-button slot="append" icon="el-icon-search" @click="getFmList"></el-button>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-button type="danger" icon="el-icon-upload" @click="goUploadFmPage">上传节目</el-button>
      </el-col>
    </el-row>
    <el-table :data="fmList" v-loading="loading">
      <el-table-column label="节目名称">
        <template slot-scope="scope">
          <div class="album_list">
            <div class="song_image">
              <el-image style="width: 64px; height: 64px;border-radius:6px;" :src="scope.row.fm_image_url"></el-image>
              <div class="play-button-wrapper">
                <div class="music-play-button"><i class="iconfont icon-play"></i></div>
              </div>

            </div>

            <div class="album_name">
              <span>（{{scope.row.id}}）{{scope.row.fm_title}}</span>
              <span style="color:#909399;font-size:13px" v-if="scope.row.album_name"># {{scope.row.album_name}}</span>
              <span style="color:#909399;font-size:13px;">上传于 {{ scope.row.fm_create_time }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="歌曲分类" width="200">
        <template slot-scope="scope">
          {{scope.row.cate_name}}
        </template>
      </el-table-column>
      <el-table-column label="播放量" prop="fm_views" width="100"></el-table-column>
      <el-table-column label="创作者" width="250">
        <template slot-scope="scope">
          <div class="artis_list">
            <el-image style="width: 52px; height: 52px" :src="scope.row.avatar"></el-image>
            <div class="artis_name">
              <!-- <span>ID：{{scope.row.user_id }}</span> -->
              <span>{{scope.row.user_name}}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="创作者编号" prop="user_id" width="100"></el-table-column>
      <el-table-column label="创作者" prop="user_name" width="100"></el-table-column> -->
      <el-table-column label="状态" width="150">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.fm_status === 1" type="warning"> 待审核 </el-tag>
          <el-tag v-if="scope.row.fm_status === 2" type="success"> 审核通过 </el-tag>
          <el-tag v-if="scope.row.fm_status === 3" type="danger"> 审核未通过 </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="更新时间" prop="fm_update_time" width="220"></el-table-column>
      <el-table-column label="操作" width="220">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-right" size="mini" @click="gotoPass(scope.row.id)">通过</el-button>
          <el-button type="danger" icon="el-icon-close" size="mini" @click="gotoRefuse(scope.row.id)">拒绝</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page" :page-sizes="[7, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </el-card>
</div>
</template>

<script>
export default {
  data() {
    return {
      fmList: [],
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 10
      },
      total: 0,
      loading: true
    }
  },
  created() {
    this.getFmList()
  },
  methods: {
    goUploadFmPage() {
      this.$router.push('/fm/upload')
    },

    async getFmList() {
      const {
        data: res
      } = await this.$http.get('/fm', { params: this.queryInfo })

      if (res.code !== 200) {
        this.$message.error('找不到相关数据')
      }
      this.fmList = res.data.data
      this.total = res.data.total
      this.loading = false
      this.$message.success('数据获取成功')
    },
    handleSizeChange(newPage) {
      console.log(newPage)
      this.queryInfo.pagesize = newPage
      this.getFmList()
    },
    handleCurrentChange(newPage) {
      console.log(newPage)
      this.queryInfo.page = newPage
      this.getFmList()
    },

    async deleteFm(fmId) {
      const confirmRes = await this.$confirm('此操作将永久删除该节目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)

      if (confirmRes !== 'confirm') {
        return this.$message.info('取消删除')
      }

      const { data: res } = await this.$http.delete('fm/' + fmId)

      if (res.code !== 201) {
        return this.$message.error('删除失败')
      }

      this.$message.success('删除成功')
      this.getFmList()
    },
    async gotoPass(id) {
      const confirmRes = await this.$confirm('是否确定审核通过此内容?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)

      if (confirmRes !== 'confirm') {
        return this.$message.info('已取消')
      }

      const { data: res } = await this.$http.post('fm/pass/' + id)

      if (res.code !== 201) {
        return this.$message.error('操作失败')
      }

      this.$message.success('操作成功')
      this.getFmList()
    },
    async gotoRefuse(id) {
      const confirmRes = await this.$confirm('是否确定拒绝此内容?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)

      if (confirmRes !== 'confirm') {
        return this.$message.info('已取消')
      }

      const { data: res } = await this.$http.post('fm/refuse/' + id)

      if (res.code !== 201) {
        return this.$message.error('操作失败')
      }

      this.$message.success('操作成功')
      this.getFmList()
    }
  }
}
</script>

<style lang="less" scoped>
.album_list {
  display: flex;
  flex-direction: row;
  margin: 0px;
}

.album_list .album_name {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
  margin-left: 30px;
}

.song_image i {
  position: relative;
  color: #fff;
  font-size: 20px;
  z-index: 100;
}

.play-button-wrapper {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 12px;
  left: 10px;
  width: 64px;
  height: 64px;
  border-radius: 6px;
  -webkit-transition: all .6s;
  transition: all .6s;
  background-color: rgba(0, 0, 0, .2);
  cursor: pointer;
}

.play-button-wrapper:hover {
  background-color: rgba(0, 0, 0, .4);
}

.play-button-wrapper .music-play-button {
  -webkit-transition: all .4s;
  transition: all .4s;
  color: #fff;
}

.artis_list {
  display: flex;
  flex-direction: row;
}

.artis_list .artis_name {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  margin-left: 10px;
}
</style>
