<template>
  <div>
    <!--面包屑组件 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>广播节目</el-breadcrumb-item>
      <el-breadcrumb-item>广播列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="15">
        <el-col :span="8">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            class="input-with-select"
            clearable
            @clear="getRadioList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getRadioList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="warning" icon="el-icon-plus" @click="goAddRadio"
            >添加节目</el-button
          >
        </el-col>
      </el-row>

      <el-table :data="radioList" v-loading="loading">
        <el-table-column label="节目标题">
          <template slot-scope="scope">
            <div class="album_list">
              <div class="song_image">
                <el-image
                  style="width: 64px; height: 64px;border-radius:6px;"
                  :src="scope.row.radio_thumb_url"
                ></el-image>
                <div class="play-button-wrapper">
                  <div class="music-play-button">
                    <i class="iconfont icon-play"></i>
                  </div>
                </div>
              </div>
              <div class="album_name">
                <span>({{scope.row.id}}) {{ scope.row.radio_title }}</span>
                <span
                  style="color:#909399;font-size:13px"
                  v-if="scope.row.radio_duration"
                >
                  <i class="el-icon-time"></i>
                  {{ scope.row.radio_duration }}</span
                >
                <span style="color:#909399;font-size:13px;"
                  >上传于 {{ scope.row.radio_add_time }}</span
                >
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="播放量"
          sortable
          width="150"
          prop="radio_views"
          align="center"
        ></el-table-column>
        <el-table-column
          label="更新时间"
          sortable
          width="250"
          prop="radio_update_time"
        ></el-table-column>
        <el-table-column label="编辑操作" width="220">
          <template slot-scope="scope">
            <el-row>
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="goEditPage(scope.row.id)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteRadio(scope.row.id)"
                >删除</el-button
              >
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      radioList: [],
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5
      },
      total: 0,
      loading: true,
      addFmAlbumDialogVisible: false
    }
  },
  created() {
    this.getRadioList()
  },
  methods: {
    async getRadioList() {
      this.loading = true
      const { data: res } = await this.$http.get('radio', {
        params: this.queryInfo
      })
      if (res.code !== 200) {
        return this.$message.error('获取失败')
      }
      this.total = res.data.total
      this.radioList = res.data.data
      this.loading = false
      this.$message.success('获取成功')
    },
    goAddRadio() {
      this.$router.push('/radio/add')
    },
    goEditPage(id) {
      this.$router.push({ path: '/radio/edit', query: { radio_id: id } })
    },
    handleSizeChange(newPage) {
      this.queryInfo.pagesize = newPage
      this.getRadioList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getRadioList()
    },
    async deleteRadio(Id) {
      const confirmRes = await this.$confirm(
        '此操作将永久删除该节目, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)

      if (confirmRes !== 'confirm') {
        return this.$message.info('取消删除')
      }

      const { data: res } = await this.$http.delete('radio/' + Id)

      if (res.code !== 201) {
        return this.$message.error('删除失败')
      }

      this.$message.success('删除成功')
      this.getRadioList()
    }
  }
}
</script>

<style lang="less" scoped>
.album_list {
  display: flex;
  flex-direction: row;
  margin: 0px;
}

.album_list .album_name {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
  margin-left: 30px;
}

.song_image i {
  position: relative;
  color: #fff;
  font-size: 20px;
  z-index: 100;
}

.play-button-wrapper {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 12px;
  left: 10px;
  width: 64px;
  height: 64px;
  border-radius: 6px;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.play-button-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.play-button-wrapper .music-play-button {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  color: #fff;
}

.artis_list {
  display: flex;
  flex-direction: row;
}

.artis_list .artis_name {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  margin-left: 10px;
}

.el-row {
  margin-bottom: 10px;
}
</style>
