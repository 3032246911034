<template>
<div>
    <!--面包屑组件 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>专辑管理</el-breadcrumb-item>
        <el-breadcrumb-item>专辑列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
        <el-row :gutter="15">
            <el-col :span="8">
                <el-input placeholder="请输入内容" v-model="queryInfo.query" class="input-with-select" clearable @clear="getAlbumList">
                    <el-button slot="append" icon="el-icon-search" @click="getAlbumList"></el-button>
                </el-input>
            </el-col>
            <el-col :span="4">
                <el-button type="warning" icon="el-icon-plus" @click="AddAlbumDialogVisible = true">添加专辑</el-button>
            </el-col>
        </el-row>

        <el-table :data="albumList" v-loading="loading">
            <!-- <el-table-column label="专辑编号" sortable width="120" prop="id" align="center"></el-table-column> -->
            <el-table-column label="专辑名称" width="300">
                <template slot-scope="scope">
                    <div class="album_list">
                        <el-image style="width: 75px; height: 75px" :src="scope.row.album_image"></el-image>
                        <div class="album_name">
                            <span>（{{scope.row.id}}）{{scope.row.album_name}}</span>
                            <span style="margin-top:5px;color:#909399;">创建于 {{ scope.row.album_create_time }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="专辑介绍">
                <template slot-scope="scope">
                    <p>{{ scope.row.album_desc }}</p>
                </template>
            </el-table-column>
            <el-table-column label="创作者" width="170">
                <template slot-scope="scope">
                    <div class="artis_list">
                        <el-image style="width: 52px; height: 52px" :src="scope.row.avatar"></el-image>
                        <div class="artis_name">
                            <!-- <span>ID：{{scope.row.user_id }}</span> -->
                            <span>{{scope.row.user_name}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="歌曲数量" sortable width="120" prop="songs_count" align="center"></el-table-column>
            <el-table-column label="审核状态" width="120">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.album_status === 1" type="warning"> 待审核 </el-tag>
                    <el-tag v-if="scope.row.album_status === 2" type="success"> 审核通过 </el-tag>
                    <el-tag v-if="scope.row.album_status === 3" type="danger"> 审核未通过 </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="更新时间" sortable width="150" prop="album_update_time"></el-table-column>
            <el-table-column label="审核操作" width="120">
                <template slot-scope="scope">
                    <el-row>
                        <el-button type="success" icon="el-icon-right" size="mini" @click="gotoPass(scope.row.id)">通过</el-button>
                    </el-row>
                    <el-row>
                        <el-button type="danger" icon="el-icon-close" size="mini" @click="gotoRefuse(scope.row.id)">拒绝</el-button>
                    </el-row>
                </template>
            </el-table-column>
            <el-table-column label="编辑操作" width="120">
                <template slot-scope="scope">
                    <el-row>
                        <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑
                        </el-button>
                    </el-row>
                    <el-row>
                        <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteAlbum(scope.row.id)">删除
                        </el-button>
                    </el-row>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </el-card>

    <!-- 添加专辑对话框 -->
    <el-dialog title="添加专辑" :visible.sync="AddAlbumDialogVisible" width="30%">
        <el-form :model="AddAlbumForm" :rules="AddAlbumFormRules" ref="AddAlbumFormRef" label-width="100px">
            <el-form-item label="专辑名称" prop="album_name">
                <el-input v-model="AddAlbumForm.album_name" placeholder="请填写专辑名称"></el-input>
            </el-form-item>
            <el-form-item label="专辑封面">
                <el-upload class="avatar-uploader" :action="uploadPath + '/image'" :headers="headerObj" :show-file-list="false" :on-success="handleAlbumImageSuccess" :before-upload="beforeAlbumImageUpload">
                    <img v-if="AddAlbumForm.album_image" :src="AddAlbumForm.album_image" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <span style="color:#909399;font-size:12px;">请上传1:1正方形封面图片</span>
            </el-form-item>
            <el-form-item label="专辑介绍">
                <el-input type="textarea" rows="3" placeholder="请填写专辑介绍" v-model="AddAlbumForm.album_desc"></el-input>
            </el-form-item>
            <el-form-item label="创作者">
                <el-select v-model="AddAlbumForm.user_id" filterable placeholder="请选择创作者" clearable>
                    <el-option v-for="item in singerList" :key="item.id" :value="item.id" :label="item.realname" class="singer_list">
                        <img :src="item.avatar" style="width:36px;height:36px;line-height:36px;border-radius:5px;" />
                        <span style="color: #8492a6; font-size: 13px;margin-left:10px;">{{ item.realname }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="发布状态">
                <el-radio-group v-model="AddAlbumForm.album_state">
                    <el-radio :label="1">立即发布</el-radio>
                    <el-radio :label="0">暂时不发布</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="addAlbumDialogClose">取 消</el-button>
            <el-button type="primary" @click="addAlbum">确 定</el-button>
        </span>
    </el-dialog>

    <el-dialog title="编辑专辑" :visible.sync="editAlbumDialogVisible" width="30%">
        <el-form :model="editAlbumForm" :rules="AddAlbumFormRules" ref="editAlbumFormRef" label-width="100px">
            <el-form-item label="专辑名称" prop="album_name">
                <el-input v-model="editAlbumForm.album_name" placeholder="请填写专辑名称"></el-input>
            </el-form-item>
            <el-form-item label="专辑封面">
                <el-upload class="avatar-uploader" :action="uploadPath + '/image'" :headers="headerObj" :show-file-list="false" :on-success="handleEditAlbumImageSuccess" :before-upload="beforeAlbumImageUpload">
                    <img v-if="editAlbumForm.album_image" :src="editAlbumForm.album_image" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <span style="color:#909399;font-size:12px;">请上传1:1正方形封面图片</span>
            </el-form-item>
            <el-form-item label="专辑介绍">
                <el-input type="textarea" rows="3" placeholder="请填写专辑介绍" v-model="editAlbumForm.album_desc"></el-input>
            </el-form-item>
            <el-form-item label="创作者">
                <el-select v-model="editAlbumForm.user_id" filterable placeholder="请选择创作者" clearable>
                    <el-option v-for="item in singerList" :key="item.id" :value="item.id" :label="item.realname" class="singer_list">
                        <img :src="item.avatar" style="width:36px;height:36px;line-height:36px;border-radius:5px;" />
                        <span style="color: #8492a6; font-size: 13px;margin-left:10px;">{{ item.realname }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="发布状态">
                <el-radio-group v-model="editAlbumForm.album_state">
                    <el-radio :label="1">立即发布</el-radio>
                    <el-radio :label="0">暂时不发布</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="editAlbumDialogClose">取 消</el-button>
            <el-button type="primary" @click="editAlbum">确 定</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            uploadPath: this.global.uploadPath,
            AddAlbumForm: {
                album_name: '',
                album_image: '',
                album_desc: '',
                album_state: 1,
                user_id: ''
            },
            editAlbumForm: {},
            queryInfo: {
                query: '',
                page: 1,
                pagesize: 10
            },
            total: 0,
            loading: true,
            albumList: [],
            singerList: [],
            // 上传接口请求头参数
            headerObj: {
                Authorization: window.sessionStorage.getItem('token')
            },
            AddAlbumFormRules: {
                album_name: [{
                    required: true,
                    message: '请填写专辑名称',
                    trigger: 'blur'
                }],
                user_id: [{
                    required: true,
                    message: '请选择创作者',
                    trigger: 'blur'
                }]
            },
            AddAlbumDialogVisible: false,
            editAlbumDialogVisible: false
        }
    },
    created() {
        this.getAlbumList()
        this.getSingerList()
    },
    methods: {
        handleAlbumImageSuccess(res, file) {
            this.AddAlbumForm.album_image = res.url
            if (res.code !== 200) {
                this.$message.error('图片上传失败')
            }
            this.$message.success(res.msg)
        },
        handleEditAlbumImageSuccess(res, file) {
            this.editAlbumForm.album_image = res.url
            if (res.code !== 200) {
                this.$message.error('图片上传失败')
            }
            this.$message.success(res.msg)
        },
        beforeAlbumImageUpload(file) {
            const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
            const isLt5M = file.size / 1024 / 1024 < 5

            if (!isImage) {
                this.$message.error('上传歌曲海报只能是 JPG|PNG 格式!')
            }
            if (!isLt5M) {
                this.$message.error('上传歌曲海报大小不能超过 5MB!')
            }
            return isImage && isLt5M
        },
        handleSizeChange(newPage) {
            this.queryInfo.pagesize = newPage
            this.getAlbumList()
        },
        handleCurrentChange(newPage) {
            this.queryInfo.page = newPage
            this.getAlbumList()
        },
        addAlbum() {
            this.$refs.AddAlbumFormRef.validate(async valid => {
                if (!valid) {
                    return false
                }
                const {
                    data: res
                } = await this.$http.post('album/add', this.AddAlbumForm)
                if (res.code !== 200) {
                    return this.$message.error(res.msg)
                }
                this.$message.success('专辑添加成功')
                this.getAlbumList()
                this.AddAlbumDialogVisible = false
                this.$refs.AddAlbumFormRef.resetFields()
                this.AddAlbumForm = {
                    album_state: 1
                }
            })
        },
        async getAlbumList() {
            const {
                data: res
            } = await this.$http.get('album', {
                params: this.queryInfo
            })
            if (res.code !== 200) {
                return this.$message.error(res.msg)
            }

            this.albumList = res.data.data
            this.total = res.data.total
            this.$message.success(res.msg)
            this.loading = false
        },
        async getSingerList() {
            const {
                data: res
            } = await this.$http.get('artisOpt')
            if (res.code !== 200) {
                return this.$message.error(res.msg)
            }
            this.singerList = res.data
        },
        addAlbumDialogClose() {
            this.AddAlbumDialogVisible = false
            this.$refs.AddAlbumFormRef.resetFields()
            this.AddAlbumForm = {}
        },

        async showEditDialog(albumId) {
            console.log(albumId)
            const {
                data: res
            } = await this.$http.get('album/' + albumId)
            if (res.code !== 200) {
                this.$message.error('获取数据失败')
            }
            this.editAlbumForm = res.data
            this.editAlbumDialogVisible = true
        },
        editAlbumDialogClose() {
            this.editAlbumDialogVisible = false
            this.$refs.editAlbumFormRef.resetFields()
            this.editAlbumForm = {}
        },
        editAlbum() {
            this.$refs.editAlbumFormRef.validate(async valid => {
                if (!valid) {
                    return false
                }
                const {
                    data: res
                } = await this.$http.put('album', this.editAlbumForm)
                if (res.code !== 200) {
                    this.$message.error('修改失败')
                }
                this.$message.success('修改成功')

                this.getAlbumList()
                this.editAlbumDialogVisible = false
            })
        },
        async deleteAlbum(albumId) {
            const confirmRes = await this.$confirm('此操作将永久删除该专辑, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            if (confirmRes !== 'confirm') {
                return this.$message.info('取消删除')
            }
            this.loading = true
            const {
                data: res
            } = await this.$http.delete('album/' + albumId)

            if (res.code !== 200) {
                return this.$message.error('删除失败')
            }
            this.$message.success('删除成功')
            this.getAlbumList()
        },
        async gotoPass(id) {
            const confirmRes = await this.$confirm('是否确定审核通过此内容?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)
            if (confirmRes !== 'confirm') {
                return this.$message.info('已取消')
            }
            this.loading = true
            const {
                data: res
            } = await this.$http.post('album/pass/' + id)
            if (res.code === 200) {
                this.$message.success('操作成功')
                this.getAlbumList()
            } else {
                return this.$message.error('操作失败：' + res.msg)
            }
        },
        async gotoRefuse(id) {
            const confirmRes = await this.$confirm('是否确定拒绝此内容?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)
            if (confirmRes !== 'confirm') {
                return this.$message.info('已取消')
            }
            this.loading = true
            const {
                data: res
            } = await this.$http.post('album/refuse/' + id)
            if (res.code === 200) {
                this.$message.success('操作成功')
                this.getAlbumList()
            } else {
                return this.$message.error('操作失败：' + res.msg)
            }
        }
    }
}
</script>

<style lang="less" scoped>
.avatar-uploader .avatar-uploader-icon {
    width: 110px;
    height: 110px;
    line-height: 110px;
}

.avatar {
    width: 110px;
    height: 110px;
    line-height: 110px;
}

.el-form-item__content {
    line-height: 24px !important;
}

.album_list {
    display: flex;
    flex-direction: row;
}

.album_list .album_name {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    margin-left: 30px;
}

.artis_list {
    display: flex;
    flex-direction: row;
}

.artis_list .artis_name {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    margin-left: 10px;
}

.el-row {
    margin-bottom: 10px;
}

.singer_list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 10px 0;
}
</style>
