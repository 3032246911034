import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/Login'
import Home from '@/views/Home'
import Welcome from '@/views/home/Welcome'
import Album from '@/views/content/album/Album'
import Songs from '@/views/content/songs/Songs'
import Upload from '@/views/content/songs/Upload'
import EditSong from '@/views/content/songs/Edit'
import Fm from '@/views/content/fm/Fm'
import FmList from '@/views/content/fm/list'
import FmVoice from '@/views/content/fm/voice'
import AlbumFm from '@/views/content/fm/AlbumFm'
import UploadFm from '@/views/content/fm/UploadFm'
import EditFm from '@/views/content/fm/EditFm'
import Videos from '@/views/content/videos/Videos'
import AddUpload from '@/views/content/videos/AddVideo'
import EditVideo from '@/views/content/videos/EditVideo'
import Setting from '@/views/system/Setting'
import Vip from '@/views/system/Vip'
import User from '@/views/user/User'
import Artis from '@/views/user/Artis'
import Payments from '@/views/order/Payments'
import Rebates from '@/views/finance/Rebates'
import Withdraw from '@/views/finance/Withdraw'
import RadioList from '@/views/content/radio/List'
import RadioAdd from '@/views/content/radio/Add'
import RadioEdit from '@/views/content/radio/Edit'
import Register from '@/views/user/Register'
import EditUser from '@/views/user/Edit'
import Gedan from '@/views/content/gedan/Gedan'
import GedanList from '@/views/content/gedan/GedanList'
import Banner from '@/views/yunying/Banner'
import Menus from '@/views/yunying/Menus'
import About from '@/views/system/About'
import Category from '@/views/content/category/category'
import Cource from '@/views/content/cource/cource'
import CourceAdd from '@/views/content/cource/add'
import CourceList from '@/views/content/cource/list'
import CourceEdit from '@/views/content/cource/edit'
import Order from '@/views/order/order'
import AppUsers from '@/views/user/AppUsers'
import VideoAlbum from '@/views/content/video_album/video_album'
import VideoAlbumList from '@/views/content/video_album/video_album_list'
import Upgrade from '@/views/app-center/upgrade.vue'
import InCome from '@/views/home/income.vue'

Vue.use(Router)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  {
    path: '/home',
    component: Home,
    redirect: '/welcome',
    children: [
      { path: '/welcome', component: Welcome },
      { path: '/songs', component: Songs },
      { path: '/songs/upload', component: Upload },
      { path: '/album', component: Album },
      { path: '/songs/edit', component: EditSong },
      { path: '/fm', component: Fm },
      { path: '/fm/list', component: FmList },
      { path: '/fm/voice', component: FmVoice },
      { path: '/fm/album', component: AlbumFm },
      { path: '/fm/upload', component: UploadFm },
      { path: '/fm/edit', component: EditFm },
      { path: '/videos', component: Videos },
      { path: '/videos/add', component: AddUpload },
      { path: '/videos/edit', component: EditVideo },
      { path: '/setting', component: Setting },
      { path: '/user', component: User },
      { path: '/artis', component: Artis },
      { path: '/payments', component: Payments },
      { path: '/rebates', component: Rebates },
      { path: '/withdraw', component: Withdraw },
      { path: '/vip', component: Vip },
      { path: '/radio', component: RadioList },
      { path: '/radio/add', component: RadioAdd },
      { path: '/radio/edit', component: RadioEdit },
      { path: '/user/register', component: Register },
      { path: '/user/edit', component: EditUser },
      { path: '/gedan', component: Gedan },
      { path: '/gedan/list', component: GedanList },
      { path: '/banner', component: Banner },
      { path: '/menus', component: Menus },
      { path: '/about', component: About },
      { path: '/category', component: Category },
      { path: '/cource', component: Cource },
      { path: '/cource/add', component: CourceAdd },
      { path: '/cource/list', component: CourceList },
      { path: '/cource/edit', component: CourceEdit },
      { path: '/order', component: Order },
      { path: '/users', component: AppUsers },
      { path: '/video/album', component: VideoAlbum },
      { path: '/video/album/list', component: VideoAlbumList },
      { path: '/app-center/upgrade', component: Upgrade },
      { path: '/income', component: InCome }
    ]
  }
]

const router = new Router({
  routes
})

// 加载路由导航守卫
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // 如果用户访问的登录页面、直接放行
  if (to.path === '/login') return next()
  const tokenStr = window.sessionStorage.getItem('token')
  // 没有token、强制跳转到登录页面
  if (!tokenStr) {
    return next('/login')
  }
  next()
})

export default router
