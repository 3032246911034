<template>
  <div>
    <breadcrumb param1="应用中心" param2="版本升级" />
    <el-card>
      <el-row>
        <el-col :span="4">
            <el-button type="warning" icon="el-icon-plus" @click="showAddDialog">添加版本</el-button>
        </el-col>
        <el-table :data="appVersionList" v-loading="loading">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="更新标题">
          <template slot-scope="scope">
            <div >{{ scope.row.upgrade_title_zh }}</div>
            <div class="uy-text">{{ scope.row.upgrade_title_uy }}</div>
          </template>
        </el-table-column>
         <el-table-column label="更新内容">
          <template slot-scope="scope">
            <div >{{ scope.row.upgrade_content_zh }}</div>
            <div class="uy-text">{{ scope.row.upgrade_content_uy }}</div>
          </template>
        </el-table-column>
        <el-table-column label="平台" width="100" prop="platform_type"></el-table-column>
        <el-table-column label="版本号" width="100" prop="upgrade_version"></el-table-column>
        <el-table-column
          label="更新时间"
          prop="update_time"
          width="250"
        ></el-table-column>
        <el-table-column label="操作" width="220">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="deleteVersion(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      </el-row>
    </el-card>
    <el-dialog :title="titleMap[dialogStatus]" :visible.sync="addDialogVisible" width="40%">
      <el-form :model="addForm" :rules="addFormRules" ref="AddFormRef" label-width="130px">
            <el-form-item label="更新标题(中文)" prop="upgrade_title_zh">
                <el-input v-model="addForm.upgrade_title_zh" placeholder="请填写更新标题(中文)"></el-input>
            </el-form-item>
            <el-form-item label="更新标题(维文)" prop="upgrade_title_uy">
                <el-input v-model="addForm.upgrade_title_uy" placeholder="تېمىسىنى كىرگۈزۈڭ"></el-input>
            </el-form-item>
            <el-form-item label="更新内容(中文)" prop="upgrade_content_zh">
                <el-input type="textarea" :rows="3" v-model="addForm.upgrade_content_zh" placeholder="请填写更新内容（中文）"></el-input>
            </el-form-item>
            <el-form-item label="更新内容(维文)" prop="upgrade_content_uy">
                <el-input class="uy-text" type="textarea" :rows="3" v-model="addForm.upgrade_content_uy" placeholder="بۇ نەشىرىدە يېڭلانغان مەزمۇن "></el-input>
            </el-form-item>
            <el-form-item label="平台" prop="platform_type">
              <el-radio-group v-model="addForm.platform_type">
                  <el-radio label='android'>Android(安卓)</el-radio>
                  <el-radio label='ios'>IOS(苹果)</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="版本号" prop="upgrade_version">
                <el-row>
                  <el-col :span="10">
                  <el-input v-model="addForm.upgrade_version" placeholder="1.0.0"></el-input>
                </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="选择文件">
              <el-upload
                class="upload-demo"
                :action= "uploadUrl + '/files'"
                :on-success="onUploadSuccess"
                >
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
              </el-upload>
            </el-form-item>
            <el-form-item label="安装包地址" prop="upgrade_file_url">
                <el-input v-model="addForm.upgrade_file_url" placeholder="可下载安装包地址"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer center">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addVersion">提 交</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addDialogVisible: false,
      appVersionList: [],
      addForm: {
        platform_type: 'android',
        upgrade_title_zh: '',
        upgrade_title_uy: '',
        upgrade_content_zh: '',
        upgrade_content_uy: '',
        upgrade_version: '',
        upgrade_file_url: ''
      },
      dialogStatus: 'add',
      titleMap: {
        add: '添加版本',
        edit: '编辑版本'
      },
      loading: true,
      addFormRules: {
        upgrade_title_zh: [
          { required: true, message: '请填写更新标题(中文)', trigger: 'blur' }
        ],
        upgrade_title_uy: [
          { required: true, message: '请填写更新标题（维文）', trigger: 'blur' }
        ],
        upgrade_content_zh: [
          { required: true, message: '更新内容（中文)', trigger: 'blur' }
        ],
        upgrade_content_uy: [
          { required: true, message: '更新内容（维文)', trigger: 'blur' }
        ],
        platform_type: [
          { required: true, message: '请选择发布平台', trigger: 'blur' }
        ],
        upgrade_version: [
          { required: true, message: '请填写版本号', trigger: 'blur' }
        ],
        upgrade_file_url: [
          { required: true, message: '请填写可下载安装包地址', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getAppVersionList()
  },
  methods: {
    showAddDialog() {
      this.dialogStatus = 'add'
      this.addDialogVisible = true
    },
    addVersion() {
      this.$refs.AddFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error('请填写相关信息')
        }

        const { data: res } = this.dialogStatus === 'edit' ? await this.$http.put('upgrade', this.addForm) : await this.$http.post('upgrade', this.addForm)

        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.addDialogVisible = false
        this.getAppVersionList()
        this.addForm = {
          upgrade_title_zh: '',
          upgrade_title_uy: '',
          upgrade_content_zh: '',
          upgrade_content_uy: '',
          platform_type: '',
          upgrade_version: '',
          upgrade_file_url: ''
        }
      })
    },
    async getAppVersionList() {
       const { data: res } = await this.$http.get('upgrade')
        if (res.code !== 200) {
          return this.$message.error('添加失败')
        }
        this.loading = false
        this.appVersionList = res.data.data
    },
    async showEditDialog(id) {
      const { data: res } = await this.$http.get('upgrade/' + id)
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.addForm = res.data
      this.dialogStatus = 'edit'
      this.addDialogVisible = true
    },
    onUploadSuccess(e) {
      if (e.code !== 200) {
        return this.$message.error(e.msg)
      }
      this.addForm.upgrade_file_url = e.url
    },
    async deleteVersion(id) {
      const confirmRes = await this.$confirm(
        '此操作将永久删除该内容, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmRes !== 'confirm') {
        return this.$message.info('取消删除')
      }

      const { data: res } = await this.$http.delete('upgrade/' + id)
      if (res.code !== 201) {
        return this.$message.error('删除失败')
      }
      this.$message.success('删除成功')
      this.getAppVersionList()
    }
  }
}
</script>
