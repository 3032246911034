<template>
<div>
  <!--面包屑组件 -->
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item :to="{ path: '/fm' }">节目管理</el-breadcrumb-item>
    <el-breadcrumb-item>上传节目</el-breadcrumb-item>
  </el-breadcrumb>

  <el-card>
    <el-form :model="uploadForm" :rules="uploadFormRules" ref="uploadFormRef" label-width="100px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="first">
          <el-row :gutter="30">
            <el-col :span='14'>
              <el-form-item label="节目标题" prop="title">
                <el-input v-model="uploadForm.title"></el-input>
              </el-form-item>
              <el-form-item label="音频文件" prop="audio_url">
                <el-upload class="upload-demo" :action="uploadPath + '/audio'" :headers="headerObj" :limit="1" :before-upload="beforeMusicUpload" :on-success="musicUploadSuccess">

                  <el-button size="small" type="primary" icon="el-icon-upload">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">
                    <el-alert title="只能上传mp3文件，且不超过20MB(如果是wav格式的文件请转换MP3然后在上传)" type="warning" :closable="false"></el-alert>
                  </div>
                </el-upload>
              </el-form-item>
              <el-row :gutter="30">
                <el-col :span="16">
                  <el-form-item label="分类">
                    <el-select v-model="uploadForm.fm_cate_id" placeholder="请选择分类">
                      <el-option v-for="item in fmCateList" :label="item.fm_cate_name" :value="item.fm_cate_id" :key="item.fm_cate_id"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="节目封面">
                <el-upload class="avatar-uploader" :action="uploadPath + '/image'" :headers="headerObj" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                  <img v-if="uploadForm.imageUrl" :src="uploadForm.imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-row :gutter="30">
                <el-col :span='16'>
                  <el-form-item label="专辑">
                    <el-select v-model="uploadForm.album" placeholder="请选择专辑" clearable>
                      <el-option v-for="item in albumList" :key="item.id" :label="item.fm_album_name" :value="item.id" class="album_list">
                        <img :src="item.fm_album_image" style="width:36px;height:36px;line-height:36px;" />
                        <span style="color: #8492a6; font-size: 13px;margin-left:10px;">{{ item.fm_album_name }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-button icon="el-icon-plus" @click="createAlbum">创建专辑</el-button>
                </el-col>
              </el-row>
              <el-form-item label="节目说明">
                <el-input type="textarea" :rows="4" :autosize="{ minRows: 4, maxRows: 4}" placeholder="请输入内容" v-model="uploadForm.shuoming"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 添加专辑对话框 -->
          <el-dialog title="添加专辑" :visible.sync="addAlbumDialogVisible" width="30%">
            <el-form :model="addAlbumForm" :rules="addAlbumFormRules" ref="addAlbumFormRef" label-width="100px">
              <el-form-item label="专辑名称" prop="fm_album_name">
                <el-input v-model="addAlbumForm.fm_album_name" placeholder="请填写专辑名称"></el-input>
              </el-form-item>
              <el-form-item label="专辑封面">
                <el-upload class="avatar-uploader" :action="uploadPath + '/image'" :headers="headerObj" :show-file-list="false" :on-success="handleAlbumImageSuccess" :before-upload="beforeAlbumImageUpload">

                  <img v-if="addAlbumForm.fm_album_image" :src="addAlbumForm.fm_album_image" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <span style="color:#909399;font-size:12px;">请上传1:1正方形封面图片</span>
              </el-form-item>
              <el-form-item label="专辑介绍">
                <el-input type="textarea" rows="3" placeholder="请填写专辑介绍" v-model="addAlbumForm.album_desc"></el-input>
              </el-form-item>
              <el-form-item label="发布状态">
                <el-radio-group v-model="addAlbumForm.fm_album_state">
                  <el-radio :label="1">立即发布</el-radio>
                  <el-radio :label="0">暂时不发布</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="addAlbumDialogClose">取 消</el-button>
              <el-button type="primary" @click="addAlbum">确 定</el-button>
            </span>
          </el-dialog>

        </el-tab-pane>
        <el-tab-pane label="付费视听" name="other">
          <el-row>
            <el-col :span="12">
              <el-form-item label="付费收听">
                <el-radio-group v-model="uploadForm.is_free" style="margin-left:15px;" @change="radioChange">
                  <el-radio :label="0">免费</el-radio>
                  <el-radio :label="1">扣费</el-radio>
                </el-radio-group>
                <span style="margin-left:30px;color:#909399;">选择扣费、按付费价格付费的用户才能收听</span>
              </el-form-item>

              <el-form-item label="扣费价格">
                <el-row>
                  <el-col :span="8" style="margin-left:15px;">
                    <el-input placeholder="0.00" v-model.number.trim="uploadForm.price" :disabled="price_disable">
                      <template slot="append">元</template>
                    </el-input>
                  </el-col>
                </el-row>
              </el-form-item>

              <el-form-item label="VIP会员">
                <el-checkbox v-model="uploadForm.is_vip" style="margin-left:15px;" true-label='1' false-label="0"> </el-checkbox>
                <span style="margin-left:30px;color:#909399;">勾选后，非vip会员不能收听此内容</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
      <el-row>
        <el-col :span="12" :offset="6" style="margin-top:30px;">
          <el-button type="primary" style="width:120px;" @click="saveUploadForm">提交</el-button>
          <el-button style="width:120px;" @click="resetForm">重置信息</el-button>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</div>
</template>

<script>
export default {
  data() {
    return {
      uploadPath: this.global.uploadPath,
      uploadForm: {
        title: '',
        audio_url: '',
        imageUrl: '',
        album: '',
        fm_cate_id: '',
        shuoming: '',
        is_free: 0,
        price: '',
        is_vip: 0
      },
      addAlbumForm: {
        fm_album_name: '',
        fm_album_image: '',
        fm_album_desc: '',
        fm_album_state: 1
      },
      addAlbumDialogVisible: false,
      addAlbumFormRules: {
        fm_album_name: [
          { required: true, message: '请填写专辑名称', trigger: 'blur' }
        ]
      },
      albumList: [],
      fmCateList: [],
      price_disable: true,
      // 上传接口请求头参数
      headerObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      inputSingerValue: '',
      inputZuoquValue: '',
      inputZuociValue: '',
      inputSingerVisible: false,
      inputZuoquVisible: false,
      inputZuociVisible: false,
      uploadFormRules: {
        title: [{
          required: true,
          message: '请输入节目标题',
          trigger: 'blur'
        }]
      },
      activeName: 'first',
      value: ''
    }
  },
  created() {
    this.getCategory()
    this.getFmAlbumList()
  },
  methods: {
    async getFmAlbumList() {
      const {
        data: res
      } = await this.$http.get('fm_album/all')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.albumList = res.data
    },

    createAlbum() {
      this.addAlbumDialogVisible = true
    },
    addAlbumDialogClose() {
      this.addAlbumDialogVisible = false
    },
    addAlbum() {
      this.$refs.addAlbumFormRef.validate(async valid => {
        if (!valid) {
          return false
        }
        const { data: res } = await this.$http.post('fm_album', this.addAlbumForm)
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        this.$message.success('专辑添加成功')
        this.getFmAlbumList()
        this.addAlbumDialogVisible = false
        this.$refs.addAlbumFormRef.resetFields()
        this.AddAlbumForm = {
          fm_album_state: 1
        }
      })
    },
    handleAlbumImageSuccess(res, file) {
      // this.uploadForm.imageUrl = URL.createObjectURL(file.raw)
      this.addAlbumForm.fm_album_image = res.url
      if (res.code !== 200) {
        this.$message.error('图片上传失败')
      }
      this.$message.success(res.msg)
    },
    beforeAlbumImageUpload(file) {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5

      if (!isImage) {
        this.$message.error('上传节目海报只能是 JPG|PNG 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传节目海报大小不能超过 5MB!')
      }
      return isImage && isLt5M
    },
    async getCategory() {
      const {
        data: res
      } = await this.$http.get('fm_category')
      if (res.code !== 200) {
        this.$message.error('分类数据获取失败')
      }
      this.fmCateList = res.data
    },
    handleAvatarSuccess(res, file) {
      // this.uploadForm.imageUrl = URL.createObjectURL(file.raw)
      this.uploadForm.imageUrl = res.url
      if (res.code !== 200) {
        this.$message.error('图片上传失败')
      }
      this.$message.success(res.msg)
    },
    beforeAvatarUpload(file) {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5

      if (!isImage) {
        this.$message.error('上传节目封面只能是 JPG|PNG 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传节目封面大小不能超过 5MB!')
      }
      return isImage && isLt5M
    },
    //  提交上传音乐表单数据
    saveUploadForm() {
      this.$refs.uploadFormRef.validate(async valid => {
        if (this.uploadForm.audio_url === '') {
          return this.$message.error('请上传音频文件')
        }
        if (this.uploadForm.fm_cate_id === '') {
          return this.$message.error('请选择节目分类')
        }
        if (this.uploadForm.imageUrl === '') {
          return this.$message.error('请上传节目封面')
        }

        const {
          data: res
        } = await this.$http.post('fm/add', this.uploadForm)

        if (res.code !== 200) {
          return this.$message.error('节目添加失败')
        }
        this.$message.success('节目添加成功')
        this.$router.push('/fm')
      })
    },

    beforeMusicUpload(file) {
      console.log(file.type)
      const isAudio = file.type === 'audio/mpeg'
      const isLt20M = file.size / 1024 / 1024 < 20

      if (!isAudio) {
        this.$message.error('请上传MP3格式的音频文件')
      }
      if (!isLt20M) {
        this.$message.error('音频文件不能超过 20MB!')
      }
      return isAudio && isLt20M
    },
    musicUploadSuccess(res, file) {
      if (res.code !== 200) {
        return this.$message.error('上传音乐失败')
      }
      this.uploadForm.audio_url = res.url
      this.$message.success('上传音乐成功')
    },

    radioChange(res) {
      console.log(res)
      if (res === 0) {
        this.price_disable = true
      } else {
        this.price_disable = false
      }
    },
    resetForm() {
      this.$refs.uploadFormRef.resetFields()
      this.uploadForm.audio_url = ''
      this.uploadForm.imageUrl = ''
      this.uploadForm.album = ''
      this.uploadForm.fm_cate_id = ''
      this.uploadForm.shuoming = ''
      this.uploadForm.is_free = 0
      this.uploadForm.price = ''
      this.uploadForm.is_vip = 0
    }
  }
}
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 30px;
}

.el-select {
  width: 100%;
}

.input-new-tag {
  width: 150px !important;
}

.el-tag {
  margin-right: 15px;
}

.el-alert {
  padding: 0 !important;
}

.avatar-uploader .avatar-uploader-icon {
  width: 120px;
  height: 120px;
  line-height: 120px;
}

.avatar {
  width: 120px;
  height: 120px;
}

.album_list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 10px 0;
}
</style>
