<template>
  <div>
    <breadcrumb param1="分类管理" param2="内容分类" />
    <el-card>
      <el-row :gutter="15">
        <el-col :span="8">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            class="input-with-select"
            clearable
            @clear="getCategoryList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getCategoryList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button
            type="warning"
            icon="el-icon-plus"
            @click="addCategoryVisible = true"
            >添加分类</el-button
          >
        </el-col>
      </el-row>

        <el-table :data="categoryList" v-loading="loading">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="分类名称">
          <template slot-scope="scope">
            <div class="uy-text">{{ scope.row.category_title_uy }}</div>
            <div class="uy-text">{{ scope.row.category_title_zh }}</div>
          </template>
        </el-table-column>
        <el-table-column label="分类类型" width="200">
            <template slot-scope="scope">
                <el-tag v-if="scope.row.category_type === 1" type="primary" effect="plain">课程分类</el-tag>
                <el-tag v-if="scope.row.category_type === 2" type="warning" effect="plain">电台分类</el-tag>
                <el-tag v-if="scope.row.category_type === 3" type="success" effect="plain">视频分类</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="排序" width="120">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.category_order_num"
              @blur="update_order_num(scope.row)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          label="更新时间"
          prop="category_update_time"
          width="250"
        ></el-table-column>
        <el-table-column label="操作" width="220">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="deleteCategory(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 15, 20]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 添加分类对话框 -->
    <el-dialog title="添加分类" :visible.sync="addCategoryVisible" width="30%">
      <el-form
        :model="AddCategoryForm"
        :rules="AddCategoryFormRules"
        ref="AddCategoryFormRef"
        label-width="100px"
      >
        <el-form-item label="分类名称" prop="category_title_uy">
          <el-input
            v-model="AddCategoryForm.category_title_uy"
            placeholder="请填写分类名称"
            class="uy-text"
          ></el-input>
        </el-form-item>
        <el-form-item label="中文名称" prop="category_title_zh">
          <el-input
            v-model="AddCategoryForm.category_title_zh"
            placeholder="请填写中文名称"
            class="uy-text"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类类型" prop="song_url_type">
            <el-radio-group v-model="AddCategoryForm.category_type">
                <el-radio label='1'>课程分类</el-radio>
                <el-radio label='2'>电台分类</el-radio>
                <el-radio label='3'>视频分类</el-radio>
            </el-radio-group>
        </el-form-item>

        <el-form-item label="分类简介">
          <el-input
            type="textarea"
            rows="3"
            placeholder="请填写分类简介"
            v-model="AddCategoryForm.category_introduce"
            class="uy-text"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="category_order_num">
          <el-input
            v-model="AddCategoryForm.category_order_num"
            style="width:100px"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addCategoryVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCategory">确 定</el-button>
      </span>
    </el-dialog>

        <!-- 编辑分类对话框 -->
    <el-dialog title="添加分类" :visible.sync="editCategoryVisible" width="30%">
      <el-form
        :model="EditCategoryForm"
        :rules="EditCategoryFormRules"
        ref="EditCategoryFormRef"
        label-width="100px"
      >
        <el-form-item label="分类名称" prop="category_title_uy">
          <el-input
            v-model="EditCategoryForm.category_title_uy"
            placeholder="请填写分类名称"
            class="uy-text"
          ></el-input>
        </el-form-item>
        <el-form-item label="中文名称" prop="category_title_zh">
          <el-input
            v-model="EditCategoryForm.category_title_zh"
            placeholder="请填写中文名称"
            class="uy-text"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类类型" prop="song_url_type">
            <el-radio-group v-model="EditCategoryForm.category_type">
                <el-radio :label='1'>课程分类</el-radio>
                <el-radio :label='2'>电台分类</el-radio>
                <el-radio :label='3'>视频分类</el-radio>
            </el-radio-group>
        </el-form-item>

        <el-form-item label="分类简介">
          <el-input
            type="textarea"
            rows="3"
            placeholder="请填写分类简介"
            v-model="EditCategoryForm.category_introduce"
            class="uy-text"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="category_order_num">
          <el-input
            v-model="EditCategoryForm.category_order_num"
            style="width:100px"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editCategoryVisible = false">取 消</el-button>
        <el-button type="primary" @click="editCategory">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 10
      },
      total: 0,
      loading: true,
      categoryList: [],
      addCategoryVisible: false,
      editCategoryVisible: false,
      AddCategoryForm: {
          category_title_uy: '',
          category_title_zh: '',
          category_type: '1',
          category_introduce: '',
          category_order_num: 0
      },
      EditCategoryForm: {},
      AddCategoryFormRules: {
          category_title_uy: [
              { required: true, message: '请输入分类名称', trigger: 'blur' }
          ],
          category_title_zh: [
              { required: true, message: '请输入中文名称', trigger: 'blur' }
          ]
      },
      EditCategoryFormRules: {
          category_title_uy: [
              { required: true, message: '请输入分类名称', trigger: 'blur' }
          ],
          category_title_zh: [
              { required: true, message: '请输入中文名称', trigger: 'blur' }
          ]
      }
    }
  },
  created() {
      this.getCategoryList()
  },
  methods: {
    async getCategoryList() {
        const { data: res } = await this.$http.get('/category', { params: this.queryInfo })
        if (res.code !== 200) {
            this.$message.error('找不到相关数据')
        }
        console.log(res)
        this.categoryList = res.data.data
        this.total = res.data.total
        this.loading = false
        this.$message.success('数据获取成功')
    },
    async addCategory() {
      this.$refs.AddCategoryFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error('请填写相关信息')
        }
        const { data: res } = await this.$http.post(
          'category',
          this.AddCategoryForm
        )
        if (res.code !== 200) {
          return this.$message.error('添加失败')
        }
        this.$message.success('添加成功')
        // 初始化添加对话框
        this.AddCategoryForm = {
            category_title_uy: '',
            category_title_zh: '',
            category_type: '1',
            category_introduce: '',
            category_order_num: 0
        }
        this.getCategoryList()
        this.addCategoryVisible = false
      })
    },
    handleSizeChange(newPage) {
      this.queryInfo.pagesize = newPage
      this.getCategoryList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getCategoryList()
    },
    update_order_num() {},
    async showEditDialog(id) {
      const { data: res } = await this.$http.get('category/' + id)
      if (res.code !== 200) {
        this.$message.error('获取数据失败')
      }
      this.EditCategoryForm = res.data
      this.editCategoryVisible = true
    },
    async editCategory() {
      this.$refs.EditCategoryFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error('请填写相关信息')
        }
        const { data: res } = await this.$http.put('category', this.EditCategoryForm)
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.getCategoryList()
        this.editCategoryVisible = false
      })
    },
    async deleteCategory(id) {
      const confirmRes = await this.$confirm(
        '此操作将永久删除该分类, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmRes !== 'confirm') {
        return this.$message.info('取消删除')
      }

      const { data: res } = await this.$http.delete('category/' + id)
      if (res.code !== 200) {
        return this.$message.error('删除失败')
      }
      this.$message.success('删除成功')
      this.getCategoryList()
    }
  }
}
</script>
