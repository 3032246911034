<template>
  <div>
    <breadcrumb param1="用户" param2="用户列表" />

    <el-card>
      <el-row :gutter="15">
        <el-col :span="8">
          <el-input
            placeholder="请输入用户ID / 用户名称"
            v-model="queryInfo.query"
            class="input-with-select"
            clearable
            @clear="getUserList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getUserList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <!-- <el-button type="warning" icon="el-icon-plus" @click="goAddMovie">新增电影</el-button> -->
        </el-col>
      </el-row>

      <el-table :data="userList" v-loading="loading">
        <el-table-column label="UID" prop="id" width="80"></el-table-column>
        <el-table-column label="头像" width="120">
          <template slot-scope="scope">
            <el-image
              style="width: 64px; height: 64px; border-radius: 6px"
              :src="scope.row.avatarUrl"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="nickName"></el-table-column>
        <el-table-column label="等级">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.is_vip === 0" type="info">普通会员</el-tag>
            <el-tag v-if="scope.row.is_vip === 1" type="success"
              >超级会员</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="所在地">
          <template slot-scope="scope">
            <span v-if="scope.row.gender === 1">男</span>
            <span v-if="scope.row.gender === 2">女</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="注册时间" prop="create_time"></el-table-column> -->
        <el-table-column label="最后登录" prop="update_time"></el-table-column>
        <el-table-column label="所在地">
          <template slot-scope="scope">
            {{ scope.row.country }} - {{ scope.row.province }} -
            {{ scope.row.city }}
          </template>
        </el-table-column>

        <el-table-column label="操作" width="260">
          <template slot-scope="scope">
            <el-row>
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="editUser(scope.row.id)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteUser(scope.row.id)"
                >删除</el-button
              >
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[7, 10, 15, 20]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      userList: [],
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 10
      },
      total: 0
    }
  },
  created() {
    this.getUserList()
  },
  methods: {
    async getUserList() {
      const { data: res } = await this.$http.get('users', {
        params: this.queryInfo
      })
      if (res.code !== 200) {
        this.loading = false
        return this.$message.error(res.msg)
      }
      console.log(res.data)
      this.userList = res.data.data
      this.total = res.data.total
      this.loading = false
    },
    handleSizeChange(newPage) {
      this.queryInfo.pagesize = newPage
      this.getUserList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getUserList()
    },
    editUser() {},
    deleteUser() {}
  }
}
</script>
