<template>
<div>
    <!--面包屑组件 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/artis' }">用户管理</el-breadcrumb-item>
        <el-breadcrumb-item>创作者</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
        <el-row :gutter="15">
            <el-col :span="8">
                <el-input placeholder="请输入关键字" v-model="queryInfo.query" class="input-with-select" clearable @clear="getArtisList">
                    <el-button slot="append" icon="el-icon-search" @click="getArtisList"></el-button>
                </el-input>
            </el-col>
            <el-col :span="4">
                <el-button type="primary" icon="el-icon-upload" @click="goRegisterPage">注册新创作者</el-button>
            </el-col>
        </el-row>
        <el-table :data="artisList" v-loading="loading">
            <!-- <el-table-column label="编号" sortable width="100" prop="id" align="center"></el-table-column> -->
            <!-- <el-table-column label="登录名" width="120" prop="username" align="center"></el-table-column> -->
            <el-table-column label="创作者">
                <template slot-scope="scope">
                    <div class="user_list">
                        <el-image style="width: 64px; height: 64px;border-radius:5px;" :src="scope.row.avatar">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-user"></i>
                            </div>
                        </el-image>
                        <div class="user_name">
                            <span>{{scope.row.realname_uy}}</span>
                            <span>姓名：{{scope.row.realname}}（{{scope.row.gender == '1' ? '男' : '女'}}）</span>
                            <!-- <span>{{scope.row.openid }}</span> -->
                            <span style="margin-top:5px;color:#909399;">注册于 {{scope.row.create_time}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="类型" width="120">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.artis === 1" type="success"> 创作者 </el-tag>
                    <el-tag v-if="scope.row.artis !== 1" type="info"> 普通用户 </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="推荐" width="120">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.artis_is_top" :active-value="1" :inactive-value="0" @change="changeIsTop(scope.row.id)"></el-switch>
                </template>
            </el-table-column>
            <!-- <el-table-column label="真实姓名" width="220" prop="realname" align="center"></el-table-column> -->
            <el-table-column label="手机" width="120" prop="mobile" align="center"></el-table-column>
            <el-table-column label="状态" sortable width="100">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.status === 0" type="danger"> 不正常 </el-tag>
                    <el-tag v-if="scope.row.status === 1" type="primary"> 正常 </el-tag>
                    <el-tag v-if="scope.row.status === 2" type="danger"> 已冻结 </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="余额(元)" sortable width="100" align="center">
                <template slot-scope="scope">
                    <span style="font-size:18px;color:#40a344;font-weight:bold;">{{scope.row.balance}}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column label="VIP续购时间" sortable width="150" prop="vip_pay_time"></el-table-column>
        <el-table-column label="VIP到期时间" sortable width="150" prop="vip_effect_time"></el-table-column> -->
            <!-- <el-table-column label="更新时间" sortable width="150" prop="update_time"></el-table-column> -->
            <el-table-column label="操作" width="310">
                <template slot-scope="scope">
                    <el-row>
                        <el-button type="primary" icon="el-icon-edit" size="mini" @click="goEditPage(scope.row.id)">编辑</el-button>
                        <el-button type="primary" icon="el-icon-edit" size="mini" @click="showPasswordModifyDialog(scope.row.id)">重置密码</el-button>
                        <el-button type="primary" icon="el-icon-money" size="mini" @click="showWithdrawDialog(scope.row.id)">提现</el-button>
                    </el-row>
                    <el-row>
                        <el-button type="danger" icon="el-icon-lock" size="mini" @click="freeze(scope.row.id)">冻结</el-button>
                        <el-button type="success" icon="el-icon-unlock" size="mini" @click="unfreeze(scope.row.id)">解冻</el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteUser(scope.row.id)">删除</el-button>
                    </el-row>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </el-card>
    <el-dialog title="重置密码" :visible.sync="modifyPasswordVisible" width="30%">
        <el-form :model="modifyPasswordForm" :rules="modifyPasswordFormRules" ref="modifyPasswordFormRef" label-width="100px">
            <el-form-item label="编号">
                <el-input v-model="modifyPasswordForm.id" disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="登录名">
                <el-input v-model="modifyPasswordForm.username" disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="new_password">
                <el-input v-model="modifyPasswordForm.new_password" placeholder="请输入新密码"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="password_again">
                <el-input v-model="modifyPasswordForm.password_again" placeholder="请再次输入新密码"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closePasswordModifyDialog">取 消</el-button>
            <el-button type="primary" @click="modifyPassword">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog title="提现" :visible.sync="withdrawVisible" width="30%">
        <el-form :model="withdrawForm" :rules="withdrawFormRules" ref="withdrawFormRef" label-width="150px">
            <el-form-item label="编号">
                <el-input v-model="withdrawForm.id" disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="余额(元)">
                <el-input v-model="withdrawForm.balance" disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="提现金额(元)" prop="amount">
                <el-input v-model="withdrawForm.amount" placeholder="请输入提现金额"></el-input>
            </el-form-item>
            <el-form-item label="提现密码" prop="amount">
                <el-input v-model="withdrawForm.password" placeholder="请输入提现密码" show-password></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeWithdrawDialog">取 消</el-button>
            <el-button type="primary" @click="withdraw">确 定</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            modifyPasswordForm: {},
            withdrawForm: {},
            artisList: [],
            queryInfo: {
                query: '',
                page: 1,
                pagesize: 10
            },
            total: 0,
            loading: true,
            // 上传接口请求头参数
            headerObj: {
                Authorization: window.sessionStorage.getItem('token')
            },
            modifyPasswordFormRules: {
                new_password: [{
                    required: true,
                    message: '请输入新密码',
                    trigger: 'blur'
                }],
                password_again: [{
                    required: true,
                    message: '请再次输入新密码',
                    trigger: 'blur'
                }]
            },
            modifyPasswordVisible: false,
            withdrawFormRules: {
                amount: [{
                    required: true,
                    message: '请输入提现金额',
                    trigger: 'blur'
                }]
            },
            withdrawVisible: false
        }
    },
    created() {
        this.getArtisList()
    },
    methods: {
        handleSizeChange(newPage) {
            this.queryInfo.pagesize = newPage
            this.getArtisList()
        },
        handleCurrentChange(newPage) {
            this.queryInfo.page = newPage
            this.getArtisList()
        },
        async getArtisList() {
            const {
                data: res
            } = await this.$http.get('artis', {
                params: this.queryInfo
            })
            if (res.code !== 200) {
                return this.$message.error(res.msg)
            }
            this.artisList = res.data.data
            this.total = res.data.total
            this.$message.success(res.msg)
            this.loading = false
        },
        goEditPage(id) {
            this.$router.push({
                path: '/user/edit',
                query: {
                    user_id: id
                }
            })
        },
        async freeze(id) {
            const confirmRes = await this.$confirm('是否确定进行此操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            if (confirmRes !== 'confirm') {
                return this.$message.info('已取消')
            }
            this.loading = true
            const {
                data: res
            } = await this.$http.post('artis/freeze/' + id)
            if (res.code === 200) {
                this.$message.success('操作成功')
                this.getArtisList()
            } else {
                return this.$message.error('操作失败：' + res.msg)
            }
        },
        async unfreeze(id) {
            const confirmRes = await this.$confirm('是否确定进行此操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            if (confirmRes !== 'confirm') {
                return this.$message.info('已取消')
            }
            this.loading = true
            const {
                data: res
            } = await this.$http.post('artis/unfreeze/' + id)
            if (res.code === 200) {
                this.$message.success('操作成功')
                this.getArtisList()
            } else {
                return this.$message.error('操作失败：' + res.msg)
            }
        },
        async changeIsTop(id) {
            const {
                data: res
            } = await this.$http.get('artis/istop/' + id)
            if (res.code !== 200) {
                return this.$message.error(res.msg)
            }
            this.$message.success(res.msg)
        },
        async deleteUser(id) {
            const confirmRes = await this.$confirm('是否确定进行此操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            if (confirmRes !== 'confirm') {
                return this.$message.info('已取消')
            }
            this.loading = true
            const {
                data: res
            } = await this.$http.delete('artis/' + id)
            if (res.code === 200) {
                this.$message.success('操作成功')
                this.getArtisList()
            } else {
                return this.$message.error('操作失败：' + res.msg)
            }
        },
        async showPasswordModifyDialog(id) {
            const {
                data: res
            } = await this.$http.get('artis/' + id)
            if (res.code !== 200) {
                this.$message.error('获取数据失败')
            }
            this.modifyPasswordForm = res.data
            this.modifyPasswordVisible = true
        },
        closePasswordModifyDialog() {
            this.modifyPasswordVisible = false
            this.$refs.modifyPasswordFormRef.resetFields()
            this.modifyPasswordForm = {}
        },
        modifyPassword() {
            this.$refs.modifyPasswordFormRef.validate(async valid => {
                if (!valid) {
                    return false
                }
                if (this.modifyPasswordForm.new_password !== this.modifyPasswordForm.password_again) {
                    this.$message.error('两次输入的密码不一致')
                    return false
                }
                this.loading = true
                const {
                    data: res
                } = await this.$http.put('artis/modifyPassword', this.modifyPasswordForm)
                if (res.code === 200) {
                    this.$message.success('修改成功')
                } else {
                    this.$message.error('修改失败：' + res.msg)
                }
                this.modifyPasswordVisible = false
                this.getArtisList()
            })
        },
        async showWithdrawDialog(id) {
            const {
                data: res
            } = await this.$http.get('artis/' + id)
            if (res.code !== 200) {
                this.$message.error('获取数据失败')
            }
            this.withdrawForm = res.data
            this.withdrawVisible = true
        },
        closeWithdrawDialog() {
            this.withdrawVisible = false
            this.$refs.withdrawFormRef.resetFields()
            this.withdrawForm = {}
        },
        withdraw() {
            this.$refs.withdrawFormRef.validate(async valid => {
                if (!valid) {
                    return false
                }
                this.loading = true
                const {
                    data: res
                } = await this.$http.post('withdraw', this.withdrawForm)
                if (res.code === 200) {
                    this.$message.success('提现申请成功')
                } else {
                    this.$message.error('提现申请失败：' + res.msg)
                }
                this.withdrawVisible = false
                this.getArtisList()
            })
        },
        goRegisterPage() {
            this.$router.push('/user/register')
        }
    }
}
</script>

<style lang="less" scoped>
.avatar-uploader .avatar-uploader-icon {
    width: 110px;
    height: 110px;
    line-height: 110px;
}

.avatar {
    width: 110px;
    height: 110px;
    line-height: 110px;
}

.el-form-item__content {
    line-height: 24px !important;
}

.user_list {
    display: flex;
    flex-direction: row;
}

.user_list .user_name {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    margin-left: 10px;
}
</style>
