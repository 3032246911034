<template>
    <div>
        <breadcrumb param1="内容管理" param2="课程列表" />
        <el-card>
            <el-row :gutter="15">
                <el-col :span="8">
                    <el-input placeholder="请输入内容" v-model="queryInfo.query" class="input-with-select" clearable @clear="getCourceList">
                        <el-button slot="append" icon="el-icon-search" @click="getCourceList"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                <el-button type="primary" icon="el-icon-plus" @click="goAddPage()">创建课程</el-button></el-col>
            </el-row>
            <el-table :data="courceList" v-loading="loading">
            <el-table-column label="课程标题">
                <template slot-scope="scope">
                    <div class="album_list">
                        <div class="song_image">
                            <el-image style="width: 164px; height: 92px;border-radius:5px;" :src="scope.row.cource_image"></el-image>
                            <div class="play-button-wrapper">
                                <div class="music-play-button">
                                    <i class="iconfont icon-play"></i>
                                </div>
                            </div>
                        </div>
                        <div class="album_name">
                            <span>({{scope.row.id}}) {{ scope.row.cource_title_zh }}</span>
                            <span>{{ scope.row.cource_title_uy }}</span>
                            <span style="color:#909399;font-size:13px;">发布于 {{ scope.row.cource_create_time }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <!-- <el-table-column label="歌曲数量" sortable width="150" prop="song_count" align="center"></el-table-column> -->
            <el-table-column label="价格" sortable width="150" prop="cource_price" align="center"></el-table-column>
            <el-table-column label="上架/下架" width="90">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.cource_is_online" :active-value="0" :inactive-value="1" @change="changeIsOnline(scope.row.id)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="推荐" width="70">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.cource_is_top" :active-value="1" :inactive-value="0" @change="changeIsTop(scope.row.id)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="更新时间" sortable width="190" prop="cource_update_time"></el-table-column>
            <el-table-column label="合集" width="180">
                <template slot-scope="scope">
                    <el-button type="warning" icon="el-icon-folder-add" size="medium" @click="goCourceListPage(scope.row.id)">课程管理</el-button>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            操作 <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="['edit',scope.row.id]" icon="el-icon-edit">编辑</el-dropdown-item>
                            <el-dropdown-item :command="['delete',scope.row.id]" icon="el-icon-delete">删除</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
        </el-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            courceList: [],
            queryInfo: {
                query: '',
                page: 1,
                pagesize: 5
            },
            total: 0,
            loading: true
        }
    },
    created() {
        this.getCourceList()
    },
    methods: {
        goAddPage() {
            this.$router.push('cource/add')
        },
        editCource(id) {
            this.$router.push('cource/edit?id=' + id)
        },
        async getCourceList() {
            const { data: res } = await this.$http.get('/cource', { params: this.queryInfo })
            if (res.code !== 200) {
                this.$message.error('找不到相关数据')
            }
            console.log(res)
            this.courceList = res.data.data
            this.total = res.data.total
            this.loading = false
            this.$message.success('数据获取成功')
        },
        handleCommand(command) {
            switch (command[0]) {
                case 'edit':
                    this.editCource(command[1])
                    break
                case 'delete':
                    this.deleteCource(command[1])
                    break
            }
        },
        async deleteCource(id) {
            const confirmRes = await this.$confirm('此操作将永久删除该歌单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)
            if (confirmRes !== 'confirm') {
                return this.$message.info('取消删除')
            }
            this.loading = true
            const { data: res } = await this.$http.delete('cource/' + id)
            if (res.code !== 200) {
                return this.$message.error(res.msg)
            }
            this.$message.success(res.msg)
            this.getCourceList()
        },
        async changeIsOnline(id) {
            const { data: res } = await this.$http.get('cource/is_online/' + id)
            if (res.code !== 200) {
                return this.$message.error(res.msg)
            }
            this.$message.success(res.msg)
        },
        async changeIsTop(id) {
            const { data: res } = await this.$http.get('cource/is_top/' + id)
            if (res.code !== 200) {
                return this.$message.error(res.msg)
            }
            this.$message.success(res.msg)
        },
        goCourceListPage(id) {
            this.$router.push({ path: 'cource/list', query: { id: id } })
        },
        handleSizeChange(newPage) {
            this.queryInfo.pagesize = newPage
            this.getCourceList()
        },
        handleCurrentChange(newPage) {
            this.queryInfo.page = newPage
            this.getCourceList()
        }
    }
}
</script>
<style lang="less" scoped>
.avatar-uploader .avatar-uploader-icon {
    width: 210px;
    height: 110px;
    line-height: 110px;
}

.avatar {
    width: 210px;
    height: 110px;
    line-height: 110px;
}

.el-form-item__content {
    line-height: 24px !important;
}

.album_list {
    display: flex;
    flex-direction: row;
}

.album_list .album_name {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    margin-left: 30px;
}

.artis_list {
    display: flex;
    flex-direction: row;
}

.artis_list .artis_name {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    margin-left: 10px;
}

.el-row {
    margin-bottom: 10px;
}

.singer_list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 10px 0;
}

.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
}

.el-icon-arrow-down {
    font-size: 12px;
}
</style>
