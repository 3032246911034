<template>
  <div>
    <!--面包屑组件 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>财务管理</el-breadcrumb-item>
      <el-breadcrumb-item>分成明细</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-table :data="rebateList" v-loading="loading">
        <el-table-column label="分成编号" sortable prop="id"></el-table-column>
        <el-table-column label="类型" sortable>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.type === 1" type="info"> 单曲购买 </el-tag>
            <el-tag v-if="scope.row.type === 9" type="info"> vip会员 </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创作者">
          <template slot-scope="scope">
            <div class="user_list">
              <el-image style="width: 38px; height: 38px" :src="scope.row.avatar">
                <div slot="error" class="image-slot">
                  <i class="el-icon-user"></i>
                </div>
              </el-image>
              <div class="user_name">
                <span>{{scope.row.nickname}}</span>
                <span>{{scope.row.user_id }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="金额(元)" sortable prop="amount"></el-table-column>
        <el-table-column label="支付订单号" prop="trade_no"></el-table-column>
        <el-table-column label="创建时间" sortable prop="create_time"></el-table-column>
      </el-table>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page"
        :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        queryInfo: {
          query: '',
          page: 1,
          pagesize: 10
        },
        total: 0,
        loading: true,
        rebateList: [],
        // 上传接口请求头参数
        headerObj: {
          Authorization: window.sessionStorage.getItem('token')
        }
      }
    },
    created() {
      this.getRebateList()
    },
    methods: {
      handleSizeChange(newPage) {
        this.queryInfo.pagesize = newPage
        this.getRebateList()
      },
      handleCurrentChange(newPage) {
        this.queryInfo.page = newPage
        this.getRebateList()
      },
      async getRebateList() {
        const {
          data: res
        } = await this.$http.get('rebateOrder', {
          params: this.queryInfo
        })
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }

        this.rebateList = res.data.data
        this.total = res.data.total
        this.$message.success(res.msg)
        this.loading = false
      }
    }
  }
</script>

<style lang="less" scoped>
  .avatar-uploader .avatar-uploader-icon {
    width: 110px;
    height: 110px;
    line-height: 110px;
  }

  .avatar {
    width: 110px;
    height: 110px;
    line-height: 110px;
  }

  .el-form-item__content {
    line-height: 24px !important;
  }

  .user_list {
    display: flex;
    flex-direction: row;
  }

  .user_list .user_name {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    margin-left: 10px;
  }

  .el-row {
    margin-bottom: 10px;
  }
</style>
