<template>
<div>
    <!--面包屑组件 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/artis' }">用户管理</el-breadcrumb-item>
        <el-breadcrumb-item>注册新创作者</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
        <el-form :model="registerForm" :rules="registerFormRules" ref="registerFormRef" label-width="100px">
            <el-tabs v-model="activeName">
                <el-tab-pane label="创作者信息" name="first">
                    <el-row :gutter="30">
                        <el-col :span='14'>
                            <el-form-item label="真实姓名" prop="realname">
                                <el-input v-model="registerForm.realname" placeholder="请输入真实姓名"></el-input>
                            </el-form-item>
                            <el-form-item label="维语姓名:" prop="realname_uy">
                                <el-input v-model="registerForm.realname_uy" placeholder="ئۇيغۇرچە ئىسىم"></el-input>
                            </el-form-item>
                            <el-form-item label="性别" prop="gender">
                                <el-radio-group v-model="registerForm.gender" style="margin-left:15px;" @change="radioChange">
                                    <el-radio :label="1">男</el-radio>
                                    <el-radio :label="2">女</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="头像" prop="avatar">
                                <el-upload class="avatar-uploader" :action="uploadPath + '/image'" :headers="headerObj" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                                    <img v-if="registerForm.avatar" :src="registerForm.avatar" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                            </el-form-item>
                            <el-form-item label="手机号" prop="mobile">
                                <el-input v-model="registerForm.mobile" placeholder="请输入手机号"></el-input>
                            </el-form-item>
                            <el-form-item label="介绍">
                                <el-input type="textarea" :rows="4" :autosize="{ minRows: 4, maxRows: 4}" placeholder="请输入创作者介绍" v-model="registerForm.introduce"></el-input>
                            </el-form-item>
                            <el-form-item label="登录名" prop="username">
                                <el-input v-model="registerForm.username" placeholder="请输入登录名"></el-input>
                            </el-form-item>
                            <el-form-item label="登录密码" prop="password">
                                <el-input v-model="registerForm.password" type="password" placeholder="请输入登录密码"></el-input>
                            </el-form-item>
                            <el-form-item label="确认密码" prop="passwordp">
                                <el-input v-model="registerForm.passwordp" type="password" placeholder="请再次输入登录密码"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-tab-pane>
            </el-tabs>
            <el-row>
                <el-col :span="12" :offset="6" style="margin-top:30px;">
                    <el-button type="primary" style="width:120px;" @click="saveRegisterForm">提交</el-button>
                    <el-button style="width:120px;" @click="resetForm">重置信息</el-button>
                </el-col>
            </el-row>
        </el-form>
    </el-card>
</div>
</template>

<script>
export default {
    data() {
        return {
            uploadPath: this.global.uploadPath,
            registerForm: {
                realname: '',
                realname_uy: '',
                avatar: '',
                gender: 0,
                introduce: '',
                mobile: '',
                username: '',
                password: '',
                passwordp: ''
            },
            registerFormRules: {
                realname: [{
                    required: true,
                    message: '请输入真实姓名',
                    trigger: 'blur'
                }],
                realname_uy: [{
                    required: true,
                    message: '请输入维语姓名',
                    trigger: 'blur'
                }],
                gender: [{
                    required: true,
                    message: '请选择性别',
                    trigger: 'blur'
                }],
                avatar: [{
                    required: true,
                    message: '请上传头像图片',
                    trigger: 'blur'
                }],
                mobile: [{
                        required: true,
                        message: '请输入手机号码',
                        trigger: 'blur'
                    },
                    {
                        min: 11,
                        max: 11,
                        message: '请输入11位手机号码',
                        trigger: 'blur'
                    },
                    {
                        pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
                        message: '请输入正确的手机号码'
                    }
                ],
                username: [{
                        required: true,
                        message: '请输入用户名',
                        trigger: 'blur'
                    },
                    {
                        min: 3,
                        max: 15,
                        message: '长度在3到10个字符',
                        trigger: 'blur'
                    }
                ],
                password: [{
                        required: true,
                        message: '请输入密码',
                        trigger: 'blur'
                    },
                    {
                        min: 6,
                        max: 15,
                        message: '长度在6到15个字符',
                        trigger: 'blur'
                    }
                ],
                passwordp: [{
                        required: true,
                        message: '请再次输入密码',
                        trigger: 'blur'
                    },
                    {
                        min: 6,
                        max: 15,
                        message: '长度在6到15个字符',
                        trigger: 'blur'
                    }
                ]
            },
            // 上传接口请求头参数
            headerObj: {
                Authorization: window.sessionStorage.getItem('token')
            },
            activeName: 'first'
        }
    },
    created() {},
    methods: {
        handleAvatarSuccess(res, file) {
            this.registerForm.avatar = res.url
            if (res.code !== 200) {
                this.$message.error('头像上传失败')
            }
            this.$message.success(res.msg)
        },
        beforeAvatarUpload(file) {
            const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
            const isLt5M = file.size / 1024 / 1024 < 5

            if (!isImage) {
                this.$message.error('上传头像图片只能是 JPG|PNG 格式!')
            }
            if (!isLt5M) {
                this.$message.error('上传头像图片大小不能超过 5MB!')
            }
            return isImage && isLt5M
        },
        //  提交上传音乐表单数据
        async saveRegisterForm() {
            this.$refs.registerFormRef.validate(async (valid) => {
                if (!valid) {
                    return false
                }
                if (this.registerForm.gender === 0) {
                    return this.$message.error('请选择性别')
                }
                if (this.registerForm.password !== this.registerForm.passwordp) {
                    return this.$message.error('两次输入的密码不一致')
                }
                const {
                    data: res
                } = await this.$http.post('artis/register', this.registerForm)
                if (res.code === 300) {
                    return this.$message.error(res.msg)
                }
                if (res.code !== 200) {
                    return this.$message.error('创作者注册失败')
                }
                this.$message.success('创作者注册成功')
                this.$router.push('/artis')
            })
        },
        radioChange(res) {
            this.registerForm.gender = res
        },
        resetForm() {
            this.$refs.registerFormRef.resetFields()
            this.registerForm.realname = ''
            this.registerForm.gender = 0
            this.registerForm.avatar = ''
            this.registerForm.introduce = ''
            this.registerForm.mobile = ''
            this.registerForm.username = ''
            this.registerForm.password = ''
            this.registerForm.passwordp = ''
        }
    }
}
</script>

<style lang="less">
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0 0 30px;
}

.el-select {
    width: 100%;
}

.input-new-tag {
    width: 150px !important;
}

.el-tag {
    margin-right: 15px;
}

.el-alert {
    padding: 0 !important;
}

.avatar-uploader .avatar-uploader-icon {
    width: 120px;
    height: 120px;
    line-height: 120px;
}

.avatar {
    width: 120px;
    height: 120px;
}

.album_list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 10px 0;
}
</style>
