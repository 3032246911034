<template>
  <div>
    <breadcrumb param1="电台管理" param2="电台列表" />
    <el-card>
      <el-page-header @back="goBack"></el-page-header>
      <div class="vocie">
        <el-row>
          <el-col :span="12">
            <div class="album_list">
              <div class="fm_image">
                <img style="width: 120px; height: 120px; border-radius: 6px" :src="fmInfo.fm_image_url" />
              </div>
              <div class="album_name">
                <span class="uy-text">{{ fmInfo.fm_title_uy }}</span>
                <span class="uy-text">{{ fmInfo.fm_title_zh }}</span>
                <span style="color: #606266; font-size: 14px"> 分类 : {{ fmInfo.category_title_zh }} - {{ fmInfo.category_title_uy }} </span>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="fm-info">
              <span>作者：<span class="uy-text">{{ fmInfo.fm_auther_zh }} - {{ fmInfo.fm_auther_uy }}</span></span>
              <span>最后更新：{{ fmInfo.fm_update_time }}</span>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <div class="fm-desc">
              电台简介： <span class="uy-text">{{ fmInfo.fm_desc_uy }}</span>
            </div>
            <div class="fm-desc">
              中文简介： <span >{{ fmInfo.fm_desc_zh }}</span>
            </div>
          </el-col>
        </el-row>
        <el-divider content-position="left"></el-divider>
        <el-row>
          <el-col :span="12">
            <el-button
              type="warning"
              icon="el-icon-plus"
              @click="addDialogVisible = true"
              >添加节目</el-button
            >
          </el-col>
        </el-row>
        <el-table :data="fmList">
          <el-table-column label="标题">
            <template slot-scope="scope">
              <span class="uy-text" style="direction: rtl; ">
                <div>{{ scope.row.fm_list_title_uy }}</div>
                <div>{{ scope.row.fm_list_title_zh }}</div>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="资源" width="150">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.fm_list_url_type === 1" type="primary">公众号</el-tag>
              <el-tag v-if="scope.row.fm_list_url_type === 0" type="warning">本地</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="fm_list_views" label="播放" width="120"></el-table-column>
          <el-table-column label="状态" width="150">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.fm_list_is_released === 0" type="primary">已发布</el-tag>
              <el-tag v-if="scope.row.fm_list_is_released === 1" type="warning">待发布</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="fm_list_update_time"
            label="最后更新"
            width="220"
          >
          </el-table-column>

          <el-table-column label="是否收费" width="150">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.fm_list_is_vip === 0" type="info"
                >免费</el-tag
              >
              <el-tag v-if="scope.row.fm_list_is_vip === 1" type="success"
                >VIP</el-tag
              >
            </template>
          </el-table-column>

          <el-table-column label="操作" width="250">
            <template slot-scope="scope">
              <el-row>
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  size="mini"
                  @click="showEditDialog(scope.row.id)"
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  @click="deleteFm(scope.row.id)"
                  >删除</el-button
                >
              </el-row>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.page"
          :page-sizes="[10, 10, 15, 20]"
          :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>

        <!-- 添加电台列表对话框  -->
        <el-dialog
          title="添加节目"
          :visible.sync="addDialogVisible"
          width="40%"
        >
          <el-form
            :model="addForm"
            :rules="addFormRules"
            ref="addFormRef"
            label-width="100px"
            class="demo-addForm"
          >
            <el-form-item label="节目标题" prop="fm_list_title_uy">
              <el-input
                v-model="addForm.fm_list_title_uy"
                class="uy-text"
              ></el-input>
            </el-form-item>
            <el-form-item label="中文标题" prop="fm_list_title_zh">
              <el-input
                v-model="addForm.fm_list_title_zh"
                class="uy-text"
              ></el-input>
            </el-form-item>
            <el-form-item label="资源类型" prop="fm_list_url_type">
              <el-radio-group
                v-model="addForm.fm_list_url_type"
                @change="typeChange"
              >
                <el-radio label="1">公众号音频</el-radio>
                <el-radio label="0">本地文件</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="资源ID">
              <el-input
                v-model="addForm.fm_list_media_id"
                :disabled="type_visible"
                placeholder="Mzk0MzI0MTQ1N18x..."
              ></el-input>
            </el-form-item>
            <el-form-item label="音频文件" prop="fm_list_audio_url">
              <el-upload
                class="upload-demo"
                :action="uploadUrl + '/audio'"
                :headers="headerObj"
                :limit="1"
                :before-upload="beforeAudioUpload"
                :on-success="audioUploadSuccess"
              >
                <el-button size="small" type="primary" icon="el-icon-upload"
                  >点击上传</el-button
                >
                <div slot="tip" class="el-upload__tip">
                  <el-alert
                    title="请上传mp3格式文件"
                    type="warning"
                    :closable="false"
                  ></el-alert>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="VIP收听" prop="fm_list_is_vip">
              <el-radio-group v-model="addForm.fm_list_is_vip">
                <el-radio label="0">关闭</el-radio>
                <el-radio label="1">开启</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-row :gutter="20">
              <el-col :span="2">
                <el-form-item label="定时发布" prop="fm_is_timing_release">
                  <el-checkbox v-model="addForm.fm_list_is_timing_release" true-label="1" false-label="0" @change="timingChecked"></el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item>
                  <el-date-picker type="datetime" placeholder="请选择日期时间" v-model="addForm.fm_list_release_time" value-format="yyyy-MM-dd HH:mm:ss" :disabled="timingDisable"></el-date-picker>
                 </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="addFmList">确 定</el-button>
          </span>
        </el-dialog>

        <!-- 编辑电台列表对话框  -->
        <el-dialog
          title="添加节目"
          :visible.sync="editDialogVisible"
          width="40%"
        >
          <el-form
            :model="editForm"
            :rules="editFormRules"
            ref="editFormRef"
            label-width="100px"
            class="demo-addForm"
          >
            <el-form-item label="节目标题" prop="fm_list_title_uy">
              <el-input
                v-model="editForm.fm_list_title_uy"
                class="uy-text"
              ></el-input>
            </el-form-item>
            <el-form-item label="中文标题" prop="fm_list_title_zh">
              <el-input
                v-model="editForm.fm_list_title_zh"
                class="uy-text"
              ></el-input>
            </el-form-item>
            <el-form-item label="资源类型" prop="fm_list_url_type">
              <el-radio-group
                v-model="editForm.fm_list_url_type"
                @change="typeChangeEdit"
              >
                <el-radio :label="1">公众号音频</el-radio>
                <el-radio :label="0">本地文件</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="资源ID">
              <el-input
                v-model="editForm.fm_list_media_id"
                :disabled="type_visible_edit"
                placeholder="Mzk0MzI0MTQ1N18x..."
              ></el-input>
            </el-form-item>
            <el-form-item label="音频文件" prop="fm_list_audio_url">
              <el-upload
                class="upload-demo"
                :action="uploadUrl + '/audio'"
                :headers="headerObj"
                :limit="1"
                :before-upload="beforeAudioUpload"
                :on-success="editAudioUploadSuccess"
              >
                <el-button size="small" type="primary" icon="el-icon-upload"
                  >点击上传</el-button
                >
                <div slot="tip" class="el-upload__tip">
                  <el-alert
                    title="请上传mp3格式文件"
                    type="warning"
                    :closable="false"
                  ></el-alert>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="VIP收听" prop="fm_list_is_vip">
              <el-radio-group v-model="editForm.fm_list_is_vip">
                <el-radio :label="0">关闭</el-radio>
                <el-radio :label="1">开启</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-row :gutter="20">
              <el-col :span="2">
                <el-form-item label="定时发布" prop="fm_is_timing_release">
                  <el-checkbox v-model="editForm.fm_list_is_timing_release" :true-label="1" :false-label="0" @change="editTimingChecked"></el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item>
                  <el-date-picker type="datetime" placeholder="请选择日期时间" v-model="editForm.fm_list_release_time" value-format="yyyy-MM-dd HH:mm:ss" :disabled="editTimingDisable"></el-date-picker>
                 </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="editDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="editFmList">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addDialogVisible: false,
      editDialogVisible: false,
      type_visible: false,
      type_visible_edit: false,
      timingDisable: true,
      editTimingDisable: true,
      fmList: [],
      fmInfo: [],
      addForm: {
        fm_list_title_uy: '',
        fm_list_title_zh: '',
        fm_list_audio_url: '',
        fm_list_is_vip: '0',
        fm_id: this.$route.query.id,
        fm_list_url_type: '1',
        fm_list_media_id: '',
        fm_list_is_timing_release: '',
        fm_list_release_time: ''
      },
      editForm: {},
      // 上传接口请求头参数
      headerObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      addFormRules: {
        fm_list_title_uy: [
          { required: 'true', message: 'تىمىسىنى كىرگۈزۈڭ', trigger: 'blur' }
        ],
        fm_list_title_zh: [
          { required: 'true', message: '请输入节目标题', trigger: 'blur' }
        ]
      },
      editFormRules: {
        fm_list_title_uy: [
          { required: 'true', message: 'تىمىسىنى كىرگۈزۈڭ', trigger: 'blur' }
        ],
        fm_list_title_zh: [
          { required: 'true', message: '请输入节目标题', trigger: 'blur' }
        ]
      },
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 10
      },
      total: 0
    }
  },
  created() {
    this.getFmInfo()
    this.getFmList()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    async getFmInfo() {
      const { data: res } = await this.$http.get('fm/' + this.$route.query.id)
      console.log(res)
      if (res.code !== 200) {
        this.$message.error('数据获取失败')
      }
      this.fmInfo = res.data
    },
    async getFmList() {
      const { data: res } = await this.$http.get(
        'fmlist/' + this.$route.query.id,
        { params: this.queryInfo }
      )
      if (res.code !== 200) {
        return this.$message.error('获取失败')
      }
      this.total = res.data.total
      this.fmList = res.data.data
      console.log(res.data.data)
    },
    async addFmList() {
      console.log(this.addForm)
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) {
          return this.$message.error('请填写相关信息')
        }
        if (this.addForm.fm_list_is_timing_release === '1' && this.addForm.fm_list_release_time === '') {
          return this.$message.error('请选择发布时间')
        }
        const { data: res } = await this.$http.post('fmlist', this.addForm)
        if (res.code !== 200) {
          return this.$message.error('添加失败')
        }
        this.$message.success('添加成功')
        this.getFmList()
        this.addDialogVisible = false
      })
    },
    editFmList() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) {
          return this.$message.error('请填写相关信息')
        }
        if (this.timingChecked && this.editForm.fm_list_release_time === '') {
          return this.$message.error('请选择定时发布时间')
        }
        const { data: res } = await this.$http.put('fmlist', this.editForm)
        if (res.code !== 200) {
          return this.$message.error('修改失败')
        }
        this.$message.success('修改成功')
        this.getFmList()
        this.editDialogVisible = false
      })
    },
    timingChecked(e) {
      if (e === '1') {
        this.timingDisable = false
      } else {
        this.timingDisable = true
      }
    },
    editTimingChecked(e) {
      if (e === 1) {
        this.editTimingDisable = false
      } else {
        this.editTimingDisable = true
      }
    },
    goMusicUrl() {
      var tempwindow = window.open('_blank')
      tempwindow.location = this.editForm.fm_list_audio_play_url
    },
    async showEditDialog(id) {
      const { data: res } = await this.$http.get('fmlist/info/' + id)
      if (res.code !== 200) {
        return this.$message.error('获取失败')
      }
      console.log(res)
      this.editForm = res.data
      this.type_visible_edit = this.editForm.fm_list_url_type === 0
      this.editTimingDisable = this.editForm.fm_list_is_timing_release === 0
      this.editDialogVisible = true
    },
    async deleteFm(id) {
      const confirmRes = await this.$confirm(
        '此操作将永久删除该分类, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch((err) => err)

      if (confirmRes !== 'confirm') {
        return this.$message.info('取消删除')
      }

      const { data: res } = await this.$http.delete('fmlist/' + id)
      if (res.code !== 201) {
        return this.$message.error('删除失败')
      }
      this.$message.success('删除成功')
      this.getFmList()
    },

    beforeAudioUpload(file) {
      const isAudio = file.type === 'audio/mpeg'
      const isLt200M = file.size / 1024 / 1024 < 200

      if (!isAudio) {
        this.$message.error('请上传MP3格式的音频文件')
      }
      if (!isLt200M) {
        this.$message.error('音频文件不能超过 20MB!')
      }
      return isAudio && isLt200M
    },
    audioUploadSuccess(res, file) {
      if (res.code !== 200) {
        return this.$message.error('上传失败')
      }
      this.addForm.fm_list_audio_url = res.local_path
      this.$message.success('上传成功')
    },
    editAudioUploadSuccess(res, file) {
      if (res.code !== 200) {
        return this.$message.error('上传失败')
      }
      this.editForm.fm_list_audio_url = res.local_path
      this.$message.success('上传成功')
    },
    handleSizeChange(newPage) {
      this.queryInfo.pagesize = newPage
      this.getFmList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getFmList()
    },
    typeChange() {
      this.type_visible = this.addForm.fm_list_url_type === '0'
    },
    typeChangeEdit() {
      this.type_visible_edit = this.editForm.fm_list_url_type === 0
    }
  }
}
</script>

<style scoped>
.vocie {
  margin-top: 30px;
}
.album_list {
  display: flex;
  flex-direction: row;
  margin: 0px;
}

.album_list .album_name {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
  margin-left: 30px;
}

.fm_image i {
  position: relative;
  color: #fff;
  font-size: 20px;
  z-index: 100;
}
.fm-info {
  display: flex;
  flex-direction: column;
  height: 92px;
  justify-content: space-between;
  color: #606266;
  font-size: 14px;
}
.fm-desc {
  margin-top: 15px;
  color: #606266;
  margin-left: 122px;
  font-size: 14px;
  line-height: 32px;
}
</style>
